import React from "react";

import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import useFetch from "../hooks/useFetch";

import Slider from "react-slick";

import Container from "@material-ui/core/Container";
import { ButtonBase } from "@material-ui/core";

import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ShowcaseSection } from "./Showcase.styled";
import qs from "qs";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Showcase({ title, category }) {
  const sliderRef = useRef(null);
  const [active, setActive] = useState(0);
  const [total, setTotal] = useState(0);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   // console.log("slide-uri totale:", sliderRef.current.props.children[1]);
  //   setTotal(sliderRef.current.props.children[1].length);
  // }, []);

  const query = qs.stringify({
    populate: "*",
    pagination: {
      page: 1,
      pageSize: 4,
    },
    filters: {
      categories: {
        id: { $eq: category },
      },
    },
    sort: ["id:desc"],
  });

  // console.log(query);

  // let { loading, error, data } = useFetch(
  //   `https://api.ovn.ro/api/videos?filters[categories][id][$eq]=${category}&populate=*`
  // );
  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/videos?${query}`
  );

  if (loading) return <p> Loading... </p>;
  if (error) console.log(error);

  const beforeChange = (prev, next) => {
    setActive(next);
  };

  // console.log(data);

  if (!data) {
    return null;
  }

  return (
    <ShowcaseSection>
      <Container maxWidth="xl" className="showcaseNav">
        {isSmall && (
          <div className="title">
            <h2>{title}</h2>
          </div>
        )}
        {/* <span>{`theme.breakpoints.up('sm') matches: ${isSmall}`}</span>
        total: {total} si Active Slide: {active} */}
        {!isSmall && (
          <>
            <ButtonBase
              className={active === 0 ? " buttons disabled" : "buttons"}
              onClick={() => sliderRef.current.slickPrev()}
            >
              <ArrowBackIosRoundedIcon />
            </ButtonBase>
            <ButtonBase
              className={active === total - 1 ? " buttons disabled" : "buttons"}
              onClick={() => sliderRef.current.slickNext()}
            >
              <ArrowForwardIosRoundedIcon />
            </ButtonBase>
          </>
        )}
      </Container>

      <div className="videoSliderWrap">
        <Slider
          //   dots
          //   dotsClass="slick-dots line-indicator"
          ref={sliderRef}
          slidesToShow={3}
          slidesToScroll={1}
          variableWidth={true}
          infinite={false}
          loop={false}
          arrows={false}
          beforeChange={beforeChange}
          responsive={[
            {
              breakpoint: 600,
              variableWidth: false,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
          customPaging={(i) => (
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: "-10px",
                opacity: 0,
              }}
            >
              {i}
            </div>
          )}
        >
          {!isSmall && (
            <div>
              <div className="title">
                <h2>{title}</h2>
              </div>
            </div>
          )}
          {data.map((item, index) => (
            <div key={index} className="innerSlider">
              <div
                className="image"
                style={{
                  backgroundImage: `url(https://api.ovn.ro${item.video_cover?.formats?.medium?.url})`,
                }}
              >
                <Link to={`/showcase/${item.slug}`} className="play">
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.612 8.36007C16.796 9.13281 16.796 10.8672 15.612 11.6399L3.52861 19.5262C2.22605 20.3764 0.499999 19.4418 0.499999 17.8863L0.5 2.11369C0.5 0.558245 2.22605 -0.376373 3.52861 0.473756L15.612 8.36007Z"
                      fill="white"
                    ></path>
                  </svg>
                  Play
                </Link>
              </div>

              <b className="name">{item.title}</b>
              <span className="client">{item.brand}</span>
            </div>
          ))}

          <Link to="/showcase" className="viewAll">
            Vezi tot
          </Link>
        </Slider>
      </div>
    </ShowcaseSection>
  );
}
