import styled from "styled-components";

export const CategoryWrap = styled.div`
  /* flex: 0 0 33.33333%; */
  /* max-width: 33.333333%; */
  /* height: 380px; */
  margin-right: 0;
  padding: 0;
  padding-left: 12px;
  position: relative;
  text-align: left;
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.md}) {
    /* flex: 0 0 300px;
    max-width: 300px;
    height: 250px; */

    width: 100%;
    /* margin: 0 -16px; */
    padding: 0;
  }

  h1 {
    color: #0787a4;
    font-size: 3rem;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    display: inline-block;

    :after {
      position: absolute;
      background: #d8dde6;
      content: "";
      width: 200%;
      max-width: 400px;
      height: 2px;
      top: 50%;
      right: -220%;
    }
  }

  .item {
    padding: 0;
    margin-bottom: 1rem;
    color: #6b7c93;
    position: relative;

    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 1rem;
      /* margin: 0 16px; */
      justify-content: center;
      display: flex;
    }

    h3 {
      color: #6b7c93;
      margin: 0;
      font-size: 4rem;
      position: relative;
      line-height: 4.2rem;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.5rem;
        line-height: 1.2;
        text-align: left;
      }
    }
    .section {
      position: relative;
      color: #6b7c93;
    }
    .description {
      position: relative;
      font-size: 2rem;
      margin: 0.5rem 0;
      line-height: 2rem;
      /* margin-bottom: 1rem; */
      p {
        color: #6b7c93;
        line-height: 1.4;
        opacity: 0.7;
        margin: 0;
        font-size: 16px;
      }
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 12px;
        display: none;
      }
    }
  }

  .serviceCount {
    transition: all 0.5s ease;

    span {
      position: absolute;
      bottom: 48px;
      left: 44px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      cursor: pointer;
      display: block;
      color: #fff;
    }
    .serviceList {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 -12px;

      @media (max-width: ${({ theme }) => theme.md}) {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin: 0;
      }

      > div {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding: 0;
        padding-left: 12px;

        @media (max-width: ${({ theme }) => theme.md}) {
          max-width: 250px;
          height: auto;
          flex: 0 0 250px;

          :last-child {
            margin-right: 16px;
          }
        }

        .image {
          @media (max-width: ${({ theme }) => theme.md}) {
            flex: 0 0 110px;
          }
        }

        .text {
          flex-direction: column;
          @media (max-width: ${({ theme }) => theme.md}) {
            justify-content: center;
            padding: 16px 24px;

            .description {
              display: none;
            }

            .link {
              display: none;
            }
          }
          h4 {
            font-size: 1.5rem;
            @media (max-width: ${({ theme }) => theme.md}) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
`;
