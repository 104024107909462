import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const ShowcaseSection = styled(Section)`
  /* make changes as needed*/

  margin: 8rem 0;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 1rem 20px 4rem 20px;
    /* margin: 0; */
  }

  .showcaseNav {
    padding: 10px 100px;
    display: flex;
    justify-content: end;
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0;
      justify-content: start;
    }
  }

  .title h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 16px;

    /* color: #8797b2; */
    color: #0786a3;
  }

  .videoSliderWrap {
    .slick-track {
      display: flex !important;
      @media (max-width: ${({ theme }) => theme.md}) {
        margin: 0px -12px;
      }
    }

    .slick-slide {
      /* margin: 0 0.5rem; */
      padding: 0 0.5rem;
      height: inherit !important;
      display: flex;
      @media (max-width: ${({ theme }) => theme.md}) {
        padding: 0 12px;
      }

      > div:first-child {
        display: flex;
      }

      .image {
        width: 600px;
        /* padding-top: 56.25%; */
        background-position: center;
        background-size: cover;
        border-radius: 12px;
        margin-bottom: 12px;
        position: relative;
        border-radius: 10px;
        aspect-ratio: 16 / 9;

        @media (max-width: ${({ theme }) => theme.md}) {
          width: 300px;
        }

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 20;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          color: #fff;
          background: #36c2d9;
          border: 1.5px solid rgba(255, 255, 255, 0.4);
          box-sizing: border-box;
          backdrop-filter: blur(24px);
          border-radius: 100px;
          padding: 12px 20px;
          display: flex;
          align-items: center;
          transition: all 0.3s ease 0s;

          svg {
            margin-right: 8px;
          }
        }
      }
      b.name {
        font-weight: 900;
        font-size: 20px;
        line-height: 28px;
        display: block;
        @media (max-width: ${({ theme }) => theme.md}) {
          width: 300px;
        }
      }

      span {
        font-size: 14px;
        line-height: 24px;
        color: #6b7c93;
      }
    }
    .title {
      margin-left: 100px;
      margin-right: 8rem;
      width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @media (max-width: ${({ theme }) => theme.md}) {
        display: none;
      }

      h2 {
        font-size: 3.5rem;
        line-height: 1.2;
        color: #8797b2;
        color: #0786a3;
      }
    }

    .viewAll {
      display: flex !important;
      justify-content: center;
      align-items: center;
      /* height: 337px;
      border: 1.5px solid #d8d8d8;
      border-radius: 10px; */
      padding: 2rem;
      font-size: 2rem;
      color: #36c2d9;
      width: 360px !important;
      /* background-color: #36c2d9; */

      @media (max-width: ${({ theme }) => theme.md}) {
        /* height: 170px;
        width: 300px !important; */
      }
    }
  }

  .line-indicator li {
    /* border: 2px solid; */
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }
  .buttons {
    width: 44px;
    height: 44px;
    background-color: #fff;
    border: 1.5px solid #ededed;
    color: #000;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
    display: flex;

    :first-of-type {
      margin-right: 10px;
    }

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .buttons:hover {
    /* background: white !important; */
    /* color: orangered !important; */
    border: 1.5px solid #202020;
    /* transform: scale(1.1); */
  }
`;
