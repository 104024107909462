import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import axios from "axios";

import Icon from "@material-ui/core/Icon";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import UploadFileIcon from "@material-ui/icons/UploadFileIcon";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { Button } from "../styles/GlobalStyles";
import { Button as Button2 } from "@material-ui/core";

const styles = {
  contactForm: {
    // marginTop: 50,
    width: "100%",
  },
};

function JobForm(props) {
  //   state = {
  //     userName: "",
  //     email: "",
  //     message: "",
  //     phone: "",
  //     industry: "",
  //     products: "",
  //     submitted: false,
  //     errors: false,
  //   };

  const [form, setForm] = useState({
    nume: "",
    prenume: "",
    email: "",
    message: "",
    phone: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const [filename, setFilename] = useState("");
  // console.log("details", props.details);

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setForm({ ...form, file });
    setFilename(name);
  };

  // Handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { nume, prenume, email, phone, message, file } = form;

    let cv = new FormData();
    cv.append("files", form.file);

    axios
      .post("https://api.ovn.ro/api/upload", cv)
      .then((response) => {
        // console.log(response);

        const fields = {
          nume,
          prenume,
          email,
          phone,
          message,
          file: response.data[0].id,
          job: props.details.title,
        };

        axios({
          method: "post",
          url: "https://api.ovn.ro/api/zjobs",
          data: fields,
        })
          .then(({ data }) => {
            // Reset from after submiting
            document.getElementById("contact-form").reset();
            setForm({
              nume: "",
              prenume: "",
              email: "",
              message: "",
              phone: "",
              file: "",
            });
            setFilename("");

            setSubmitted(true);
          })
          .catch((error) => {
            //handle error
            console.log("postJobs", error);
          });
      })
      .catch((error) => {
        console.log("upload", error);
      });
  };

  // Handle fields change
  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
    // console.log(form);
  };

  const { classes, details } = props;

  return (
    <div className={classes.container}>
      {submitted && (
        <div
          style={{
            minHeight: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <Icon style={{ fontSize: "10rem", color: "#0786a3" }}>task_alt</Icon>
          <h4 style={{}}>
            Multumim!
            <br />
            Aplicația dvs. a fost trimisă cu succces.
            <br />O confirmare a fost trimisă și pe adresa de email.
          </h4>
          <Button type="reset" onClick={() => setSubmitted(false)}>
            RESET
          </Button>
        </div>
      )}
      {!submitted && (
        <Paper elevation={3} style={{ minHeight: 600, paddingTop: 20 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            component={Box}
            p={4}
            // sm={{ p: 2 }}
          >
            <Typography variant="h4" align="center" component="h4" gutterBottom>
              Aplică pentru poziția {details.title}
              <br />
              completând formularul de mai jos:
            </Typography>
            <form
              id="contact-form"
              className={classes.contactForm}
              onSubmit={handleSubmit}
            >
              <Grid item className="uploadCV">
                <Button2
                  component="label"
                  variant="outlined"
                  size="large"
                  type="button"
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginRight: "1rem" }}
                >
                  Upload CV
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx,.txt"
                    required
                    style={{ display: "none" }}
                    name="cv"
                    onChange={handleFileUpload}
                  />
                </Button2>

                <Box className="uploadCVFilename">{filename}</Box>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="nume"
                    label="Nume"
                    name="nume"
                    className={classes.inputField}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="prenume"
                    label="Prenume"
                    name="prenume"
                    className={classes.inputField}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="email"
                    label="Email"
                    name="email"
                    className={classes.inputField}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    id="phone"
                    label="Telefon"
                    name="phone"
                    className={classes.inputField}
                    onChange={handleChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  variant="filled"
                  // required
                  id="message"
                  label="Informatii suplimentare și Link-uri"
                  name="message"
                  placeholder="Specificați, daca e cazul, link-uri către profilele dvs. sociale sau către portofoliu."
                  className={classes.inputField}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  minRows="7"
                  maxRows="10"
                />
              </Grid>
              <p style={{ fontSize: "0.8rem", margin: 0 }}>
                Prin trimiterea acestei aplicatii, confirmați că ați citit și
                sunteți de acord cu Politica de Confidențialitate și Prelucrarea
                Datelor Personale.
              </p>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="center"
                style={{ marginTop: 20 }}
              >
                {/* <Grid item>
                  <Button
                    className={classes.formButton}
                    type="reset"
                    variant="contained"
                    color="default"
                    // disabled={submitting || pristine}
                  >
                    RESET
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    className={classes.formButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Trimite aplicația ta pentru {details.title}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      )}
    </div>
  );
}

export default withStyles(styles)(JobForm);
