import React from "react";
import drm from "../images/icons/icon-drm.svg";
import engage from "../images/icons/icon-engage.svg";
import mobilesdk from "../images/icons/icon-mobile-sdk.svg";
import monetize from "../images/icons/icon-monetize.svg";
import news from "../images/icons/icon-news.svg";
import ott_apps from "../images/icons/icon-ott-apps.svg";
import play from "../images/icons/icon-play.svg";
import player from "../images/icons/icon-player-web.svg";
import recommendations from "../images/icons/icon-recommendations.svg";
import report_chart from "../images/icons/icon-report-chart.svg";
import report_star from "../images/icons/icon-report-star.svg";
import stream from "../images/icons/icon-stream.svg";
import subscriptions from "../images/icons/icon-subscriptions.svg";
import player_customize from "../images/icons/player-customize.svg";
import brand from "../images/icons/icon_brand.svg";
import microphone from "../images/icons/icon_microphone.svg";
import trophy from "../images/icons/icon_trophy.svg";
import conference from "../images/icons/icon_conference.svg";
import speaker from "../images/icons/icon_speaker.svg";
import podcast from "../images/icons/icon_podcast.svg";
import chroma from "../images/icons/icon_chroma.svg";
import webinar from "../images/icons/icon_webinar.svg";
import graphics from "../images/icons/icon_graphics.svg";
import projects from "../images/icons/icon_projects.svg";
import tips from "../images/icons/icon_tips.svg";
import live from "../images/icons/icon-live-channels-3.svg";
import sport from "../images/icons/icon-sports.svg";

const iconList = {
  brand: brand,
  conference: conference,
  chroma: chroma,
  drm: drm,
  engage: engage,
  graphics: graphics,
  mobilesdk: mobilesdk,
  monetize: monetize,
  microphone: microphone,
  news: news,
  ott_apps: ott_apps,
  play: play,
  player: player,
  recommendations: recommendations,
  report_chart: report_chart,
  report_star: report_star,
  stream: stream,
  subscriptions: subscriptions,
  speaker: speaker,
  player_customize: player_customize,
  podcast: podcast,
  projects: projects,
  trophy: trophy,
  tips: tips,
  live: live,
  sport: sport,
  webinar: webinar,
};

export default function SvgIcon(props) {
  let Icon = iconList[props.name];
  if (!Icon) {
    Icon = iconList.live;
  }
  return <img src={Icon} />;
}
