import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const Service = styled(Section)`
  background: #36c2d9;
  padding-top: 76px;
  padding-bottom: 80px;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: visible;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin-bottom: 110px;
  }

  .background {
    position: absolute;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 675px;
    max-height: 820px;
    background-position: center top;

    ::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 675px;
      top: 0;
      /* background: linear-gradient(
        180deg,
        rgb(7 135 164 / 80%) 41.15%,
        #36c2d9 100%
      ); */
      background: linear-gradient(
        180deg,
        rgba(32, 32, 32, 0.55) 60%,
        #36c2d9 100%
      );
      backdrop-filter: blur(10px);

      max-height: 820px;
    }
  }

  .content {
    margin-top: 78px;
    position: relative;
    color: #fff;

    .text {
      color: #fff;
      font-size: 20px;
      margin-right: 64px;
      margin-bottom: 0;
      text-align: left;

      @media (max-width: ${({ theme }) => theme.md}) {
        margin-right: 0;
      }

      h1 {
        font-size: 76px;
        line-height: 91px;
        margin-bottom: 16px;
        color: #fff;
        font-weight: 900;
        @media (max-width: ${({ theme }) => theme.md}) {
          font-size: 4rem;
          line-height: 1.2;
          text-align: center;
        }
      }

      p {
        color: #fff;
        font-size: 20px;
        margin: 0;
        @media (max-width: ${({ theme }) => theme.md}) {
          text-align: center;
          font-size: 16px;
          line-height: 1.4;
        }
      }
    }

    .callWrap {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      backdrop-filter: blur(10px);
      padding: 20px 15px 15px;

      margin-top: 16px;

      font-size: 20px;
      line-height: 28px;
      text-align: center;

      @media (max-width: ${({ theme }) => theme.md}) {
      }

      .cta {
        margin-top: 20px;
        padding: 20px;
        /* background: rgba(32, 32, 32, 0.75);
        backdrop-filter: blur(250px);
        border-radius: 12px; */
      }
    }

    .about {
      margin-top: 90px;
      margin-bottom: -200px;
      background: #0787a4;
      box-shadow: 0 2px 48px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      overflow: hidden;
      min-height: 400px;
      padding: 3rem;

      @media (max-width: ${({ theme }) => theme.md}) {
        margin-bottom: -150px;
        margin-top: 2rem;
        padding: 1rem;
      }

      .video {
        position: relative;
        /* z-index: 100; */
        /* margin-bottom: 24px; */
        border-radius: 12px;
        overflow: hidden;

        img {
          height: auto;
          aspect-ratio: 16/9;
        }
      }

      .details {
        padding-left: 4rem;
        align-self: center;

        @media (max-width: ${({ theme }) => theme.md}) {
          padding-left: 30px;
        }

        .title {
          font-size: 32px;
          line-height: 46px;
          margin-bottom: 12px;
          white-space: nowrap;
          margin-left: -30px;

          @media (max-width: ${({ theme }) => theme.md}) {
            font-size: 25px;
            line-height: 1.2;
            font-weight: 900;
            text-align: center;
          }
        }

        .include {
          p {
            margin: 0;
            color: #fff;
            font-weight: 900;
            display: inline-block;
            margin-top: 16px;
            margin-left: -2rem;
          }
        }

        ul {
          margin-left: 1rem;
          font-size: 16px;
          line-height: 30px;

          li:before {
            content: "✓";
            margin-right: 10px;
            font-size: 20px;
            opacity: 0.4;
            margin-left: -30px;
          }

          b {
            font-weight: 900;
            display: inline-block;
            margin-top: 16px;
            margin-left: -2rem;
          }
        }
      }
    }
  }
`;
