import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const CariereSection = styled(Section)`
  margin: 2rem 0;
  padding: 2rem 0;
  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  .DepartmentWrap {
    text-align: center;
    @media (max-width: ${({ theme }) => theme.md}) {
      overflow-x: auto;
      padding-bottom: 12px;
    }

    span {
      margin-right: 12px;
      white-space: nowrap;
      background: #fff;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      line-height: 42px;
      padding: 0 12px;
      color: #202020;
      height: 32px;
      font-weight: 400;
      cursor: pointer;
      padding: 0.5rem 1rem;

      &.active {
        background: #272b38;
        color: #fff;
      }
    }
  }

  .jobWrap {
    margin: 3rem -6px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${({ theme }) => theme.md}) {
      margin: 1rem -6px;
    }

    .item {
      width: 33.33333%;
      flex: 0 0 33.3333%;
      padding: 0 6px 12px;

      &.noposition > div {
        background: #ededed;

        .description p {
          font-size: 16px;
          line-height: 1.4;
        }
      }

      &.statement > div {
        background: #0787a4;
        text-align: center;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
          color: #fff;
          font-size: 3rem;
          margin: 0.5rem 0;
        }

        .description p {
          font-size: 16px;
          color: #fff;
          line-height: 1.4;
        }
      }

      @media (max-width: ${({ theme }) => theme.md}) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
`;

export const CariereDetailsTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  @media (max-width: ${({ theme }) => theme.md}) {
    margin-top: 40px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 1.4;
    display: block;
    margin-bottom: 10px;
    color: #272b38;
  }
  h1 {
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    margin: 0;
    @media (max-width: ${({ theme }) => theme.md}) {
      text-align: center;
      font-size: 2.5rem;
      line-height: 1.2;
      padding: 1rem;
    }
  }
  p {
    font-size: 2rem;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #202020;
    margin: 0;
    img {
      margin: 0 1rem;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 1.5rem;
    }
  }

  div {
    max-width: 700px;
    text-transform: none;
    margin: 2rem 0 80px;
    @media (max-width: ${({ theme }) => theme.md}) {
      margin: 2rem 1rem;
    }

    p {
      text-align: center;
      line-height: 1.4;
      color: #fff;
      font-size: 1.5rem;
      margin: 12px 0;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.2rem;
        line-height: 1.2;
      }
    }
  }
`;

export const CariereDetailsContent = styled(Section)`
  background: #f3f5f9;

  a {
    margin: 4rem auto;
    width: 300px;
    display: block;
    font-size: 2rem;
  }
  p {
    font-size: 1.7rem;
    line-height: 1.4;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 1rem;
    }
  }

  ul {
    margin-bottom: 3rem;
    li {
      list-style: square;
      font-size: 1.5rem;
      line-height: 1.4;
      margin-bottom: 1rem;
      margin-left: 2rem;
      color: #6b7c93;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1rem;
        line-height: 1.2;
      }
    }
  }
`;

export const CariereApplyContent = styled(Section)`
  padding: 3rem 0;
`;
