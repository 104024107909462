import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import { BrowserRouter as Router, useParams } from "react-router-dom";

import TopSection from "../sections/TopSection";
import LogoSlider from "../sections/Slider";
import Services from "../sections/Services";
import TwoSide from "../sections/TwoSide";
import Next from "../sections/Next";
import FAQ from "../sections/faq";

import Image1 from "../images/demo/1.svg";

import Digisport from "../images/logotv/Digi_Sport_logo.svg";
import Eurosport from "../images/logotv/Eurosport_Logo_2015.svg";
import ProArena from "../images/logotv/PRO_Arena.svg";
import PrimaSport from "../images/logotv/Prima_Sport_logo.svg";
import ZUTV from "../images/logotv/ZUTVLogo.png";
import Antena1 from "../images/logotv/antena1.svg";
import LookSport from "../images/logotv/LookSport.png";
import Showcase from "../sections/Showcase";
import CategoryList from "../sections/CategoryList";
import usePageTracking from "../hooks/usePageTracking";

const tv_logos = [
  Eurosport,
  Digisport,
  ProArena,
  Antena1,
  PrimaSport,
  ZUTV,
  LookSport,
];

export default function CategoryPage() {
  usePageTracking();
  let { category } = useParams();

  // console.log(category);
  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/categories?filters[slug][$eq]=${category}&populate[services][populate]=*`
  );
  if (loading) return null;
  if (error) return <p> Error :( </p>;

  // console.log(data);
  return (
    <>
      <TopSection
        light="1"
        type={data[0].title}
        title=""
        description={data[0].description}
        picture=""
        //   link_title="Hello"
        services={data[0].services}
      ></TopSection>

      {/* <Services data={data}></Services> */}

      <Showcase
        title="Producții anterioare pentru inspirație"
        category={data[0].id}
      ></Showcase>

      <CategoryList
        title="Vrei mai mult?"
        subtitle="Iată câteva tipuri de conținut video de care ne-am gândit că ati fi interesat."
        showLink={true}
      ></CategoryList>

      <LogoSlider
        title="Posturi TV partenere"
        //   subtitle="Innovative industry solutions to flawlessly stream video, improve audience engagement, and better monetize video content."

        slides={tv_logos}
      ></LogoSlider>

      <Next backgroundColor="#0786a3"></Next>
      <FAQ />
    </>
  );
}
