import React, { useState } from "react";
import axios from "axios";

import { Form, Formik } from "formik";
import * as yup from "yup";

import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

import Steps from "./cotatie/Steps";

export default function CotatieForm() {
  // let validationSchema = yup.object({
  //   event_address: yup
  //     .string("Enter event address")
  //     .required("Event address is required"),
  //   // event_location: yup
  //   //   .string("Enter event location")
  //   //   .required("Event location is required"),
  //   event_date: yup
  //     .string("Enter event date")
  //     .required("Event date is required"),
  //   event_notes: yup.string().required("Event description is required"),
  //   contact_email: yup
  //     .string("Enter your email")
  //     .email("Enter a valid email")
  //     .required("Email is required"),
  //   contact_phone: yup
  //     .number("Enter your phone")
  //     .typeError("Only numbers (Ex: 0722111333)")
  //     .test(
  //       "len",
  //       "Incorrect phone number",
  //       (val) => val.toString().length >= 9
  //     )
  //     .required("Phone is required"),
  //   contact_name: yup.string("Enter your name").required("Name is required"),
  //   contact_firstname: yup
  //     .string("Enter your firstname")
  //     .required("First Name is required"),
  // });

  return (
    <CotatieFormWrap>
      <Steps />
    </CotatieFormWrap>
  );
}

const CotatieFormWrap = styled(Section)`
  padding: 0 0 5rem 0;
  background: #36c2d9;

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
    color: #fff !important;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
    color: #fff !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #0786a3 inset;
    -webkit-text-fill-color: #fff;
  }

  .btnTrimiteWrap {
    display: flex;
    .btnTrimite {
      width: 80%;
      margin: 4rem auto;
    }
  }

  .stepInfo {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 2rem;
    text-align: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
    line-height: 1.4;
    font-weight: 700;
  }

  .stepsNav {
    display: flex;
    justify-content: space-between;
  }

  .sectionSelect {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    cursor: pointer;

    :hover {
      transform: scale(1.03);
      transition: all 0.2s ease;
    }
    h4 {
      margin: 0;
      color: #0786a3;
    }

    .extra {
      color: #0786a3;
    }

    &.selected {
      background: #0786a3;

      h4,
      .extra {
        color: #fff;
      }
    }
  }

  .segment {
    color: #fff;
    opacity: 0.7;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 1.5rem;
    }

    :after {
      content: "";
      flex: 1 1;
      border-bottom: 2px solid #fff;
      margin: auto;
      margin-left: 2rem;
    }

    svg {
      width: 2rem;
      height: 2rem;
      @media (max-width: ${({ theme }) => theme.md}) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .formBox {
    padding-bottom: 2rem;
  }
`;
