import React, { Component } from "react";

import { FAQSection } from "./faq.styled";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

const accordionData = [
  {
    title: "Care sunt beneficiile Live Streaming-ului pentru mine?",
    description:
      "Posibilitatea de a atrage și implica o audiență vastă în timp real, creșterea notorietății brand-ului prin interacțiune autentică cu clienții, și generarea de feedback în timp real pentru îmbunătățirea produselor și serviciilor.",
  },
  {
    title:
      "Ce se întâmplă dacă se pierde conexiunea în timpul transmisiunii LIVE?",
    description:
      'Pentru toate transmisiunile LIVE, compania noastra va pune la dispozitie  o solutie de protectie stream. In cazul in care conexiunea din locatia evenimentului se intrerupe, un stream de back-up va mentine conexiunea cu platformele sociale active. Acea conexiune de back-up va avea un carton cu textul "Revenim Imediat". Personalizare mesalujul ce apare pe disponibila.',
  },
  {
    title: "Care este cea mai bună platformă sociala pentru Live Streaming?",
    description:
      "Pentru a alege platforma de Live Streaming potrivită pentru afacerea dumneavoastră, luați în considerare publicul țintă, caracteristicile platformei, stabilitatea și suportul tehnic, precum și costurile și politicile de monetizare. Este esențial să vă adaptați alegerea la obiectivele și necesitățile specifice ale afacerii dumneavoastră. Totodată este important să detineți drepturile pentru conținutul audio.",
  },

  {
    title: "In ce locații oferă OVN serviciile sale?",
    description:
      "Toate serviciile companiei Online Video Network sunt disponibile pe intreg teritoriul României. Pentru Chroma Key, Inchiriere Studio si Studio Virtual 3D ne gasiti in București, Sector 6, lângă Afi Controceni.",
  },
  {
    title: "Vreau doar filmarea si inregistrarea, fara LIVE Streaming.",
    description:
      "Nici o problema. Serviciile noastre se adapteaza usor nevoilor dumneavoastra. Va vom pune la dispozitie inregistrarea imediat dupa terminarea evenimentului pe un link de transfer valabil 30 de zile.",
  },
  {
    title:
      "Ce se întâmplă cu drepturile de autor pentru materialele video produse?",
    description:
      "Pentru materiale video rezultate în urma contractului încheiat cu compania noastră, toate drepturile asupra acestor materiale sunt ale dvs., Beneficiarul,  după achitarea valorii serviciului. Ulterior, puteți folosi materialele derivate pentru orice comunicare vă este necesară.",
  },
];

const Accordion = ({ title, description }) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <li className="accordion-item" onClick={() => setIsActive(!isActive)}>
      <div className="accordion-toggle">
        {isActive && (
          <h3>
            <strong>{title}</strong>
          </h3>
        )}
        {!isActive && <h3>{title}</h3>}
        <span>{isActive ? "-" : "+"}</span>
      </div>
      {isActive && <div className="accordion-content">{description}</div>}
    </li>
  );
};

class FAQ extends Component {
  state = {};
  render() {
    return (
      <FAQSection>
        <Container maxWidth="xl">
          <Grid container spacing={8}>
            <Grid item md={5}>
              <h4>Intrebări frecvente</h4>
            </Grid>
            <Grid item md={7}>
              <ul className="accordion">
                {accordionData.map(({ title, description }, index) => (
                  <Accordion
                    key={index}
                    title={title}
                    description={description}
                  />
                ))}
              </ul>
              <p className="moreFAQ">
                Ai alte intrebari?{" "}
                <Link to="/contact">Completeaza Formularul de Contact.</Link>
              </p>
            </Grid>
          </Grid>
        </Container>
      </FAQSection>
    );
  }
}

export default FAQ;

// <div class="item slick-slide" data-slick-index="6" aria-hidden="true" style="width: 300px;" tabindex="-1">

//         <figure class="logo-image">
//           <img src="https://jwplayer.com/wp-content/uploads/2022/12/logo-eurosport-white.svg" alt="" loading="lazy" class="logo">
//         </figure>

//             </div>
