import React from "react";
import { Link } from "react-router-dom";

import { BlogCardWrap } from "./BlogCard.styled";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import TimerIcon from "@material-ui/icons/Timer";

export default function BlogCard(props) {
  const { article } = props;

  return (
    <BlogCardWrap>
      <Link to={`/blog/${article.slug}`}>
        <div
          className="image"
          style={{
            backgroundImage: `url(https://api.ovn.ro${article.cover.formats.small.url})`,
          }}
        ></div>
        <div className="text">
          <div className="extra">
            <div className="categories">
              {article.categories.map((cat) => (
                <span key={cat.slug}>{cat.title}</span>
              ))}
            </div>
            <span>
              {article.views} <RemoveRedEyeIcon /> &nbsp;&nbsp;|&nbsp;&nbsp;
              {article.reading_time} Min <TimerIcon />
            </span>
          </div>
          <h3>{article.title}</h3>
        </div>
      </Link>
    </BlogCardWrap>
  );
}
