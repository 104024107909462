import React from "react";
import { PageWrap, StyledContainer } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";
import CategoryList from "../sections/CategoryList";
import Next from "../sections/Next";
import FAQ from "../sections/faq";
import usePageTracking from "../hooks/usePageTracking";

function Services() {
  usePageTracking();
  return (
    <>
      <PageWrap className="static">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img>
        <Hero
          light="1"
          title="Servicii și Produse"
          subtitle="Descoperă lista completă de soluții profesionale de transmisiune LIVE și producție video"
        ></Hero>
      </PageWrap>
      <CategoryList
        // title="Lista completa"
        // subtitle="Iată câteva tipuri de conținut video de care ne-am gândit că ati fi interesat."
        showLink={false}
        isList={true}
      ></CategoryList>
      <Next backgroundColor="#0786a3"></Next>
      <FAQ />
    </>
  );
}

export default Services;
