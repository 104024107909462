import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const TwoSideSection = styled(Section)`
  /* make changes as needed*/

  padding: 5rem 0;
  margin: 5rem 0;
  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .MuiGrid-item {
    padding: 0 4rem 0 1rem;
    ${({ reverse }) => reverse && ` padding: 0 3rem;`}
    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 4rem 0 0rem;
      ${({ reverse }) => reverse && ` padding: 0 3rem;`}
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0 1rem;
      margin-bottom: 3rem;
      //   ${({ reverse }) => reverse && `  padding: 0 1rem;`}
    }

    p {
      margin-bottom: 1.5rem;
    }

    a {
      color: #de3a31;
      font-weight: 700;
    }

    li {
      color: #6b7c93;
      position: relative;
      padding-left: 60px;
      margin-bottom: 1.5rem;
      /* font-size: 1.2rem; */

      &:before {
        font-size: 30px;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%236b7c93'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
        font-family: "Material Icons";
        position: absolute;
        left: 0;
        top: 0;
        line-height: 30px;
        width: 30px;
      }
    }
  }

  ${({ reverse }) =>
    reverse &&
    `
    .MuiGrid-container {
        flex-direction: row-reverse;
    }

    `}

  .slider-btn {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    > * {
      margin-bottom: 16px;
      margin-right: 0;
      cursor: pointer;
    }

    @media (max-width: ${({ theme }) => theme.md}) {
      display: flex;
      flex-direction: column;
    }
  }

  .slider-image {
    border-radius: 10px;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    ${({ reverse }) => reverse && `   direction: rtl;`}

    img {
      border-radius: 10px;
      width: 100%;
      filter: drop-shadow(5px 10px 10px rgba(47, 53, 66, 0.3))
        drop-shadow(3px 5px 7px rgba(47, 53, 66, 0.1));
      @media (min-width: ${({ theme }) => theme.md}) {
        width: 600px;
      }
      @media (min-width: ${({ theme }) => theme.lg}) {
        width: 800px;
      }
    }
  }
`;
