import React, { Component } from "react";
import { Service } from "./TopService.styled";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { Button, StyledContainer } from "../styles/GlobalStyles";
import VideoEmbed from "../components/videoEmbed";

class TopService extends Component {
  state = {};

  render() {
    const { title, description, video_url, include, background } = this.props;
    return (
      <Service {...this.props}>
        <div
          className="background"
          style={{
            backgroundImage: `url(https://api.ovn.ro${background?.url})`,
          }}
        ></div>
        <StyledContainer maxWidth="xl" className="content">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item md={6} className="text">
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </Grid>
            <Grid item md={4} className="callWrap">
              <div className="call">
                Compania noastră are solutii multiple în funcție de bugetul dvs.
                și cerințele tehnice necesare.
                <div className="cta">
                  <Button style={{ width: "100%" }} type="red">
                    Vreau o soluție pentru <br /> {title}
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container className="about">
            <Grid item className="video" md={7} xs={12}>
              {video_url && <VideoEmbed url={video_url}></VideoEmbed>}
              {!video_url && (
                <img
                  src={`https://api.ovn.ro${background?.url}`}
                  alt={`cover_image_${title}`}
                  width="100%"
                />
              )}
            </Grid>
            <Grid item className="details" md={5}>
              <div className="title">Acest serviciu include:</div>
              <div
                className="include"
                dangerouslySetInnerHTML={{ __html: include }}
              ></div>

              {/* <ul>
                <b>Planificare</b>
                <li>Pregatire Grafica si Logistica Meci</li>

                <b>Productie</b>
                <li>Filmare Meci in sistem Multicam</li>
                <li>Afisare Grafica Meci </li>
                <li>Instant Replay</li>
                <li>Conexiune Internet / Satelit</li>
                <li>Transmisiune LIVE pe internet / TV</li>

                <b>Post-Productie</b>
                <li>Video Highlights - Cele mai bune momente din joc</li>
              </ul> */}
            </Grid>
          </Grid>
        </StyledContainer>
      </Service>
    );
  }
}

export default TopService;
