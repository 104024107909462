import React, { Component } from "react";
import { PromoSection } from "./Promo.styled";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import PictureCard from "../components/cards/PictureCard";

function EmbedPlayer() {
  return (
    <div className="videoWrap">
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.5%" /* 16:9 */,
          paddingTop: 0,
          height: 0,
          overflow: "hidden",
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://transmite.live/ovn/video/5f91a1ce16846d80025ae1b2/embed`}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
        />
      </div>
    </div>
  );
}

class Promo extends Component {
  render() {
    return (
      <PromoSection>
        <Container maxWidth="lg">
          <div className="promoWrap">
            <div className="secondPromo">
              <PictureCard></PictureCard>
            </div>

            <div className="secondPromo">
              <PictureCard></PictureCard>
            </div>
            <div className="secondPromo">
              <PictureCard></PictureCard>
            </div>
          </div>
        </Container>
      </PromoSection>
    );
  }
}

export default Promo;
