import React, { Component } from "react";
import { HeroSection } from "./Hero.styled";
import Container from "@material-ui/core/Container";

class Hero extends Component {
  state = {};
  render() {
    return (
      <HeroSection {...this.props}>
        <Container maxWidth="xl">
          <div className="heroWrapper">
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: this.props.title }}
            ></h2>
            <h4 className="subtitle">{this.props.subtitle}</h4>
            {this.props.children}
          </div>
        </Container>
      </HeroSection>
    );
  }
}

export default Hero;
