import React, { Component } from "react";
import { FeaturesSection } from "./Features.styled";
import { Title, Description, StyledContainer } from "../styles/GlobalStyles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import vr1 from "../images/virtual/vkrs_0.jpg";
import vr2 from "../images/virtual/vkrs_1.jpg";
import vr3 from "../images/virtual/vkrs_2.jpg";

class Features extends Component {
  state = {};

  render() {
    const { jsonData } = this.props;

    return (
      <FeaturesSection {...this.props}>
        <StyledContainer maxWidth="xl">
          {jsonData.title && jsonData.description && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={6} component={Box} className="slider-content">
                <Title dangerouslySetInnerHTML={{ __html: jsonData.title }} />

                <Description>
                  <p>{jsonData.description}</p>
                </Description>
                <div className="images">
                  <span>
                    <img src={vr1} width="100%"></img>
                  </span>
                  <span>
                    <img src={vr2} width="100%"></img>
                  </span>
                  <span>
                    <img src={vr3} width="100%"></img>
                  </span>
                  <span>
                    <img src={vr1} width="100%"></img>
                  </span>
                </div>

                <div className="slider-btn"></div>
              </Grid>
              <Grid item md={6} component={Box}>
                <div className="featuresContainer">
                  {jsonData.items.map((item, index) => (
                    <div className="featItem" key={index}>
                      <Icon>{item.icon}</Icon>
                      <div className="featContent">
                        <h4>{item.title}</h4>
                        <div className="featDescription">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          )}

          {!jsonData.title && (
            <Grid container justify="space-between" alignItems="flex-start">
              {jsonData.description && (
                <Grid item md={12} component={Box}>
                  <Description className="bigDescription">
                    {jsonData.description}
                  </Description>
                </Grid>
              )}
              {jsonData.items.map((item, index) => (
                <Grid item md={6} component={Box} key={index}>
                  <div className="featuresContainer">
                    <div className="featItem">
                      <Icon>{item.icon}</Icon>
                      <div className="featContent">
                        <h4>{item.title}</h4>
                        <div className="featDescription">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </StyledContainer>
      </FeaturesSection>
    );
  }
}

export default Features;
