import React, { Component } from "react";
import { Hero } from "./TopSection.styled";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { Button, StyledContainer } from "../styles/GlobalStyles";
import Services from "./Services";
import ServiceCard from "../components/cards/ServiceCard";

class TopSection extends Component {
  state = {};

  render() {
    const {
      title,
      type,
      description,
      link_title,
      link_url,
      picture,
      services,
    } = this.props;

    // console.log(services);
    return (
      <Hero {...this.props}>
        <StyledContainer maxWidth="xl">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="contentWrap"
          >
            <Grid
              item
              md={services ? 10 : 6}
              component={Box}
              className={services ? "slider-content single" : "slider-content"}
              order={{ xs: 1, md: 1 }}
              //   display={{ xs: "block", md: "none" }}
            >
              {/* <img
                loading="lazy"
                className="eyebrow-image"
                src="https://jwplayer.com/wp-content/uploads/2022/12/icon-sports-w.svg"
              ></img> */}

              {!services && (
                <>
                  <div
                    className="type"
                    dangerouslySetInnerHTML={{ __html: type }}
                  ></div>
                  <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
                </>
              )}

              {services && <h1 dangerouslySetInnerHTML={{ __html: type }}></h1>}

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>

              {link_title && (
                <div className="slider-btn">
                  <Button type="transparent">Portofoliu</Button>
                </div>
              )}
            </Grid>
            {!services && (
              <Grid
                item
                md={6}
                component={Box}
                className="slider-image"
                order={{ xs: 2, md: 2 }}
                //   display={{ xs: "none", md: "block" }}
              >
                <img
                  src={picture}
                  alt={this.props.title}
                  className="topImage"
                ></img>
              </Grid>
            )}
          </Grid>
          {services && (
            <>
              <div className="separator">Produse și Servicii</div>
              <div className="serviceList">
                {services
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })
                  .map((item, index) => (
                    <ServiceCard
                      service={item}
                      key={index}
                      className={
                        services.length < 3 ? "full" : "items" + services.length
                      }
                    ></ServiceCard>
                  ))}
              </div>
            </>
          )}
        </StyledContainer>
        {/* <Container maxWidth="xl">
          <div className="heroWrapper">
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: this.props.title }}
            ></h2>
            <h4 className="subtitle">{this.props.subtitle}</h4>
          </div>
        </Container> */}
      </Hero>
    );
  }
}

export default TopSection;
