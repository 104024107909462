import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const ExtraServices = styled(Section)`
  /* make changes as needed*/

  margin: 8rem 0;
  padding-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: -2rem 20px 2rem 20px;
    /* margin: 0; */
  }

  .showcaseNav {
    padding: 10px 100px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0;
      justify-content: start;
    }
  }

  .title {
    h2 {
      margin: 0;
      line-height: 1;
      font-size: 3rem;
      color: #6b7c93;
      /* margin-bottom: 16px; */

      /* color: #8797b2; */
      /* color: #0786a3; */
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 30px;
      color: #6b7c93;
      margin: 0;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 1rem;
      }
    }
  }

  .extraNav {
    display: flex;
    align-items: center;
    height: 90px;
  }

  .videoSliderWrap {
    .slick-dots {
      .slick-active {
        background: #0786a3;
      }
    }
    .slick-list {
      .slick-track {
        display: flex !important;
      }
    }

    .slick-slide {
      /* flex: 0 0 421px; */
      width: 421px;
      height: 162px;
      padding-right: 24px;
      @media (max-width: ${({ theme }) => theme.md}) {
        flex: 0 0 280px;
        width: 280px;
        height: 142px;
      }
      :first-child {
        margin-left: 100px;

        @media (max-width: ${({ theme }) => theme.md}) {
          margin-left: 0;
          /* margin: 0; */
        }
      }
      /* :last-child {
        padding-right: 0;
      } */

      .extra {
        border-radius: 12px;
        padding: 16px 24px;
        font-size: 14px;
        line-height: 24px;
        height: 100%;
        border: 1px solid #f1f1f1;
        color: #6b7c93;
        @media (max-width: ${({ theme }) => theme.md}) {
          padding: 8px 14px;
          font-size: 12px;
          line-height: 16px;
        }

        .extraTitle {
          display: flex;
          margin-bottom: 8px;
          font-weight: 900;
          font-size: 20px;
          line-height: 28px;
          @media (max-width: ${({ theme }) => theme.md}) {
            font-size: 14px;
            line-height: 32px;
          }

          img {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .line-indicator li {
    /* border: 2px solid; */
    height: 4px;
    width: 70px;
    background: lightgray;
    border-radius: 5px;
  }

  .line-indicator li:hover {
    background: gray;
  }

  .line-indicator li.slick-active {
    background: blue;
  }
  .buttons {
    width: 44px;
    height: 44px;
    background-color: #fff;
    border: 1.5px solid #ededed;
    color: #000;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
    display: flex;

    :first-of-type {
      margin-right: 10px;
    }

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .buttons:hover {
    /* background: white !important; */
    /* color: orangered !important; */
    border: 1.5px solid #202020;
    /* transform: scale(1.1); */
  }
`;
