import React from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import { PageWrap, StyledContainer } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";

import BlogAll from "../sections/Blog";
import usePageTracking from "../hooks/usePageTracking";

export default function BlogPage() {
  usePageTracking();
  return (
    <>
      <PageWrap className="static">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img>
        <Hero
          light="1"
          title="Blog"
          subtitle="Stai la curent cu cele mai noi tendințe în domeniul producției video!"
        ></Hero>
      </PageWrap>

      <BlogAll></BlogAll>
    </>
  );
}
