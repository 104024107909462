import React, { Component } from "react";
import { GroupSection } from "./Group.styled";
import { Title, Description, StyledContainer } from "../styles/GlobalStyles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

class Group extends Component {
  state = {};

  render() {
    const { jsonData } = this.props;

    return (
      <GroupSection {...this.props}>
        <StyledContainer maxWidth="xl">
          {jsonData.title && (
            <Description
              className="bigDescription"
              dangerouslySetInnerHTML={{ __html: jsonData.title }}
            ></Description>
          )}
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {jsonData.items.map((item, index) => (
              <Grid item md={6} component={Box} key={index}>
                <div className="GroupContainer">
                  <div className="featItem">
                    <Icon>{item.icon}</Icon>
                    <div className="featContent">
                      <h4>{item.title}</h4>
                      <div className="featDescription">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </StyledContainer>
      </GroupSection>
    );
  }
}

export default Group;
