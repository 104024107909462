import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const IntroAreaSection = styled(Section)`
  /* make changes as needed*/
  min-height: 1100px;
  @media (max-width: ${({ theme }) => theme.md}) {
    min-height: 800px;
    background-size: cover;
  }

  h2 {
    font-size: 60px;
    color: #fff;
    line-height: 1.3;
    margin-bottom: 10px;
    text-transform: uppercase;
    @media (max-width: ${({ theme }) => theme.lg}) {
      font-size: 60px;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 50px;
      text-align: center;
    }
    @media (max-width: ${({ theme }) => theme.sm}) {
      font-size: 40px;
      text-align: center;
    }
  }

  p {
    font-size: 24px;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 60px;
    margin-top: 0;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 18px;
      line-height: 1.2;
      text-align: center;
      margin-top: 20px;
    }
    @media (max-width: ${({ theme }) => theme.sm}) {
      font-size: 16px;
      line-height: 1.2;
      text-align: center;
      margin-top: 20px;
    }
  }

  .slider-btn {
    display: flex;
    > *:first-child {
      margin-right: 20px;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      flex-direction: column;
      > *:first-child {
        margin-bottom: 16px;
        margin-right: 0;
      }
    }
  }

  .MuiGrid-item {
    padding-top: 130px;
    @media (max-width: ${({ theme }) => theme.md}) {
      padding-top: 100px;
    }
  }
`;
