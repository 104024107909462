import React, { Component } from "react";
import IntroArea from "../sections/IntroArea";
import TwoSide from "../sections/TwoSide";
import Tiles from "../sections/Tiles";
import Hero from "../sections/Hero";
import Features from "../sections/Features";

import Next from "../sections/Next";
import Promo from "../sections/Promo";
import VideoHero from "../sections/VideoHero";

import Image1 from "../images/front/ovn_show_podcast.svg";
import Image2 from "../images/front/ovn_shows_graphics.svg";
import Image3 from "../images/front/ovn_mobile_apps.svg";
import Image4 from "../images/front/ovn_videoplayer_graphics.svg";

import Ecosystem from "../images/ovn_ecosystem.png";
import BaseCard from "../components/cards/BaseCard";
import FullPromo from "../sections/FullPromo";
import HeroTiles from "../sections/HeroTiles";
import { Link } from "react-router-dom";
import FAQ from "../sections/faq";

import vr1 from "../images/virtual/vkrs_1.jpg";

import vr3 from "../images/virtual/msd.png";

import usePageTracking from "../hooks/usePageTracking";

const features = {
  title: "<strong>Studio Virtual</strong> cu audienta <span>LIVE</span>",
  description:
    "Aveti la dispozitie o lume de posibilități într-un singur studio, folosind un design existent sau un spatiu 3D complet personalizat.",
  items: [
    {
      title: "Video Conferinte",
      description:
        "Adaugam grafica personalizata si elemente de branding conferintelor  pe ZOOM, Teams, WebEX sau Skype si le distribuim simultan catre site-ul dvs si platformele de live streaming.",
      icon: "settings_icon",
    },
    {
      title: "Evenimente Virtuale",
      description:
        "Un spatiu complet virtual, in format 3D. Evenimentele corporate reprezinta experiența și interacțiunea cu publicul, interacțiunea pe scenă, și sălile de recepție înainte de eveniment. Toate acestea le putem îndeplini din studioul nostru virtual de evenimente live.",
      icon: "wifi_icon",
    },
    {
      title: "Evenimente Hibride",
      description:
        "Un mix perfect intre o audienta remote si in locatie, solutia propusa poate fi executata fie in studio-ul nostru, fie intr-o alta locatie. Este ideala pentru lansari de produse, congrese si simpozioane.",
      icon: "security_icon",
    },
  ],
};

const tiles = [
  {
    title: "Video Web Player",
    text: "The fastest HTML5 video player on the web—so your content and ads render reliably and beautifully on every screen.",
    link_title: "Web Player",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2022/12/icon-player-web.svg",
  },

  {
    title: "OTT Video Apps",
    text: "A tailored end-to-end solution to deliver compelling video experiences across all screens.",
    link_title: "Learn more",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2022/12/icon-ott-apps.svg",
  },
  {
    title: "Live & VOD Streaming",
    text: "24/7 Live, instant live events, and comprehensive catch-up services to connect and engage deeper with global audiences.",
    link_title: "Learn more",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2022/12/icon-live-channels-3.svg",
  },

  {
    title: "Studio DRM",
    text: "Studio-approved DRM, geo-blocking, HLS token signing and more.",
    link_title: "Learn more",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2022/12/icon-drm.svg",
  },

  {
    title: "Subscriptions",
    text: "Let JWP help you diversify your revenue streams.",
    link_title: "Learn more",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2023/02/icon-subscriptions.svg",
  },

  {
    title: "Web App",
    text: "Easily deploy engaging viewer experiences across devices.",
    link_title: "Learn more",
    link_url: "#",
    icon: "https://jwplayer.com/wp-content/uploads/2023/02/icon-recommendations.svg",
  },
];

export default function HomePage() {
  usePageTracking();
  return (
    <React.Fragment>
      <IntroArea></IntroArea>
      {/* <Promo></Promo> */}

      {/* <Hero
          // light="1"
          title="Quickly and easily stream live events with Live Channels."
          subtitle="Launch live events simply, without delays or technical challenges with an all in one solution."
        ></Hero> */}
      <HeroTiles></HeroTiles>
      <Hero
        title="Un ecosistem complet pentru ideile tale"
        subtitle="De la producția video la
              distribuție și monetizare."
      >
        <br />
        <img src={Ecosystem} alt="OVN ecosystem" />
      </Hero>

      {/* <Hero
          title="Be the first to use our Beta!"
          subtitle="Become part of our ever growing community.."
        ></Hero> */}

      {/* <VideoHero></VideoHero> */}

      <TwoSide
        section="Live Streaming"
        title="Inovație  și creativitate în transmisiunile live"
        description="Soluție profesională de streaming video  ce presupune transmisia evenimentelor din orice locație, pe orice dispozitiv și pe orice platformă existentă."
        link_title="Play Everywere"
        link_url="/livestreaming/competitii-sportive"
        picture={Image2}
      ></TwoSide>

      <FullPromo
        subtitle="Studio Virtual 3D"
        title="Posibilități infinite într-un singur studio, cu un spatiu 3D
          complet personalizat."
        description=" Oferă o experiență unică și captează toată atenția publicului.
          Iți oferim un serviciu complet de la concept la producția video
          LIVE."
        link_text="Află mai mult"
        link_url="/studio/virtual-events"
        image={[
          vr1,
          "https://api.ovn.ro/uploads/conferinta_virtuala_6c9aafb1fb.png",
          vr3,
        ]}
      ></FullPromo>
      <TwoSide
        reverse
        section="Studio Space"
        title="Găzduim emisiuni și proiecte video"
        description="Descoperă studio-ul nostru din București unde producem evenimente virtuale, emisiuni sau podcast-uri cu toată infrastructura tehnică necesară, chroma key și conexiuni redundante la internet."
        link_title="Deliver Instantly"
        link_url=""
        picture={Image1}
      >
        {/* <p>
            Alt text intr-un alt <strong> html tag</strong> cu text bold si in{" "}
            <Link to="/ceva">link </Link>ce merge catre ceva
          </p> */}
        <ul>
          <li>
            <strong>Emisiuni cu frecvență săptămânală.</strong>
            <br />
            Ai acces la toate resursele studioului nostru la un cost imbatabil.
          </li>
          <li>
            Afli primul și implementezi rapid cele mai noi tendințe și
            tehnologii in domeniul producției video.
          </li>
          <li>
            Ai suport complet și consultanță video gratuită pentru proiectele
            tale.
          </li>
        </ul>
      </TwoSide>
      {/* <Tiles
          section="Tiles"
          title="Standalone Products for Any Video Use Case"
          tiles={tiles}
        ></Tiles> */}
      {/* <Promo></Promo> */}

      {/* <BaseCard
          icon="https://jwplayer.com/wp-content/uploads/2022/12/icon-stream.svg"
          type="Video Platform"
          title="Accelerate Your Video Strategy"
          text="Easily scale your video strategy with a centralized, all-in-one
          platform"
          link_url="#"
          link_title="Learn more"
        ></BaseCard> */}

      {/* <Features backgroundColor="#f8f8f8" jsonData={features}></Features> */}
      <TwoSide
        section="Video CDN"
        title="Platforma video si Microsite"
        description="Distribuim continutul video public sau privat prin intermediul platformei noastre catre publicul, clientii sau colegii dvs."
        link_url="/video-development/video-player"
        link_title="Discover more"
        picture={Image4}
      >
        <ul>
          <li>
            <strong>Retransmitem automat</strong> video-ul către toate
            platformele video din lume pentru ca publicul tău să te urmărească
            pe platforma lor preferată.
          </li>

          <li>
            <strong>Protejăm conținutul tău</strong> video în cazul în care este
            blocat automat de sistemele de protecție ale Facebook sau Youtube.
          </li>
          <li>
            <strong>Platforma Video</strong> permite accesarea conținutului pe
            baza de parolă, listă predefinită de utilizatori sau pe baza de
            paywall.
          </li>
        </ul>
      </TwoSide>

      <TwoSide
        reverse
        section="DEVELOPMENT"
        title="Aplicatii video pentru WEB, IOS, Android & TV"
        description="Preia controlul asupra conținutu-lui video, într-un spatiu virtual, nerestricționat de alte platforme, unde poate fi monetizat la adevarata lui valoare."
        link_title="Free your content!"
        link_url="/video-development/ios-android-apps"
        picture={Image3}
      >
        <p>
          <strong>
            Maximizează încasările cu ajutorul aplicației noastre, fără
            intermediari.
          </strong>
        </p>
        <ul>
          <li>
            Crează propria aplicație IOS & Android personalizată cu culorile și
            identitatea brandului tău.
          </li>
          <li>
            Permite fanilor tăi accesul la <strong>conținut exclusiv</strong> pe
            bază de abonament sau one time payment.
          </li>

          <li>
            Adaugă modului de shop/merch sau video dedicatii pentru a mări
            încasările tale lunare
          </li>
        </ul>
      </TwoSide>

      <Next backgroundColor="#0786a3"></Next>
      <FAQ />

      {/* <Features backgroundColor="#f8f8f8" jsonData={features}></Features> */}
    </React.Fragment>
  );
}
