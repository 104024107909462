import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const GroupSection = styled(Section)`
  padding: 5rem 0;
  margin: 5rem 0;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .MuiGrid-item {
    padding: 0 2rem 1rem 0.5rem;

    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 3rem 0 0rem;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0 1rem;
      margin-bottom: 3rem;
    }
  }

  .bigDescription {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.4;
    margin: 0 10% 2rem 10%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #0786a3;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 3rem;
      line-height: 1.2;
    }
  }

  .featItem {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    @media (max-width: ${({ theme }) => theme.sm}) {
      flex-direction: column;
      align-items: center;
    }

    span {
      margin: 0 1rem 0 0;
      height: 80px;
      width: 80px;
      color: #35c2d8;
      font-size: 80px;
      text-align: center;
      @media (max-width: ${({ theme }) => theme.sm}) {
        margin: 0;
        // height: 100px;
        // width: 100px;
      }
    }

    .featContent {
      h4 {
        font-size: 2rem;
        margin: 0;
        font-weight: 700;
        color: #6b7c93;
        font-family: "Poppins", sans-serif;
        @media (max-width: ${({ theme }) => theme.sm}) {
          text-align: center;
        }
      }

      .featDescription {
        margin-top: 0.5em;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.4;
          text-align: left;
          color: #6b7c93;
          opacity: 0.7;

          margin: 0.2rem 0 0 0;

          @media (max-width: ${({ theme }) => theme.sm}) {
            text-align: center;
          }
        }
      }
    }
  }
`;
