import { TrendingUpRounded } from "@material-ui/icons";
import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  border-radius: 12px;
  overflow: hidden;
`;
const Video = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
function VideoEmbed({ url, light = true }) {
  return (
    <VideoWrapper>
      <Video
        width="100%"
        height="100%"
        url={url}
        light={light}
        controls={true}
      />
    </VideoWrapper>
  );
}

export default VideoEmbed;
