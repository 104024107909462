import { createGlobalStyle } from "styled-components";

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Lato', sans-serif;
	font-weight: normal;
	font-size: 16px;
	font-style: normal;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
  }
  .f-left {
    float: left
  }
  .f-right {
    float: right
  }
  .fix {
    overflow: hidden
  }
  a,
  .button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .btn:focus,
  a:focus,
  .button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  a:hover,
  .portfolio-cat a:hover,
  .footer -menu li a:hover {
    color: #2B96CC;
    text-decoration: none;
  }
  a,
  button {
    color: #0786a3;
    outline: medium none;
  }
  button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
    color: #47495a;
    margin-top: 0px;
    font-style: normal;
    font-weight: 700;
    text-transform: normal;
  }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
  }
  h1 {
    font-size: 40px;
    font-weight: 500;
  }
  h2 {
    font-size: 35px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none
  }
  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    color: #6b7c93;
    margin-bottom: 15px;
  }
  hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
  }
  label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }
  *::-moz-selection {
    background: #d6b161;
    color: #fff;
    text-shadow: none;
  }
  ::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none;
  }
  ::selection {
    background: #444;
    color: #fff;
    text-shadow: none;
  }
  *::-moz-placeholder {
    color: #555555;
    font-size: 14px;
    opacity: 1;
  }
  *::placeholder {
    color: #555555;
    font-size: 14px;
    opacity: 1;
  }
  .theme-overlay {
    position: relative
  }
  .theme-overlay::before {
    background: #1696e7 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .separator {
    border-top: 1px solid #f2f2f2
  }
  
`;
