import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const CategoryListSection = styled(Section)`
  overflow: unset;
  h2 {
    margin: 0;
    line-height: 1;
    font-size: 3rem;
    color: #6b7c93;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 2rem;
      text-align: center;
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.md}) {
      text-align: center;
      line-height: 1.2;
      flex-direction: column;
    }

    p {
      margin: 0;
      line-height: inherit;
    }

    a {
      font-weight: bold;
    }
  }

  .categoryContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-left: -12px;
    margin-right: -12px;

    @media (max-width: ${({ theme }) => theme.md}) {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin: 0;
      flex-direction: column;
      overflow: unset;
      margin: 0 -16px;
    }

    .sectionWrap {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: ${({ theme }) => theme.md}) {
        /* overflow: hidden; */
        display: block;
      }

      .sectionName {
        position: sticky;
        top: 10%;
        flex: 0 0 400px;
        overflow: hidden;
        padding-top: 0.5rem;

        @media (max-width: ${({ theme }) => theme.md}) {
          top: 0;
          z-index: 10;
          padding: 10px 0;
          top: 60px;
          z-index: 2;
          background: #fff;
        }

        h1 {
          color: #0786a3;
          font-size: 2rem;
          font-weight: 700;
          position: relative;
          text-transform: uppercase;
          @media (max-width: ${({ theme }) => theme.md}) {
            font-size: 1.5rem;
            margin: 0;
            padding: 0 16px;
          }
          display: inline-block;
          :after {
            position: absolute;
            background: #0786a3;
            content: "";
            width: 400%;
            max-width: 400px;
            height: 2px;
            top: 50%;
            left: 110%;
          }
        }
      }
    }
  }
`;
