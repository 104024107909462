import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { FieldConfig, useField } from "formik";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#0786a3",
      color: "#fff",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "#0786a3",

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#0786a3",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#ffffff",
      color: "#545454",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#0786a3",
    },

    "& .MuiFilledInput-underline:after": {
      borderBottom: "2px solid #0786a3",
    },
    "& .MuiInputLabel-filled": {
      color: "#a7f2ff",
    },

    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #266798 inset",
      WebkitTextFillColor: "#fff",
    },
  },
}));

export default function InputField({
  label,
  basicoptions,
  helperText,
  formik,
  ...props
}) {
  const [field, meta] = useField(props);
  //   console.log(props, field);

  const classes = useStyles();

  return (
    <TextField
      fullWidth
      variant="filled"
      label={label}
      className={classes.root}
      {...field}
      {...props}
      //   helperText={helperText}

      //   onBlur={meta.handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        meta.touched && meta.error ? meta.touched && meta.error : helperText
      }
    >
      {basicoptions &&
        basicoptions.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option}
          </MenuItem>
        ))}
    </TextField>
  );
}
