import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const VideoSection = styled(Section)`
  margin: 2rem 0;
  padding: 2rem 0;
  min-height: 400px;
  h2 {
    width: calc(100% - 630px);
    font-size: 54px;
    line-height: 68px;
    /* margin: 0 auto 58px; */
    margin-bottom: 53px;
    color: #fff;
  }

  .blogWrap {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -12px;
  }

  button {
    display: block;
    margin: 0 auto;
    min-width: 250px;
  }
`;

export const VideoDetailsSection = styled.article`
  margin: 2rem 0;
  padding: 2rem 0;
  min-height: 400px;

  .contactus {
    margin: 4rem 0 0 0;
    padding: 1rem;
    background: #202020;
    border-radius: 12px;
    text-align: center;
  }

  .VideoDetailsText {
    padding-right: 48px;

    span {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
      text-transform: uppercase;
      opacity: 0.5;
    }

    h2 {
      color: #fff;
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 1rem;
    }

    .description p {
      color: #fff;
    }

    .categories span {
      font-size: 14px;
      line-height: 24px;
      background: #ededed;
      border-radius: 8px;
      padding: 8px 16px;
      white-space: nowrap;
      margin-right: 12px;
      cursor: pointer;
      background: #202020;
      color: #fff;
      text-transform: none;
      opacity: 1;
    }
  }
`;
