import React, { Component } from "react";
import { HeroTilesSection } from "./HeroTiles.styled";
import { Title, Description, StyledContainer } from "../styles/GlobalStyles";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import vr1 from "../images/virtual/vkrs_0.jpg";
import vr2 from "../images/virtual/vkrs_1.jpg";
import vr3 from "../images/virtual/vkrs_2.jpg";

export default class HeroTiles extends Component {
  state = {};

  render() {
    const { jsonData } = this.props;

    return (
      <HeroTilesSection {...this.props}>
        <StyledContainer maxWidth="xl">
          <div className="cardWrap">
            <div className="item marketplace">
              <Link to="/services">
                <span className="title">Servicii Video</span>
                <h3>Descoperă lista completă de servicii video</h3>
                <span className="link">Află mai mult </span>
              </Link>
            </div>

            <div className="item d-flex">
              <div className="item locations">
                <Link to="/cariere">
                  <span className="title">Cariere</span>
                  <h3>Hai să dezvoltăm împreună proiecte de succes!</h3>
                  <span className="link">Poziții disponibile</span>
                </Link>
              </div>

              <div className="item blog">
                <Link to="/blog">
                  <span className="title">Blog</span>
                  <h3>Cele mai noi tendințe în domeniul producției video</h3>
                  <span className="link">OVN Blog</span>
                </Link>
              </div>
            </div>
            <div className="item inspiration">
              <Link to="/showcase">
                <span className="title">Show Case</span>
                <h3>Inspiră-te din cel mai bun conținut video online</h3>
                <span className="link">Get inspired </span>
              </Link>
            </div>
          </div>
        </StyledContainer>
      </HeroTilesSection>
    );
  }
}
