import React, { Component } from "react";

import { TwoSideSection } from "./TwoSide.styled";

import {
  Button,
  Title,
  SubTitle,
  Description,
  StyledContainer,
} from "../styles/GlobalStyles";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import demo1 from "../images/demo2.png";

class TwoSide extends Component {
  state = {};
  render() {
    const {
      title,
      section,
      description,
      link_title,
      link_url,
      picture,
      children,
    } = this.props;
    return (
      <TwoSideSection {...this.props}>
        <StyledContainer maxWidth="xl">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              md={6}
              component={Box}
              className="slider-content"
              order={{ xs: 2, md: 1 }}
              //   display={{ xs: "block", md: "none" }}
            >
              <SubTitle>{section}</SubTitle>
              <Title>{title}</Title>
              <Description>
                <p>{description}</p>
                {children}
              </Description>
              {link_title && (
                <div className="slider-btn">
                  <Button type="blue">{link_title}</Button>
                </div>
              )}
            </Grid>

            <Grid
              item
              md={6}
              component={Box}
              className="slider-image"
              order={{ xs: 1, md: 2 }}
              //   display={{ xs: "none", md: "block" }}
            >
              <img src={picture} alt="ovn_intro"></img>
            </Grid>
          </Grid>
        </StyledContainer>
      </TwoSideSection>
    );
  }
}

export default TwoSide;
