import React, { Component, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import { ReactComponent as LogoSimple } from "../ovn_logo_full.svg";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// import Container from "@material-ui/core/Container";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { StyledContainer } from "../styles/GlobalStyles";
import useFetch from "../hooks/useFetch";
import useSticky from "../hooks/useSticky";

import Svgicon from "./SvgIcon";

class StartupSticky extends Sticky {
  componentDidMount() {
    super.componentDidMount();
    const scrollEvent = {
      scroll: {
        delta: 1, // down
        top: window.scrollY,
      },
    };
    // react-sticky's initialization isn't actually complete until the state
    // change *after* componentDidMount. Therefore, we add a callback to fire
    // after that state change...
    /* eslint-disable react/no-did-mount-set-state */
    this.setState({}, () => this.handleScroll(null, scrollEvent));
    /* eslint-enable react/no-did-mount-set-state */
  }
}

function Header() {
  const { sticky, stickyRef } = useSticky();
  const [open, setOpen] = useState(false);

  // handleMenuClick() {
  //  setOpen(!open);
  // }

  // handleLinkClick() {
  //   setOpen(false);
  // }
  let menu = [
    {
      name: "Despre",
      path: "/despre",
      items: [
        {
          name: "Despre noi",
          path: "/despre",
          text: "În 2023 împlinim 10 ani în inovația proiectelor video",
        },
        {
          name: "Cariere",
          path: "/cariere",
          text: "Poziții disponibile în echipa noastră",
        },
        {
          name: "Showcase",
          path: "/showcase",
          text: "Proiectele noastre cele mai bune ca să te inspire",
        },
        {
          name: "Blog",
          path: "/blog",
          icon: "news",
          text: "Te ținem la curent cu cele mai noi tendințe video",
        },
      ],
    },

    // {
    //   name: "Servicii",
    //   path: "/servicii",
    //   items: [
    //     { name: "Productie Video", path: "/productie" },
    //     { name: "Regie TV Mobila", path: "/regie-mobila" },
    //     { name: "Studio TV & Chroma Key", path: "studio-space" },
    //     { name: "Platforma Video / CDN", path: "/platforma-video" },
    //     { name: "Galerie video /Microsite", path: "/galerie-video" },
    //     { name: "Aplicatii IOS & Android", path: "/aplicatii-ios-android" },
    //     { name: "Inchiriere Echipamente", path: "/inchiriere-echipamente" },
    //   ],
    // },

    // {
    //   name: "Solutii",
    //   path: "/solutii",
    //   items: [
    //     { name: "Publicatii", path: "/solutii/publicatii" },
    //     { name: "Agentii Publicitate", path: "/solutii/agentii-publicitate" },
    //     { name: "Radio si Televiziuni", path: "/solutii/radio-tv" },
    //     { name: "Companii / Asociatii", path: "/solutii/companii-asociatii" },
    //     {
    //       name: "Provideri TV / Internet",
    //       path: "/solutii/provideri-tv-internet",
    //     },
    //     {
    //       name: "Organizatori Evenimente",
    //       path: "/solutii/organizatori-evenimente",
    //     },
    //     // { name: "Televiziuni", path: "/solutii/televiziuni" },
    //   ],
    // },
    { name: "Academy", path: "/academy" },
    { name: "Cotație Preț", path: "/cotatie" },
    { name: "Contact", path: "/contact" },
  ];

  let { loading, error, data } = useFetch(
    "https://api.ovn.ro/api/sections?populate=categories&sort=id"
  );

  let menuFix = [];
  // console.log(data);
  let nimic = data?.map((item) => {
    let section = {};
    section.name = item.title;
    section.path = "/" + item.slug;
    section.items = [];
    item.categories
      .sort((a, b) => a.menu_order - b.menu_order)
      .map((sub) => {
        let category = {};
        category.name = sub.title;
        category.path = section.path + "/" + sub.slug;
        category.icon = sub.icon;
        category.text = sub.menu_description;
        section.items.push(category);
      });

    menuFix.push(section);
  });

  menu = [...menuFix, ...menu];
  // console.log(menu);

  const menuItems = menu.map((item, index) => {
    return (
      <MenuItem
        key={index}
        delay={`${index * 0.1}s`}
        onClick={() => {
          setOpen(false);
        }}
        {...item}
      >
        <NavLink to={item.path}>{item.name}</NavLink>
      </MenuItem>
    );
  });

  const menuItemsLarge = menu.map((item, index) => {
    if (item.name === "Contact") return;
    const hasSubMenu =
      Array.isArray(item.items) && item.items.length > 0 ? true : false;
    return (
      <li key={index} className={hasSubMenu ? "menu-item-has-children" : ""}>
        {hasSubMenu && <a>{item.name}</a>}
        {!hasSubMenu && <NavLink to={item.path}>{item.name}</NavLink>}

        {Array.isArray(item.items) ? (
          <div className="submenuWrap">
            <ul className="submenu">
              {item.items.map((subItem) => (
                <li
                  key={subItem.path + index}
                  delay={`${index * 0.1}s`}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <NavLink to={subItem.path}>
                    <div className="left">
                      {/* <img
                        src="https://jwplayer.com/wp-content/uploads/2022/12/icon-live-channels-3.svg"
                        alt="icon-live-channels"
                      /> */}
                      <Svgicon name={subItem.icon} />
                    </div>

                    <div className="right">
                      <span> {subItem.name}</span>
                      <p>{subItem.text ? subItem.text : ""}</p>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </li>
    );
  });

  return (
    <React.Fragment>
      <Menu open={open}>{menuItems}</Menu>

      {/* <StartupSticky
        top={0}
        innerZ={9999}
        // bottomBoundary="footer"
        activeClass="sticky-nav-active"
      > */}
      <nav
        className={`hosting_navbar ${sticky ? "sticky-nav-active" : ""}`}
        ref={stickyRef}
      >
        <StyledContainer maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item md={2} xs={6}>
              <div className="ovnBrand">
                <NavLink to="/">
                  <LogoSimple className="logoSimple" />
                </NavLink>
              </div>
            </Grid>

            <Grid
              component={Box}
              item
              lg={9}
              display={{ xs: "none", md: "block" }}
            >
              <div className="menu-area">
                <div className="main-menu textRight">
                  {/* <div className="main-menu"> */}
                  <ul>{menuItemsLarge}</ul>
                </div>
              </div>
            </Grid>
            <Grid
              component={Box}
              item
              lg={1}
              md={1}
              display={{ xs: "none", md: "block" }}
            >
              <div className="textRight">
                <NavLink to={"/contact"} className="contactButton">
                  Contact
                </NavLink>
              </div>
            </Grid>

            <Grid
              component={Box}
              item
              md={1}
              display={{ xs: "block", md: "none" }}
            >
              <MenuButton
                open={open}
                onClick={() => setOpen(!open)}
                color="#35c2d8"
              />
            </Grid>
          </Grid>
        </StyledContainer>
      </nav>
      {/* </StartupSticky> */}
    </React.Fragment>
  );
}

export default Header;

/* MenuItem.jsx*/

function MenuItem(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     hover: false,
  //     open: false,
  //   };
  // }

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const styles = {
    container: {
      opacity: 0,
      animation: "1s appear forwards",
      animationDelay: props.delay,
    },
    menuItem: {
      fontFamily: `'Poppins', sans-serif`,
      fontSize: "1.4rem",
      padding: "1rem 0",
      margin: "0 5%",
      cursor: "pointer",
      color: hover ? "#fafafa" : "#fafafa",
      transition: "color 0.2s ease-in-out",
      animation: "0.5s slideIn forwards",
      animationDelay: props.delay,
      display: "flex",
      alignItems: "center",
    },
    line: {
      width: "90%",
      height: "1px",
      background: "white",
      opacity: 0.4,
      margin: "0 auto",
      animation: "0.5s shrink forwards",
      animationDelay: props.delay,
    },
  };

  return (
    <div style={styles.container}>
      <div
        style={styles.menuItem}
        onMouseEnter={() => {
          setHover(!hover);
        }}
        onMouseLeave={() => {
          setHover(!hover);
        }}
        onClick={
          !props.items
            ? props.onClick
            : () => {
                setOpen(!open);
              }
        }
      >
        {!props.items && props.children}
        {props.items && (
          <React.Fragment>
            {/* <NavLink to={this.props.path}>{this.props.name}</NavLink> */}
            <span>{props.name}</span>
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          </React.Fragment>
        )}
      </div>
      {!props.isSubMenu && <div style={styles.line} />}
      {props.items && open && (
        <div className="subMenu">
          {props.items.map((subItem, index) => (
            <MenuItem
              key={subItem.path + index}
              isSubMenu={true}
              delay={`${index * 0.1}s`}
              onClick={props.onClick}
            >
              <NavLink to={subItem.path}> - {subItem.name}</NavLink>{" "}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  );
}

/* Menu.jsx  */
function Menu(props) {
  const [open, setOpen] = useState(props.open ? props.open : false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const styles = {
    container: {
      position: "fixed",
      top: "4rem",
      left: 0,
      height: open ? "100%" : 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      background: "rgb(27, 187, 214)",
      opacity: 1,
      color: "#fafafa",
      transition: "height 0.3s ease",
      zIndex: 2,
    },
    menuList: {
      paddingTop: "2rem",
      paddingBottom: "10rem",
      // position: "fixed"
    },
  };
  return (
    <div style={styles.container} className="mobileScroll">
      {open ? (
        <div style={styles.menuList} className="mobileMenu">
          {props.children}
        </div>
      ) : null}
    </div>
  );
}

function MenuButton(props) {
  const [color, setColor] = useState(props.color ? props.color : "black");
  const [open, setOpen] = useState(props.open ? props.open : false);

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: this.props.open ? this.props.open : false,
  //     color: this.p,
  //   };
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.open !== this.state.open) {
  //     this.setState({ open: nextProps.open });
  //   }
  // }

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const styles = {
    container: {
      height: "64px",
      width: "64px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      padding: "4px",
    },
    line: {
      height: "4px",
      width: "40px",
      background: color,
      transition: "all 0.2s ease",
    },
    lineTop: {
      transform: open ? "rotate(45deg)" : "none",
      transformOrigin: "top left",
      marginBottom: "9px",
    },
    lineMiddle: {
      opacity: open ? 0 : 1,
      transform: open ? "translateX(-16px)" : "none",
    },
    lineBottom: {
      transform: open ? "translateX(-3px) rotate(-45deg)" : "none",
      transformOrigin: "top left",
      marginTop: "10px",
    },
  };

  return (
    <div
      style={styles.container}
      onClick={
        props.onClick
          ? props.onClick
          : () => {
              setOpen(!open);
            }
      }
    >
      <div style={{ ...styles.line, ...styles.lineTop }} />
      <div style={{ ...styles.line, ...styles.lineMiddle }} />
      <div style={{ ...styles.line, ...styles.lineBottom }} />
    </div>
  );
}
