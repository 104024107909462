import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const HeroSection = styled(Section)`
  margin: 3rem 0;
  padding: 3rem 0;
  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 1rem 0;
    padding: 1rem 0;
  }

  .heroWrapper {
    padding: 0 10rem;

    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 3rem;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0;
    }
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    line-height: 1.2;
    text-transform: none;
    color: #0786a3;
    color: ${(props) => (props.light ? "#fff" : "#0786a3")};

    @media (max-width: ${({ theme }) => theme.lg}) {
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 2rem;
      line-height: 1.2;
    }

    span {
      color: #de3a31;
      text-shadow: 0px 0px 12px rgba(255, 255, 255, 0.8);
    }
  }

  .subtitle {
    text-align: center;
    font-size: 2rem;
    line-height: 1.2;
    margin: 0 auto;
    /* margin-top: 1.5rem; */
    padding: 0 1rem;
    text-transform: none;
    max-width: 75%;
    color: ${(props) => (props.light ? "#f8f8f8" : "#6b7c93")};
    opacity: 0.7;

    font-weight: 400;
    @media (max-width: ${({ theme }) => theme.lg}) {
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 1rem;
      line-height: 1.2;
      max-width: 90%;
      margin-top: 2rem;
    }
  }

  img {
    width: 100%;
  }
`;
