import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const ServiceSection = styled(Section)`
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  margin-top: -180px;
  position: relative;

  // margin-top:-280px;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 0;
    padding: 0;
  }

  h2 {
    text-align: center;
    opacity: 0.6;
    line-height: 1em;
    margin: 1rem;
  }

  .promoWrap {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;

    background: #fff;
    padding: 30px;
    border-radius: 12px;
    margin: 0;
    min-height: 200px;
    // box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.15) !important;

    // @media (max-width: ${({ theme }) => theme.sm}) {
    //   margin-top: 60px;
    // }

    .secondPromo {
      /* min-width: 0;
      flex-basis: 19%;
      // margin-right: 1%;
      // align-items: stretch;
      align-self: stretch;
      display: flex;
      margin: 0.5%; */

      // &:last-of-type {
      //   margin-right: 0;
      // }

      @media (max-width: ${({ theme }) => theme.md}) {
        flex-basis: 40%;
        flex-grow: 1;
      }

      @media (max-width: ${({ theme }) => theme.sm}) {
        flex-basis: 100%;
      }
    }
  }
`;
