import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { SecondaryPageWrap } from "../../styles/GlobalStyles";
import TopSection from "../../sections/TopSection";
import LogoSlider from "../../sections/Slider";
import Services from "../../sections/Services";
import TwoSide from "../../sections/TwoSide";
import Next from "../../sections/Next";

import Image1 from "../../images/demo/1.svg";

import Digisport from "../../images/logotv/Digi_Sport_logo.svg";
import Eurosport from "../../images/logotv/Eurosport_Logo_2015.svg";
import ProArena from "../../images/logotv/PRO_Arena.svg";
import PrimaSport from "../../images/logotv/Prima_Sport_logo.svg";
import ZUTV from "../../images/logotv/ZUTVLogo.png";
import Antena1 from "../../images/logotv/antena1.svg";
import LookSport from "../../images/logotv/LookSport.png";

const tv_logos = [
  Eurosport,
  Digisport,
  ProArena,
  Antena1,
  PrimaSport,
  ZUTV,
  LookSport,
];

class BrandsCorporatePage extends Component {
  state = {};
  render() {
    return (
      <>
        <TopSection
          light="1"
          type="Brands & Corporate"
          title="Facem echipa pentru productia video LIVE  a competitiilor sportive "
          description="Transmitem live orice eveniment din orice locație. De la evenimente corporative la festivaluri de muzică sau lansari de produs surprindem atmosfera locatiei si maximizam interesul publicului. "
          picture="https://jwplayer.com/wp-content/uploads/2022/12/86ee788b-642b-4b0a-bb58-524f8bb0f9a2_ott-sports-min.webp"
          //   link_title="Hello"
        ></TopSection>

        <TwoSide
          section="Event Production"
          title="Regie LIVE cu 12 Camere FullHD/4k"
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        >
          <p>
            Surprindem cele mai importante momente si emotii pentru spectatori
            si cream o expunere maxima pentru branduri si parteneri.
          </p>
        </TwoSide>
        <TwoSide
          reverse
          section="Video"
          title="Replay & Sumar eveniment"
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        >
          <p>
            Reluarea instantanee permite fanilor să vadă acțiunea de mai multe
            ori. Folosim unghiuri de filmare multiple pentru a oferi diversitate
            și profunzime unei producții sportive.
          </p>
          <p>
            La final va punem la dispozitie un clip video cu cele mai importante
            faze.
          </p>
        </TwoSide>
        <TwoSide
          section="Live Streaming"
          title="Comentariu"
          description="Un comentator poate crește entuziasmul fanilor în momentele cheie și poate oferi analize și perspectivă. Fie ca este in locatie sau remote il putem integra in productia noastra LIVE."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>

        <TwoSide
          reverse
          section="Graphic Design"
          title="Elemente Grafice"
          description="Construim impreuna un pachet grafic complet, adaptat exact nevoilor tale sau folosim unul din pachetele deja existente specifice sportului."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
        <TwoSide
          section="4G Bonding Tehnology"
          title="Conectivitate Multipla la Internet"
          description="Dispunem de cele mai noi tehnologii pentru o experienta video fara intreruperi. Folosim conexiuni redundante la internet, prin satelit sau 4G Video Bonding cu pana la 8 cartele 4G LTE."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>

        <TwoSide
          reverse
          section="Mobile Video Production"
          title="Bicicleta, Scuter sau Masina"
          description="Suntem acolo unde este actiunea. Transmitem constant cele mai importante secvente pentru ca spectatorii si fanii sportului sa beneficieze de cele mai spectaculoase momente."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
        <TwoSide
          section="Marketing"
          title="Distributie si Monetizare"
          description="Fie ca vrei sa transmiti competitia sportiva pe Internet sau TV, noi te putem ajuta sa ajungi la fanii sportului. Iti punem la dispozitie parteneriate cu agentii de presa si posturi TV nationale."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
        <LogoSlider
          title="Posturi TV partenere"
          //   subtitle="Innovative industry solutions to flawlessly stream video, improve audience engagement, and better monetize video content."

          slides={tv_logos}
        ></LogoSlider>

        <Next backgroundColor="#0786a3"></Next>
      </>
    );
  }
}

export default BrandsCorporatePage;
