import React, { Component } from "react";
import { NextSection, Steps } from "./Next.styled";
import { Button, StyledContainer } from "../styles/GlobalStyles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import { ReactComponent as Step1 } from "../images/next/step1.svg";
import { ReactComponent as Step2 } from "../images/next/step2.svg";
import { ReactComponent as Step3 } from "../images/next/step3.svg";
import { Link } from "react-router-dom";

class Group extends Component {
  state = {};

  render() {
    const { jsonData } = this.props;

    return (
      <>
        <Steps maxWidth="xl">
          <StyledContainer maxWidth="xl">
            <h4>What next?</h4>
            <div className="stepsWrap">
              <div className="stepItem">
                <Step1 />
                <h5>Conversation</h5>
                <p>
                  Get in touch and let’s talk about your requirements, key
                  objectives and video brief.
                </p>
              </div>

              <div className="stepItem">
                <Step2 />
                <h5>Ideas</h5>
                <p>
                  We’ll then formulate some brilliant ideas to get the best
                  results from your video project.
                </p>
              </div>

              <div className="stepItem">
                <Step3 />
                <h5>Quote</h5>
                <p>
                  Providing the best possible price that is competitive, fits
                  with your budget and offers the best value.
                </p>
              </div>
            </div>
          </StyledContainer>
        </Steps>
        <NextSection {...this.props}>
          <StyledContainer maxWidth="xl">
            <div className="ready">
              <h4>Ready to talk about your live event?</h4>

              <Link to="/cotatie" className="btn transparent-btn">
                Contact Us
              </Link>
            </div>
          </StyledContainer>
        </NextSection>
      </>
    );
  }
}

export default Group;
