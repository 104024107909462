import styled from "styled-components";

export const CategoryWrap = styled.div`
  flex: 0 0 33.33333%;
  max-width: 33.333333%;
  height: 380px;
  margin-right: 0;
  padding: 0 12px 24px;
  position: relative;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.md}) {
    flex: 0 0 300px;
    max-width: 300px;
    height: 250px;
  }

  :hover .item::before {
    background: rgba(0, 0, 0, 0.5);
  }

  .item {
    height: 100%;
    padding: 2rem;
    color: #fff;
    position: relative;
    background-size: cover;
    background-position: center;
    display: block;
    flex-direction: column;
    border-radius: 12px;
    @media (max-width: ${({ theme }) => theme.md}) {
      text-align: center;
      padding: 1rem;
      overflow: hidden;
      justify-content: center;
      display: flex;
    }

    ::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      /* background: linear-gradient(
        rgba(7, 135, 164, 0.8) 41.15%,
        rgba(54, 194, 217, 0.8) 100%
      ); */
      background: rgba(0, 0, 0, 0.7);
      transition: all 0.3s ease 0s;
      border-radius: 12px;
    }

    h3 {
      color: #fff;
      margin: 0 0 1rem;
      font-size: 2rem;
      position: relative;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }
    .section {
      position: relative;
      color: #fff;
    }
    .description {
      position: relative;
      margin-bottom: 1rem;
      p {
        color: #fff;
        line-height: 1.4;
        opacity: 0.7;
        /* font-size: 1.2rem; */
      }
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 12px;
        display: none;
      }
    }
  }

  .serviceCount {
    transition: all 0.5s ease;

    @media (max-width: ${({ theme }) => theme.md}) {
      display: none;
    }
    span {
      position: absolute;
      bottom: 48px;
      left: 44px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      cursor: pointer;
      display: block;
      color: #fff;
    }
    .serviceList {
      background: rgb(42 55 58 / 40%);
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      border-radius: 8px;
      width: 346px;
      padding: 16px;
      position: absolute;
      bottom: 70px;
      left: 44px;
      display: none;

      a {
        display: block;
        color: #fff;
        margin-bottom: 16px;
        background-image: url(https://api.ovn.ro/uploads/arrow_right_70bb05e60d.svg);
        background-repeat: no-repeat;
        background-position: right center;
        display: flex;
        align-items: center;

        :last-child {
          margin-bottom: 0;
        }

        img {
          width: 36px;
          height: 28px;
          border-radius: 4px;
          margin-right: 8px;
        }
      }
    }
  }
`;
