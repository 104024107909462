import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { PageWrap } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";
import Services from "../sections/Services";
import TwoSide from "../sections/TwoSide";

import Image1 from "../images/demo/1.svg";

const ServicesData = [
  {
    title: "Competitii Sportive",
    description:
      "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
    image: "live1",
  },
  {
    title: "Podcast si Emisiuni",
    description:
      "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
    image: "live2",
  },
  {
    title: "Evenimente Culturale",
    description:
      "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
    image: "live3",
  },
  {
    title: "Evenimente Corporate",
    description:
      "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
    image: "live4",
  },
  {
    title: "Congrese si Conferinte",
    description:
      "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
    image: "live2",
  },
  // {
  //   title: "Video Live 360",
  //   description:
  //     "Găzduiește evenimentul virtual pe scena noastră virtuală, complet personalizabilă, cu PUBLIC LIVE Interactiv",
  //   image: "image",
  // },
];

class LiveStreamingPage extends Component {
  state = {};
  render() {
    return (
      <>
        <PageWrap className="static">
          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img>
          <Container>
            <Hero
              light="1"
              title="Servicii complete de transmisiuni <span>LIVE</span>!"
              subtitle="Ne ocupăm de fiecare detaliu tehnic al evenimentului tău"
            ></Hero>
          </Container>
        </PageWrap>
        <Services data={ServicesData}></Services>
        <TwoSide
          section="Live Streaming"
          title="Competitii sportive"
          description="Solutie profesionala de streaming video  ce presupune transmisia evenimentelor din orice locatie, pe orice dispozitiv si pe orice platforma existenta."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
        <TwoSide
          reverse
          section="Live Streaming"
          title="Podcast si Emisiuni"
          description="Solutie profesionala de streaming video  ce presupune transmisia evenimentelor din orice locatie, pe orice dispozitiv si pe orice platforma existenta."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
        <TwoSide
          section="Live Streaming"
          title="Evenimente Culturale"
          description="Solutie profesionala de streaming video  ce presupune transmisia evenimentelor din orice locatie, pe orice dispozitiv si pe orice platforma existenta."
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        ></TwoSide>
      </>
    );
  }
}

export default LiveStreamingPage;
