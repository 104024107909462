import React, { Component } from "react";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import Icon from "@material-ui/core/Icon";

import { Button } from "../styles/GlobalStyles";

const styles = {
  contactForm: {
    // marginTop: 50,
    width: "100%",
  },
};

export class ContactForm extends Component {
  state = {
    nume: "",
    prenume: "",
    email: "",
    message: "",
    phone: "",
    industry: "",
    products: "",
    submitted: false,
    errors: false,
  };

  // Handle submit button
  handleSubmit = async (event) => {
    event.preventDefault();
    const { nume, prenume, email, phone, industry, products, message } =
      this.state;

    const fields = {
      nume,
      prenume,
      email,
      telefon: phone,
      industrie: industry,
      produse: products,
      descriere: message,
    };

    axios({
      method: "post",
      url: "https://api.ovn.ro/api/contacts",
      data: fields,
    })
      .then(({ data }) => {
        // Reset from after submiting
        document.getElementById("contact-form").reset();
        this.setState({
          nume: "",
          prenume: "",
          email: "",
          message: "",
          phone: "",
          industry: "",
          products: "",
        });

        this.setState({ submitted: true });
      })
      .catch((error) => {
        //handle error
        console.log("postContact", error);
      });

    // Reset from after submiting
    // document.getElementById("contact-form").reset();
    // this.setState({
    //   nume: "",
    //   prenume:"",
    //   email: "",
    //   message: "",
    //   phone: "",
    //   industry: "",
    //   products: "",
    // });

    // this.setState({ submitted: true });
  };

  // Handle fields change
  handleChange = (input) => (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  // Handle select change
  handleSelect = (event, item) => {
    this.setState({ [item]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { userName, email, message, phone, industry, products } = this.state;
    return (
      <div className={classes.container}>
        {this.state.submitted && (
          <div
            style={{
              minHeight: 600,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <Icon style={{ fontSize: "10rem", color: "#0786a3" }}>
              task_alt
            </Icon>
            <h4 style={{}}>
              Multumim!
              <br />
              Mesajul dvs. a fost trimis cu succces.
            </h4>
            <Button
              type="reset"
              onClick={() => this.setState({ submitted: false })}
            >
              RESET
            </Button>
          </div>
        )}
        {!this.state.submitted && (
          <Paper elevation={3} style={{ minHeight: 600, paddingTop: 20 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              component={Box}
              p={4}
              // sm={{ p: 2 }}
            >
              <Typography
                variant="h4"
                align="center"
                component="h4"
                gutterBottom
              >
                {"Contact Form".toUpperCase()}
              </Typography>
              <form
                id="contact-form"
                className={classes.contactForm}
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="nume"
                      label="Nume"
                      name="nume"
                      className={classes.inputField}
                      onChange={this.handleChange("nume")}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="prenume"
                      label="Prenume"
                      name="prenume"
                      className={classes.inputField}
                      onChange={this.handleChange("prenume")}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="email"
                      label="Email"
                      name="email"
                      className={classes.inputField}
                      onChange={this.handleChange("email")}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="phone"
                      label="Telefon"
                      name="phone"
                      className={classes.inputField}
                      onChange={this.handleChange("phone")}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Industrie
                      </InputLabel>
                      <Select
                        id="industry"
                        value={this.state.industry}
                        onChange={(e) => this.handleSelect(e, "industry")}
                      >
                        <MenuItem value="Divertisment">Divertisment</MenuItem>
                        <MenuItem value="Sport">Sport</MenuItem>
                        <MenuItem value="Educatie">Educatie</MenuItem>
                        <MenuItem value="Corporate">Corporate</MenuItem>
                        <MenuItem value="Arta & Muzica">Arta & Muzica</MenuItem>
                        <MenuItem value="Medical">Medical</MenuItem>
                        <MenuItem value="Alta">Alta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Produse si servicii
                      </InputLabel>
                      <Select
                        id="products"
                        value={this.state.products}
                        onChange={(e) => this.handleSelect(e, "products")}
                      >
                        <MenuItem value="Live Streaming">
                          Live Streaming
                        </MenuItem>
                        <MenuItem value="Studio Space">Studio Space</MenuItem>
                        <MenuItem value="Remote">Remote</MenuItem>
                        <MenuItem value="Video Development">
                          Video Development
                        </MenuItem>
                        <MenuItem value="Alta">Alta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    variant="filled"
                    required
                    id="message"
                    label="Descriere proiect"
                    name="message"
                    placeholder="Specificati data, locul si alte detalii relevante pentru proiectul dvs."
                    className={classes.inputField}
                    onChange={this.handleChange("message")}
                    margin="normal"
                    multiline
                    minRows="7"
                    maxRows="10"
                  />
                </Grid>
                <p style={{ fontSize: "0.8rem", margin: 0 }}>
                  Prin trimiterea acestui formular, confirm că am citit și sunt
                  de acord cu Politica de Confidențialitate si GDPR
                </p>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  {/* <Grid item>
                  <Button
                    className={classes.formButton}
                    type="reset"
                    variant="contained"
                    color="default"
                    // disabled={submitting || pristine}
                  >
                    RESET
                  </Button>
                </Grid> */}
                  <Grid item>
                    <Button
                      className={classes.formButton}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Trimite formular
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        )}
      </div>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
