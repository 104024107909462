import styled from "styled-components";

export const ServiceWrap = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 12px;
  margin-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.md}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.md}) {
    &.items3 {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }

    &.full {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 12px;
      padding-bottom: 12px;

      &:nth-child(even) {
        .serviceListItem {
          flex-direction: row-reverse;
        }
      }

      &:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      }

      .serviceListItem {
        flex-direction: row;
        padding: 36px 24px;
        justify-content: space-around;
        background: none;

        .image {
          flex: 0 0 550px;
          height: 350px;
          border-radius: 12px;
        }

        .text {
          width: 545px;
          padding: 16px 0 54px;
          h4 {
            font-size: 4rem;
            line-height: 1;
            margin: 1rem 0;
          }

          .description {
            max-height: none;
            p {
              max-width: 430px;
              font-size: 20px;
              line-height: 1.2;
            }
          }

          .link {
            display: block;
            position: relative;
            bottom: unset;
            left: unset;
            right: unset;

            a {
              background: #0787a4;
              border-radius: 5px;
              display: inline-block;
              font-size: 18px;
              font-weight: 700;
              letter-spacing: 0;
              line-height: 1;

              padding: 22px 43px;
            }
          }
        }
      }
    }
  }
  /* @media (max-width: ${({ theme }) => theme.md}) {
    flex: 0 0 100%;
    max-width: 100%;
  } */

  .serviceListItem {
    background: #0787a4;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
    position: relative;
    /* min-height: 200px; */

    .image {
      height: 150px;
      background-size: cover;
      background-position: top center;
      position: relative;
      text-align: center;
      flex: 0 0 220px;
    }

    .text {
      padding: 16px 24px 54px;
      height: 100%;

      h4 {
        color: #fff;
        margin: 0;
        font-size: 1.8rem;
        line-height: 1.2;
      }
      .description {
        max-height: 38px;
        overflow: hidden;
        p {
          color: #fff;
          opacity: 0.8;
          margin: 0 0 8px;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .link {
        position: absolute;
        bottom: 20px;
        left: 24px;
        right: 24px;
        justify-content: flex-end;
        margin-top: 16px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        display: flex;
        opacity: 0.7;
        a {
          color: #fff;
        }
      }
    }
  }
`;
