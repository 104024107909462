import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import FAQ from "../sections/faq";
import Next from "../sections/Next";
import ShowCase from "../sections/Showcase";
import TopService from "../sections/TopService";
import ExtraServices from "../sections/ExtraServices";
import usePageTracking from "../hooks/usePageTracking";

export default function ServicePage() {
  let { service } = useParams();
  const [title, setTitle] = useState("");

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/services?filters[slug][$eq]=${service}&populate=*`
  );

  usePageTracking();
  useEffect(() => {
    document.title = `${title} | Live Streaming | OVN`;
  }, [title]);
  if (loading) return null;
  if (error) return <p> Error :( </p>;
  data = data[0];
  if (data.title != title) setTitle(data.title);

  return (
    <>
      <TopService
        light="1"
        {...data}
        // type="Sport"
        // title={data.title}
        // description={data.description}
        // picture="https://jwplayer.com/wp-content/uploads/2022/12/86ee788b-642b-4b0a-bb58-524f8bb0f9a2_ott-sports-min.webp"
        //   link_title="Hello"
      ></TopService>
       <ExtraServices></ExtraServices>
      <ShowCase
        title="Producții anterioare pentru inspirație"
        category={data.categories[0].id}
      ></ShowCase>
      <Next backgroundColor="#0786a3"></Next>
      <FAQ />
    </>
  );
}
