import React from "react";
import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";

import Container from "@material-ui/core/Container";
import { ButtonBase } from "@material-ui/core";

import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ExtraServices } from "./ExtraServices.styled";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useFetch from "../hooks/useFetch";

import icon from "../images/icons/icon-additional-videos.svg";
import SvgExtraIcon from "../components/SvgExtraIcon";

let extraStatic = [
  {
    id: 9,
    description:
      "Amplificați experiența de transmisie live cu o cameră video adițională pentru capturarea unor unghiuri și perspective diverse.",
    icon: "camera",
    price: "150",
    active: true,
    locale: "ro",
    people: 1,
    title: "Echipament Suplimentar",
    slug: "echipament-suplimentar",
  },
  {
    id: 7,
    description:
      " Furnizăm ecrane LED de înaltă calitate, montate pe stativ, pentru a asigura vizualizarea optimă a conținutului video pentru public.",
    icon: null,
    price: "50",
    active: true,
    locale: "ro",
    people: 0,
    title: "Ecran Led",
    slug: "ecran-led",
  },
  {
    id: 3,
    description:
      "Primiți perspective aeriene spectaculoase ale evenimentului dvs. cu ajutorul dronelor noastre profesioniste de filmare.",
    icon: null,
    price: "200",
    active: true,
    locale: "ro",
    people: 1,
    title: "Filmare Dronă",
    slug: "filmare-drona",
  },
  {
    id: 2,
    description:
      "Adăugați o atmosferă captivantă evenimentului cu iluminare profesională oferită de kitul nostru specializat.",
    icon: null,
    price: "100",
    active: true,
    locale: "ro",
    people: 0,
    title: "Kit Lumini",
    slug: "kit-lumini",
  },
  {
    id: 6,
    description:
      "Asigurăm vizualizarea clară a prezentatorilor sau artiștilor pe scenă cu monitoarele noastre de scenă profesionale.",
    icon: null,
    price: "200",
    active: true,
    locale: "ro",
    people: 0,
    title: "Monitor Scenă",
    slug: "monitor-scena",
  },
  {
    id: 11,
    description:
      "Adăugați o notă de profesionalism și angajament cu un prezentator experimentat care să conducă și să animeze evenimentul într-un mod captivant și informativ.",
    icon: null,
    price: null,
    active: null,
    locale: "ro",
    people: null,
    title: "Prezentator / Moderator",
    slug: "prezentator-moderator",
  },
  {
    id: 4,
    description:
      "Adăugați un element cinematic la transmisiunea live cu ajutorul sistemelor noastre de CableCam  pentru cadre din mișcare captivante.",
    icon: null,
    price: "500",
    active: true,
    locale: "ro",
    people: 1,
    title: "Spider Cam",
    slug: "spidercam-cablecam",
  },
  {
    id: 8,
    description:
      "Beneficiați de captură suplimentară de fotografii și videoclipuri de înaltă calitate cu ajutorul sistemului nostru Spider Foto/Video.",
    icon: null,
    price: "200",
    active: true,
    locale: "ro",
    people: 0,
    title: "Spider Foto/Video",
    slug: "spider-foto-video",
  },
  {
    id: 12,
    description:
      "Asigurați accesibilitatea pentru publicul divers prin adăugarea de subtitrări pentru limbajul semnelor sau traduceri pentru a facilita înțelegerea conținutului.",
    icon: "icon_subtitrare",
    price: "100",
    active: true,
    locale: "ro",
    people: null,
    title: "Subtitrare",
    slug: "subtitrare",
  },
  {
    id: 1,
    description:
      "Beneficiați de un expert în sunet pentru a asigura calitatea audio impecabilă a transmisiunii live a evenimentului dvs.",
    icon: null,
    price: "150",
    active: true,
    locale: "ro",
    people: 1,
    title: "Tehnician Sunet",
    slug: "tehnician-sunet",
  },
  {
    id: 5,
    description:
      "Facem evenimentul accesibil pentru o audiență globală oferind servicii de traducere în timp real a discursurilor sau prezentărilor.",
    icon: null,
    price: "100",
    active: true,
    locale: "ro",
    people: 1,
    title: "Traducere",
    slug: "traducere",
  },
  {
    id: 10,
    description:
      "Furnizăm un proiector video de înaltă calitate pentru a oferi o modalitate eficientă de afișare a conținutului video live pe ecrane mari sau pe perete,",
    icon: null,
    price: "100",
    active: true,
    locale: "ro",
    people: 0,
    title: "Video Proiector",
    slug: "video-proiector",
  },
];

export default function Showcase({ title }) {
  const sliderRef = useRef(null);
  const [active, setActive] = useState(0);
  const [total, setTotal] = useState(0);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/extras?sort=title`
  );

  // useEffect(() => {
  //   console.log("slide-uri totale:", sliderRef.current.props.children[1]);
  //   setTotal(sliderRef.current.props.children[1].length);
  // }, []);

  const beforeChange = (prev, next) => {
    setActive(next);
  };

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  if (data)
    return (
      <ExtraServices>
        <Container maxWidth="xl" className="showcaseNav">
          <div className="title">
            <h2>Opțiuni suplimentare</h2>
            <p>Elemente suplimentare ce le puteți include în producția dvs.</p>
          </div>
          {/* <span>{`theme.breakpoints.up('sm') matches: ${isSmall}`}</span>
        total: {total} si Active Slide: {active} */}
          {!isSmall && (
            <div className="extraNav">
              <ButtonBase
                className={active === 0 ? " buttons disabled" : "buttons"}
                onClick={() => sliderRef.current.slickPrev()}
              >
                <ArrowBackIosRoundedIcon />
              </ButtonBase>
              <ButtonBase
                className={
                  active === total - 1 ? " buttons disabled" : "buttons"
                }
                onClick={() => sliderRef.current.slickNext()}
              >
                <ArrowForwardIosRoundedIcon />
              </ButtonBase>
            </div>
          )}
        </Container>

        <div className="videoSliderWrap">
          <Slider
            dots
            dotsClass="slick-dots line-indicator"
            ref={sliderRef}
            slidesToShow={4}
            slidesToScroll={4}
            variableWidth={false}
            infinite={false}
            loop={false}
            arrows={false}
            beforeChange={beforeChange}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false,
                  variableWidth: true,
                },
              },
            ]}
            customPaging={(i) => (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "-10px",
                  opacity: 0,
                }}
              >
                {i}
              </div>
            )}
          >
            {data.map((item, index) => (
              <div key={index} className="extra">
                <div className="extraTitle">
                  {/* <img src={icon} /> */}
                  <SvgExtraIcon name={item.icon} />
                  {item.title}
                </div>
                {item.description}
              </div>
            ))}
            {/* <div className="viewAll">Vezi tot</div> */}
          </Slider>
        </div>
      </ExtraServices>
    );
}
