import React, { Component } from "react";
import { ServiceSection } from "./Services.styled";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import PictureCard from "../components/cards/PictureCard";
import { StyledContainer } from "../styles/GlobalStyles";
import BaseCard from "../components/cards/BaseCard";

export default function Services(props) {
  console.log("props data", props);
  return (
    <ServiceSection>
      <StyledContainer maxWidth="xl" className="serviceContainer">
        <h2>Descopera oferta noastra de servicii</h2>
        <div className="promoWrap">
          {props.data.map((item, index) => (
            <div className="secondPromo" key={index}>
              {/* <PictureCard data={item}></PictureCard> */}

              <BaseCard
                icon="https://jwplayer.com/wp-content/uploads/2022/12/icon-stream.svg"
                type="Video Platform"
                title={item.title}
                text="Easily scale your video strategy with a centralized, all-in-one
          platform"
                link_url="#"
                link_title="Learn more"
              ></BaseCard>
            </div>
          ))}
        </div>
      </StyledContainer>
    </ServiceSection>
  );
}
