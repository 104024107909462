import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const BlogSection = styled(Section)`
  margin: 1rem 0;
  padding: 1rem 0;
  min-height: 400px;
  h2 {
    font-size: 35px;
    margin: 0;
  }

  .blogWrap {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -12px;
  }

  button {
    display: block;
    margin: 0 auto;
    min-width: 250px;
  }
`;

export const BlogDetailsTitle = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.md}) {
    margin-top: 40px;
  }

  span {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 1.4;
    display: block;
    margin-bottom: 10px;
    color: #fff;
    opacity: 0.7;
  }
  h1 {
    font-size: 3rem;
    color: #fff;
    font-weight: 700;
    max-width: 90%;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.md}) {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.2;
      padding: 1rem;
    }
  }
  p {
    font-size: 1rem;
    @media (max-width: ${({ theme }) => theme.md}) {
      margin: 0;
    }
    span {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      background: #ededed;
      border-radius: 8px;
      padding: 8px 16px;
      white-space: nowrap;
      margin-right: 12px;
      cursor: pointer;
      color: #545454;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 8px;
        line-height: 12px;
        padding: 4px 8px;
      }
    }
  }

  .image {
    margin-top: 90px;
    margin-bottom: -200px;
    background: #0787a4;
    box-shadow: 0 2px 48px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    min-height: 400px;
    padding: 3rem;
  }
`;

export const BlogDetailsContent = styled(Section)`
  margin-top: -200px;
  margin-bottom: 100px;
  position: relative;
  overflow: visible;

  .blogMeta {
    display: flex;
    margin: 0.5rem 0;
    justify-content: flex-end;
    font-size: 1.5rem;
    color: #fff;
    padding: 0 20px;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 1rem;
    }

    span {
      margin-left: 20px;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      svg {
        margin: 0 4px;
      }
    }
  }

  .blogContainer {
    background: #fff;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    font-size: 18px;
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 2rem 1rem;
      font-size: 14px;
    }

    .blogTable {
      margin-right: 124px;
      flex: 0 0 256px;

      position: sticky;
      position: -webkit-sticky; //For Safari
      top: 170px; /* How far down the page you want your ToC to live */

      /* Give table of contents a scrollbar */
      max-height: calc(70vh - 10px);
      overflow: auto;

      @media (max-width: ${({ theme }) => theme.md}) {
        display: none;
      }

      h4 {
        margin-bottom: 12px;
        text-transform: uppercase;
        text-transform: uppercase;
        font-size: 16px;
        opacity: 0.5;
      }

      ol {
        margin: 0;
        padding: 0;
        /* padding-left: 20px; */

        li {
          margin-bottom: 28px;
          /* list-style: decimal; */
          list-style: none;
          color: #999;
          &.active a {
            color: #202020;
            font-weight: 700;
          }
        }
        a {
          color: #999;

          :hover {
            color: #202020;
          }
        }
      }
    }

    .blogContent {
      h2 {
        margin: 0;
        margin-bottom: 20px;
        scroll-margin-top: 130px;
        margin-top: 4rem;
        @media (max-width: ${({ theme }) => theme.md}) {
          margin: 0;
          margin-top: 2rem;
          font-size: 1.5rem;
          line-height: 1.4;
        }
      }

      h4 {
        @media (max-width: ${({ theme }) => theme.md}) {
          margin-top: 2rem;
          font-size: 1.2rem;
        }
      }

      ol,
      ul {
        background: #f1f1f1;
        padding: 1rem 3rem;
        border-radius: 8px;
        li {
          list-style: auto;
          color: #6b7c93;
          /* font-size: 22px; */
          line-height: 1.6;
          margin-bottom: 0.5rem;
        }
      }

      ul {
        li {
          list-style: disc;
        }
      }

      pre {
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 20px;
        /* word-break: break-all; */
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        font-family: monospace, serif;
        /* font-size: 0.5em; */
        white-space: pre-wrap;
      }

      #introducere {
        margin-top: -70px;
        visibility: hidden;
      }
      figure {
        margin: 0;
        padding: 0.5rem;
        background: #e8e8e8;
        border-radius: 16px;
        overflow: hidden;
        margin: 1rem 0;

        &.media {
          padding-bottom: 55.25%;
          position: relative;
          display: block;
          width: 100%;
          overflow: hidden;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        img {
          width: 100%;
          overflow: hidden;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        figcaption {
          text-align: center;
          color: #999;
          font-size: 1rem;
        }
      }
      p {
        font-size: 22px;
        line-height: 36px;
        margin: 0;
        margin-bottom: 48px;
        margin: 20px 0;
        scroll-margin-top: 160px;
        /* color: #000; */
        @media (max-width: ${({ theme }) => theme.md}) {
          line-height: 32px;
          font-size: 20px;
          margin-bottom: 48px;
        }

        img {
          width: 100%;
          padding: 0.5rem;
          background: #e8e8e8;
          border-radius: 16px;
          overflow: hidden;
          margin: 1rem 0;
        }
      }
    }
  }
`;
