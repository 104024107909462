import React from "react";
import { FullPromoSection } from "./FullPromo.styled";
import { StyledContainer } from "../styles/GlobalStyles";

import { Link } from "react-router-dom";
import Carousel from "../components/Carousel";

export default function FullPromo(props) {
  const { subtitle, title, description, link_text, link_url, image } = props;

  return (
    <FullPromoSection {...props}>
      <StyledContainer maxWidth="xl">
        <div className="wrapper">
          <div className="context">
            <h2>{subtitle}</h2>
            <h3>{title}</h3>
            <p>{description}</p>
            <Link to={link_url} className="btn transparent-btn">
              {link_text}
            </Link>
          </div>

          <div className="heroWrap">
            {/* <div
              className="image"
              style={{ backgroundImage: `url(${image})` }}
            ></div> */}
            <Carousel slides={[...image]} options={{ loop: true }} />
          </div>
        </div>
      </StyledContainer>
    </FullPromoSection>
  );
}
