import React from "react";

import { CariereWrap } from "./CariereCard.styled";

import IconPin from "../../images/svg/icon-pin.svg";
import Diversity from "../../images/svg/icon-diversity.svg";
import { Link } from "react-router-dom";

export default function CariereCard({ job }) {
  if (!job.static) {
    job.intro = job.intro.replace(/<[^>]+>/g, "").slice(0, 250) + " ...";
  }
  return (
    <CariereWrap>
      {!job.icon && <Link to={`/cariere/${job.slug}`} className="link"></Link>}
      {job.icon && <img src={Diversity} alt="" />}
      <h3> {job.title}</h3>
      {!job.static && (
        <span>
          <img src={IconPin} />
          {job.position_location} - {job.position_type}
        </span>
      )}
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: job.intro,
        }}
      ></div>

      {!job.icon && (
        <div className="actions">
          <Link to={`/cariere/${job.slug}/apply`} className="action apply">
            Aplica acum
          </Link>
          {!job.static && (
            <Link to={`/cariere/${job.slug}`} className="action more">
              Vezi detalii
            </Link>
          )}
        </div>
      )}
    </CariereWrap>
  );
}
