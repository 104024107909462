import React, { useCallback } from "react";

import styled from "styled-components";

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

export default function Carousel(props) {
  const { slides, options } = props;
  const [emblaRef] = useEmblaCarousel(options, [Autoplay()]);

  return (
    <Slider className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((slide, index) => (
            <div className="embla__slide" key={index}>
              {/* <div className="embla__slide__number">
                <span>{index + 1}</span>
              </div> */}
              <img
                className="embla__slide__img"
                src={slide}
                alt="Studio Virtual"
              />
            </div>
          ))}
        </div>
      </div>
    </Slider>
  );
}

const Slider = styled.div`
  --slide-spacing: 0;
  --slide-size: 100%;
  --slide-height: 100%;
  padding: 0;
  height: 100%;

  .embla__viewport {
    overflow: hidden;
    height: 100%;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
    height: 100%;
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
    height: 100%;
  }
  .embla__slide__img {
    display: block;

    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .embla__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
