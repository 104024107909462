import React from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import { PageWrap, StyledContainer } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";
import Cariere from "../sections/Cariere";

import usePageTracking from "../hooks/usePageTracking";
import useFetch from "../hooks/useFetch";

import {
  CariereDetailsTitle,
  CariereDetailsContent,
  CariereApplyContent,
} from "../sections/Cariere.styled";
import DotBlack from "../images/svg/dot-black.svg";
import JobForm from "../components/JobForm";

export default function CarierePage() {
  usePageTracking();
  return (
    <>
      <PageWrap className="static">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img>
        <Hero
          light="1"
          title="Cariere"
          subtitle="Vino în echipa noastră să dezvoltăm împreună proiecte de succes!"
        ></Hero>
      </PageWrap>

      <Cariere title="Open Positions"></Cariere>
    </>
  );
}

export function CariereDetailsPage() {
  usePageTracking();
  let { id } = useParams();

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/careers/${id}`
  );
  if (loading) return null;
  if (error) return <Navigate to="/cariere" />;

  return (
    <>
      <PageWrap className="static">
        <CariereDetailsTitle>
          <span>Cariere</span>
          <h1>{data.title}</h1>
          <p>
            {data.position_type} <img src={DotBlack} /> {data.position_location}
          </p>
          <div dangerouslySetInnerHTML={{ __html: data.intro }}></div>
        </CariereDetailsTitle>
      </PageWrap>
      <CariereDetailsContent>
        <StyledContainer maxWidth="lg">
          <div dangerouslySetInnerHTML={{ __html: data.description }}></div>

          <Link to={`/cariere/${data.slug}/apply`} className="btn  applyNow">
            Aplica acum
          </Link>
        </StyledContainer>
      </CariereDetailsContent>

      <Cariere title="Vezi alte poziții disponibile" pageid={data.id}></Cariere>
    </>
  );
}

export function CariereApplyPage() {
  usePageTracking();

  let { id } = useParams();

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/careers/${id}`
  );
  if (loading) return null;
  if (error) return <Navigate to="/cariere" />;

  return (
    <>
      <PageWrap className="static">
        <CariereDetailsTitle>
          <span>Cariere</span>
          <h1>{data.title}</h1>
          <p style={{ marginBottom: "2rem" }}>
            {data.position_type} <img src={DotBlack} /> {data.position_location}
          </p>
          {/* <div>
            <p>
              Aplică pentru această poziție <br />
              completând formularul de mai jos:
            </p>
          </div> */}
          {/* <div dangerouslySetInnerHTML={{ __html: data.intro }}></div> */}
        </CariereDetailsTitle>
      </PageWrap>
      <CariereApplyContent>
        <StyledContainer maxWidth="lg">
          <JobForm details={data}></JobForm>
        </StyledContainer>
      </CariereApplyContent>
    </>
  );
}
