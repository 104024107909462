import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

import red_hatch from "../images/red_hatch.png";

export const FullPromoSection = styled(Section)`
  padding: 6rem 0;
  margin: 5rem 0;
  background: #0786a3;
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;

    .context {
      width: calc(60% - 3rem);

      h2 {
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-size: 24px;
        color: #272b38;
        margin: 0 0 1rem;
        line-height: 1.25;
      }

      h3 {
        color: #fff;
        margin-bottom: 2rem;
        font-size: 48px;

        font-weight: 700;
        line-height: 1.25;
      }

      p {
        font-size: 23px;
        margin: 0 0 1rem;
        font-weight: 400;
        line-height: 1.5;
        opacity: 0.7;
        color: #fff;
      }
      button {
        margin-top: 2rem;
      }

      @media (max-width: ${({ theme }) => theme.md}) {
        padding-top: 300px;
        width: 100%;
        text-align: center;

        h2 {
          font-size: 16px;
        }

        h3 {
          font-size: 24px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .heroWrap {
      position: absolute;
      top: 0;
      left: 60%;
      width: 40vw;
      height: 100%;
      background-image: url(${red_hatch});
      background-repeat: repeat-y;
      background-position: 0 0;

      :after {
        content: "";
        background-image: url(${red_hatch});
        background-repeat: repeat-y;
        background-position: 0 0;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 2rem;
      }

      @media (max-width: ${({ theme }) => theme.md}) {
        left: 0;
        height: 300px;
        width: 100%;
      }

      .image {
        background-size: cover;
        background-position: 50%;
        margin-left: 13px;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 13px);
        height: 100%;
        /* background-image: url(https://ns1.com/writable/images/cheerful-multiethnic-women-browsing-smartphone-4353619_2023-05-12-165624_oxjv.jpg); */
      }
    }
  }
`;
