import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const NextSection = styled(Section)`
  padding: 2rem 0;
  margin: 0 0 5rem 0;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .ready {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    h4 {
      color: #fff;
      font-size: 2em;
      margin-bottom: 0.5em;
    }

    button {
      max-width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
`;

export const Steps = styled(Section)`
  padding: 3rem 0;
  margin: 0;
  background-color: #272b38;
  text-align: center;
  margin-top: 4rem;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
    display: none;
  }

  ::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-top: 1.5rem;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #272b38;
    left: 50%; /*Add these*/
    margin-left: -40px;
  }
  h4 {
    color: #fff;
    font-size: 2em;
    margin-bottom: 2rem;
    opacity: 0.6;
  }

  .stepsWrap {
    display: flex;
    justify-content: space-around;
    align-items: start;

    .stepItem {
      max-width: 370px;
      /* margin: 0 3rem; */
      svg {
        width: 180px;
      }
      h5 {
        color: #fff;
        font-size: 1.5rem;
        margin: 0;
      }

      p {
        color: #fff;
        margin: 0;
      }
    }
  }
`;
