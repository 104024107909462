import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import {
  PageWrap,
  Title,
  SubTitle,
  StyledContainer,
} from "../styles/GlobalStyles";
import { ContactSection } from "../styles/contactStyles";
import Hero from "../sections/Hero";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

import MapOVN from "../components/Map";
import ContactForm from "../components/ContactForm";

import { Button } from "../styles/GlobalStyles";
import usePageTracking from "../hooks/usePageTracking";

function Contactpage() {
  usePageTracking();

  return (
    <>
      <PageWrap className="static">
        <StyledContainer maxWidth="xl">
          <Hero
            light="1"
            title="Video-ul tău are nevoie de inovație și creativitate?"
            subtitle="Te asteptăm să vizitezi studio-ul nostru sau să ne contactezi folosind informațiile de mai jos."
          ></Hero>
        </StyledContainer>
      </PageWrap>
      <ContactSection>
        <StyledContainer maxWidth="xl">
          <Grid container className="ContactFormWrap">
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              component={Box}
              p={1}
              style={{ alignSelf: "center" }}
            >
              <h1>Intră în contact cu un expert OVN</h1>
              <p>
                Accelerează dezvoltarea conținutului video cu produsele și
                serviciile puse la dispoziție de compania noastră.
              </p>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              component={Box}
              p={1}
              style={{ alignSelf: "center" }}
            >
              <ContactForm></ContactForm>
            </Grid>
          </Grid>
        </StyledContainer>
      </ContactSection>
      <MapOVN></MapOVN>
      <ContactSection>
        <StyledContainer maxWidth="xl">
          <Grid container>
            <Grid item md={5} xs={12} sm={12} component={Box} p={1}>
              <div className="contactName">Offices</div>

              <Grid container>
                <Grid item md={6} xs={12} sm={6} component={Box} p={1}>
                  <p>
                    <strong>București</strong>
                  </p>
                  <p className="small">
                    B-dul Iuliu Maniu, Nr. 7, Corp O,
                    <br />
                    Sect. 6, București, CP 061072, RO
                  </p>
                  <p className="small">
                    <strong>Tel:</strong> +4.0725.875478 <br />
                    <strong>Email:</strong> contact [at] ovn.ro <br />
                    <strong>Website:</strong> www.ovn.ro
                  </p>
                </Grid>
                <Grid item md={6} xs={12} sm={6} component={Box} p={1}>
                  <p>
                    <strong>Gherla, jud. Cluj</strong>
                  </p>
                  <p className="small">
                    Str. Cetății, Nr 33, Gherla <br />
                    Jud. Cluj, CP 405300
                  </p>
                  <p className="small">
                    <strong>Tel:</strong> +4.0725.875478 <br />
                    <strong>Email:</strong> contact [at] ovn.ro <br />
                    <strong>Website:</strong> www.ovn.ro
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} sm={6} component={Box} p={1}>
              <div className="contactName">Studio Space</div>
              <div className="contactDescription">
                <p>
                  <strong>
                    Adresa: <br />
                  </strong>
                  B-dul Iuliu Maniu, Nr. 7, Corp O, <br />
                  Sect. 6, Bucuresti, CP 061072, România
                  <br />
                  (in incinta APACA)
                </p>
                <div>
                  <p>
                    <strong>Navigatie</strong>
                  </p>
                  <a
                    href="https://ul.waze.com/ul?preview_venue_id=17105341.170856800.11998710&navigate=yes"
                    target="_blank"
                  >
                    <Icon>drive_eta</Icon>
                    Waze
                  </a>{" "}
                  <a
                    href="https://goo.gl/maps/Mwfj6k6HuMFjPj637"
                    target="_blank"
                  >
                    <Icon>drive_eta</Icon>
                    Google Maps
                  </a>
                </div>
                <div>
                  <p>
                    <strong>Transport public:</strong>
                  </p>

                  <p className="withIcon">
                    <Icon>directions_bus</Icon> Autobuz: 105 ,336 ,610 (Statia
                    Bd. Iuliu Maniu)
                  </p>
                  <br />
                  <p className="withIcon">
                    <Icon>directions_subway</Icon>Metrou: M3 (Statia
                    Politehnica)
                  </p>
                  <br />
                  <p className="withIcon">
                    <Icon>tram</Icon>Tramvai: 10 / 1 (Statia Bd. Iuliu Maniu)
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} component={Box} p={1}>
              <div className="contactName">Info Companie</div>
              <div className="contactDescription">
                <p>
                  <b>S.C. Online Video Network SRL</b>
                </p>
                <p>
                  Str. Cetatii, nr. 33, Gherla, <br />
                  jud. Cluj, CP 405300, Romania
                </p>
                <p>
                  Reg. com.: J12/1860/2013 <br />
                  CIF: RO31758529
                </p>
                <p>
                  RO38INGB0000999903885950
                  <br />
                  ING Bank
                  <br />
                  <br />
                  RO56TREZ2185069XXX003142
                  <br />
                  Trezoreria Gherla
                  <br />
                </p>

                <p>
                  <strong>Websites:</strong> <br />
                  ovn.ro | onlinevideonetwork.com
                </p>
              </div>
            </Grid>
          </Grid>
        </StyledContainer>
      </ContactSection>
    </>
  );
}

export default Contactpage;
