export const theme = {
  primaryDark: "#0786a3",
  primaryLight: "#35c2d8",
  primaryHover: "#343078",
  sm: "600px",
  md: "992px",
  lg: "1280px",
  xl: "1600px",

  overrides: {
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
      },
    },
  },
};
