import React from "react";
import { VideoSection } from "./VideoHero.styled";
import Container from "@material-ui/core/Container";

function VideoHero(props) {
  return (
    <VideoSection {...props}>
      <Container maxWidth="xl">
        <div className="videoWrapper">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/8k9BrhDUmew"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Container>
    </VideoSection>
  );
}

export default VideoHero;
