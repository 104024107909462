import React, { Component } from "react";

import * as Yup from "yup";
import { FormikProvider } from "formik";
import Grid from "@material-ui/core/Grid";
import InputField from "./InputField";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";

const ProductionDetails = ({ formik }) => {
  return (
    <FormikProvider value={formik}>
      <Grid container spacing={4} className="formBox">
        <Grid item xs={12} sm={4}>
          <Grid item xs={12} sm={12}>
            <InputField
              name="event_type"
              label="Tip Eveniment"
              select
              basicoptions={["Interior", "Exterior", "Interior + Exterior"]}
              helperText="Tip Eveniment"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              name="event_production"
              label="Tip Productie"
              select
              basicoptions={[
                "Mica - 1 camera video",
                "Medie - 3 camere video",
                "Mare - 4+ camere video",
                "Nu stiu",
              ]}
              helperText="Pentru a acoperi mai bine evenimentul dumneavostra putem folosi una sau mai multe camere."
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              name="event_internet"
              label="Internet in locatia evenimentului?"
              select
              basicoptions={["Da", "Nu", "Nu stiu"]}
              helperText="Putem folosi conexiunea la  internet din locatia evenimentului sau vom furniza noi o solutie de conectare?"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid item xs={12} sm={12}>
            <InputField
              name="event_audio"
              label="Cine va asigura sonorizarea?"
              select
              basicoptions={[
                "Sonorizarea exista in locatie",
                "OVN va asigura sonorizarea",
                "Nu stiu",
              ]}
              helperText="Unele locatii, de exemplu hotelurile, pun la dispozitia clientilor infrastructura audio necesara gratuit."
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField
              name="event_graphics"
              label="Elemente Grafice?"
              select
              basicoptions={["Da", "Nu", "Nu stiu"]}
              helperText="Putem sigla evenimentului dvs., afisarea numelui speakerilor si alte elemente grafice pentru a crea o productie mai bogata vizual."
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              name="event_visitors"
              label="Numarul Vizitatorilor"
              select
              basicoptions={[
                "~500 vizitatori",
                " ~1000 vizitatori",
                "~2000 vizitatori",
                "~5000 vizitatori",
                "Nu stiu",
              ]}
              helperText="Cati vizitatori estimati ca vor urmari evenimentul dumneavoastra? Aveti in vedere nr. total, insumand toate zilele evenimentului."
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid item xs={12} sm={12}>
            <InputField
              name="event_summary"
              label="Rezumat video al evenimentului"
              select
              basicoptions={[
                "Da - Rezumat din productia video",
                " Da - Rezumat din productia video + cadre filmate separat",
                "Nu",
                "Nu stiu",
              ]}
              helperText="Putem crea un rezumat, cu momentele cheie ale evenimentului, pe care sa-l puteti prezenta clientilor sau publica pe site-ul dvs.."
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              name="event_segments"
              label="Clipuri video pentru fiecare speaker?"
              select
              basicoptions={["Da", "Nu", "Nu stiu"]}
              helperText="Dupa terminarea evenimentul va putem pune la dispozitie clipuri video separat, cu fiecare invitat al evenimentului."
            />
          </Grid>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

ProductionDetails.label = "Production Details";
ProductionDetails.icon = <LiveHelpIcon />;
ProductionDetails.initialValues = {
  event_type: "",
  event_production: "",
  event_internet: "",
  event_audio: "",
  event_graphics: "",
  event_visitors: "",
  event_summary: "",
  event_segments: "",
};
ProductionDetails.validationSchema = Yup.object().shape({});

export default ProductionDetails;
