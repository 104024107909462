import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledContainer, Section } from "../../styles/GlobalStyles";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

import Button from "@material-ui/core/Button";

import SwipeableViews from "react-swipeable-views";

import SelectProduct from "./Step_SelectProduct";
import ProjectInfo from "./Step_ProjectInfo";
import ContactDetails from "./Step_ContactDetails";
import ProductionDetails from "./Step_ProductionDetails";
import Icon from "@material-ui/core/Icon";

import axios from "axios";
import { useFormik } from "formik";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },

  active: {
    "& $line": {
      //   backgroundImage:
      //     "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      background: "#0786a3",
    },
  },
  completed: {
    "& $line": {
      //   backgroundImage:
      //     "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      background: "#0786a3",
    },
  },

  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ffffff",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#0786a3",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    background: "#de3a31",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "#ffffff",
  },
  completed: {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    background: "#0786a3",
    color: "#ffffff",
  },
});

function ColorlibStepIcon(props) {
  //   console.log(props);
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  //   const icons = {
  //     1: <SubscriptionsIcon />,
  //     2: <SettingsIcon />,
  //     3: <LiveHelpIcon />,
  //     4: <AssignmentIndIcon />,
  //   };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* {icons[String(props.icon)]} */}
      {props.icon}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },

    "& .MuiStepLabel-label ": {
      color: "#ffffff",
      fontSize: "1.2rem",
      opacity: 0.8,
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(section) {
  if (section === "Live Streaming") {
    return [SelectProduct, ProjectInfo, ProductionDetails, ContactDetails];
  } else {
    return [SelectProduct, ProjectInfo, ContactDetails];
  }
}

export default function Steps() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  //********************************************************************************************************************************* */
  //*  F O R M I K
  //********************************************************************************************************************************* */

  const formikSelectProduct = useFormik({
    initialValues: SelectProduct.initialValues,
    validationSchema: SelectProduct.validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const formikProjectInfo = useFormik({
    initialValues: ProjectInfo.initialValues,
    validationSchema: ProjectInfo.validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const formikProductionDetails = useFormik({
    initialValues: ProductionDetails.initialValues,
    validationSchema: ProductionDetails.validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const formikContactDetails = useFormik({
    initialValues: ContactDetails.initialValues,
    validationSchema: ContactDetails.validationSchema,
    onSubmit: (values) => {
      handleFormSubmit();
    },
  });

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const section = formikSelectProduct.getFieldMeta("event_section").value;
  //   console.log("section", section);
  const steps = getSteps(section);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    formikSelectProduct.resetForm();
    formikProjectInfo.resetForm();
    formikProductionDetails.resetForm();
    setActiveStep(0);
  };

  const handleFormSubmit = () => {
    formikContactDetails.setSubmitting(true);
    var values = {
      ...formikSelectProduct.values,
      ...formikProjectInfo.values,
      ...formikProductionDetails.values,
      ...formikContactDetails.values,
    };
    console.log("handleFormSubmit ", values);
    let data = {
      cotatie: true,
      nume: values.contact_name,
      prenume: values.contact_firstname,
      telefon: values.contact_phone,
      email: values.contact_email,
      descriere: JSON.stringify(values, null, 2),
    };

    axios({
      method: "post",
      url: "https://api.ovn.ro/api/contacts",
      data: data,
    })
      .then(({ data }) => {
        // Reset from after submiting
        // resetForm();
        formikContactDetails.setSubmitting(false);
        handleNext();
        //
      })
      .catch((error) => {
        //handle error
        formikContactDetails.setSubmitting(false);
        console.log("postCotatie", error);
      });
    // alert(JSON.stringify(data, null, 2));
  };

  const handleSubmit = () => {
    switch (activeStep) {
      case 0:
        formikSelectProduct.handleSubmit();
        break;
      case 1:
        formikProjectInfo.handleSubmit();
        break;
      case 2:
        if (section === "Live Streaming") {
          formikProductionDetails.handleSubmit();
        } else {
          formikContactDetails.handleSubmit();
        }

        break;
      case 3:
        formikContactDetails.handleSubmit();
        break;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SelectProduct formik={formikSelectProduct} key={step} />;
      case 1:
        return <ProjectInfo formik={formikProjectInfo} key={step} />;
      case 2:
        if (section === "Live Streaming") {
          return (
            <ProductionDetails formik={formikProductionDetails} key={step} />
          );
        } else {
          return <ContactDetails formik={formikContactDetails} key={step} />;
        }

      case 3:
        return <ContactDetails formik={formikContactDetails} key={step} />;
      default:
        return "Unknown step";
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              icon={steps[index].icon}
            >
              {steps[index].label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="formBg">
        <StyledContainer maxWidth="xl">
          {activeStep === steps.length ? (
            <div
              style={{
                minHeight: 450,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <Icon style={{ fontSize: "10rem", color: "#ffffff" }}>
                task_alt
              </Icon>
              <h4 style={{ color: "#ffffff" }}>
                Multumim!
                <br />
                Cotatia dvs. a fost trimisa cu succces.
                <br />
                Un email de confirmare a fost trimis catre adresa de email.
              </h4>
              <Button className="btn red" onClick={handleReset}>
                Reseteaza formular
              </Button>
            </div>
          ) : (
            <>
              {/* <SwipeableViews
                index={activeStep}
                animateheight
                // style={{ minHeight: 800 }}
                // containerStyle={{
                //   width: "100%",
                //   height: "100%",
                //   WebkitOverflowScrolling: "touch",
                // }}
              >
                {steps.map((step, index) => {
                  //   const Component = getStepContent(index);
                  //   return <Component key={index} />;

                  return getStepContent(index);
                })}
              </SwipeableViews> */}
              {getStepContent(activeStep)}
              <div className="stepsNav">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className="btn transparent-btn"
                >
                  Back
                </Button>
                <Button
                  onClick={handleSubmit}
                  className="btn red"
                  disabled={formikContactDetails.isSubmitting}
                >
                  {isLastStep() ? "Trimite formular" : "Next"}
                </Button>
              </div>
            </>
          )}
        </StyledContainer>
      </div>
    </div>
  );
}
