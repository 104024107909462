import React, { useRef, useState } from "react";
import { VideoWrap } from "./VideoInspiration.styled";
import ReactPlayer from "react-player";
import styled from "styled-components";

const Video = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
  position: absolute;
  width: 100% !important;
  top: 0;
  left: 0;

  background-size: cover;
  background-position: center;
  overflow: hidden;
`;
export default function VideoInspiration({ video }) {
  let videoRef = useRef(null);

  const [play, setPlay] = useState(true);

  const handleEnded = () => {
    setPlay(false);
    console.log("onEnded", videoRef);

    // this.player.seekTo(5, "seconds");
    videoRef.current.seekTo(0, "seconds");

    console.log("afterseek", videoRef);
    setPlay(true);
  };

  console.log(videoRef);

  return (
    <VideoWrap>
      <div className="videoBackground">
        <Video
          ref={videoRef}
          url={video.video_url}
          playing={play}
          muted={true}
          width="100%"
          height="100%"
          //   light={true}
          controls={false}
          //   loop={true}
          onReady={(e) => console.log(e)}
          onEnded={handleEnded}
        />
      </div>
      <div className="videoInfo">
        <div className="brand">{video.brand}</div>
        <h2 className="title">{video.title}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: video.description,
          }}
        />
        <button className="play">
          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.612 8.36007C16.796 9.13281 16.796 10.8672 15.612 11.6399L3.52861 19.5262C2.22605 20.3764 0.499999 19.4418 0.499999 17.8863L0.5 2.11369C0.5 0.558245 2.22605 -0.376373 3.52861 0.473756L15.612 8.36007Z"
              fill="white"
            ></path>
          </svg>
          Play
        </button>
      </div>
    </VideoWrap>
  );
}
