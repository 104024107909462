import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const VideoSection = styled(Section)`
  max-width: 1160px;
  margin: 0 auto;
  margin-top: -15rem;
  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 2rem 0;
    padding: 1rem 0;
  }

  .videoWrapper {
    /* padding: 0 7rem; */
    background: #e8e8e8;
    border-radius: 10px;
    margin: 0 auto;
    /* width: 1280px;
    height: 720px; */
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 3rem;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
