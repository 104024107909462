import React, { useEffect } from "react";

import ScrollToTop from "./hooks/scrollToTop";

import Header from "./components/header";
import Footer from "./components/footer";
import "./styles/styles.css";
import { ContentWrapper, Main } from "./styles/GlobalStyles";
import { ResetCSS } from "./styles/ResetStyles";
import HomePage from "./pages/homepage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contactpage from "./pages/contact";
import LiveStreamingPage from "./pages/livestreaming";
import CompetitiiSportivePage from "./pages/livestreaming/competitiiSportive";
import EvenimenteCulturalePage from "./pages/livestreaming/evenimenteCulturale";
import BrandsCorporatePage from "./pages/livestreaming/brandCorporate";
import VideoMarketingPage from "./pages/livestreaming/videoMarketing";
import ServicePage from "./pages/servicePage";
import CarierePage, {
  CariereApplyPage,
  CariereDetailsPage,
} from "./pages/cariere";

import CategoryPage from "./pages/categoryPage";

import BlogPage from "./pages/blog";
import BlogDetailsPage from "./pages/blogDetailsPage";
import InspirationPage from "./pages/inspiration/inspiration";
import InspirationDetailsPage from "./pages/inspiration/inspirationDetailsPage";
import Services from "./pages/services";
import AboutPage from "./pages/aboutPage";
import CotatiePage from "./pages/cotatiePage";

import TagManager from "react-gtm-module";

export default function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WZDFG6ZG" });
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <ResetCSS />

      <ContentWrapper>
        <Header></Header>
      </ContentWrapper>
      <Main>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          {/* <Route path="/service">
            <ServicePage></ServicePage>
          </Route> */}

          <Route
            path="/:section/:category/:service"
            element={<ServicePage />}
          />

          <Route path="/:section/:category" element={<CategoryPage />} />

          {/* <Route
            path="/livestreaming/competitii-sportive"
            element={<CompetitiiSportivePage />}
          />
          <Route
            path="/livestreaming/evenimente-culturale"
            element={<EvenimenteCulturalePage />}
          />

          <Route
            path="/livestreaming/brands-corporate-events"
            element={<BrandsCorporatePage />}
          />

          <Route
            path="/livestreaming/video-marketing"
            element={<VideoMarketingPage />}
          /> */}

          <Route path="/services" element={<Services />} />
          <Route path="/livestreaming" element={<LiveStreamingPage />} />

          <Route path="/cariere/:id/apply" element={<CariereApplyPage />} />
          <Route path="/cariere/:id" element={<CariereDetailsPage />} />
          <Route path="/cariere" element={<CarierePage />}></Route>
          <Route path="/blog/:slug" element={<BlogDetailsPage />}></Route>
          <Route path="/blog" element={<BlogPage />}></Route>

          <Route
            path="/showcase/:slug"
            element={<InspirationDetailsPage />}
          ></Route>
          <Route path="/showcase" element={<InspirationPage />}></Route>

          <Route path="/contact" element={<Contactpage />} />
          <Route path="/cotatie" element={<CotatiePage />} />
          <Route path="/despre" element={<AboutPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Main>
      <Footer></Footer>
    </Router>
  );
}
