import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import qs from "qs";
import Slider from "react-slick";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CategoryWrap = styled.div`
  /* display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 2px;
  margin-bottom: 50px;
  transition: all 0.5s ease 0s; */

  .slick-arrow {
    margin: unset;
    min-width: unset;
    /* width: 25px;
    height: 25px; */

    position: absolute;
    top: 50%;
    width: 130px;
    height: 61px;
    border: none;
    z-index: 5;
    color: transparent;

    :before {
      color: #202020;
      /* font-size: 30px; */
    }
    &.slick-next {
      right: 0;
      background: linear-gradient(
        270deg,
        #ffffff 31.31%,
        rgba(255, 255, 255, 0) 93.93%
      );
      text-align: right;
      ${({ variant }) =>
        variant === "dark" &&
        `
        background: linear-gradient(
        270deg,
        #272b38 31.31%,
        rgba(255, 255, 255, 0) 93.93%
      );
        `}
    }

    &.slick-prev {
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 31.31%,
        #ffffff 93.93%
      );
      text-align: left;
      ${({ variant }) =>
        variant === "dark" &&
        `
          background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 31.31%,
        #272b38 93.93%
      );
        `}
    }
    &.slick-disabled {
      display: none !important;
    }
  }

  .catItem {
    span {
      display: block;
      font-size: 14px;
      line-height: 24px;
      background: #ededed;
      border-radius: 8px;
      padding: 8px 16px;
      white-space: nowrap;
      margin-right: 12px;
      cursor: pointer;
      ${({ variant }) =>
        variant === "dark" &&
        `
        background: #202020;
    `}
      :hover {
        background: #202020;
        color: #fff;
        ${({ variant }) =>
          variant === "dark" &&
          `
        background: #fff;
        color: #202020;
        `}
      }
    }
    &.active {
      span {
        background: #202020;
        color: #fff;

        ${({ variant }) =>
          variant === "dark" &&
          `
        background: #fff;
        color: #202020;
        `}
      }
    }
  }
`;

export default function CategorySlider(props) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getCategories() {
    const query = qs.stringify({
      pagination: {
        page: 1,
        pageSize: 50,
      },
    });

    setLoading(true);
    let url = `https://api.ovn.ro/api/categories?${query}`;
    // console.log(url);

    try {
      const res = await axios.get(url);
      setCategories(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  const settings = {
    // className: "center",
    infinite: false,
    centerMode: false,
    slidesToShow: 1,

    variableWidth: true,

    // afterChange: function (index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // },
  };

  if (loading) return <div>Loading</div>;
  return (
    <CategoryWrap variant={props.dark ? "dark" : "light"}>
      <Slider {...settings}>
        <div
          className={props.activeCat === "all" ? "active catItem" : "catItem"}
          onClick={() => props.onSelectCat("all")}
        >
          <span>All</span>
        </div>
        {categories
          .sort(function (a, b) {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
          })
          .map((cat, index) => (
            <div
              key={index}
              className={
                props.activeCat === cat.slug ? "active catItem" : "catItem"
              }
              onClick={() => props.onSelectCat(cat.slug)}
            >
              <span>{cat.title}</span>
            </div>
          ))}
      </Slider>
    </CategoryWrap>
  );
}
