import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const TilesSection = styled(Section)`
  /* make changes as needed*/

  background-image: url("https://jwplayer.com/wp-content/uploads/2022/11/grad-img-03.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  padding: 5rem 0;
  margin: 5rem 0;
  margin-bottom: 0;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .intro {
    margin: 0 auto 2rem auto;
    text-align: center;
    position: relative;
    z-index: 2;
    @media (min-width: ${({ theme }) => theme.md}) {
      margin: 0 auto 4rem auto;
    }

    h4 {
      color: #fff;
      font-size: 2rem;
      font-weight: 900;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .tileWrap {
    height: 9rem;
    @media (min-width: ${({ theme }) => theme.md}) {
      height: 15rem;
    }
    /* flex: 0 1 calc(33.333% - 30px); */
    .tileInner {
      position: relative;
      width: 100%;
      height: 100%;

      .tileFront {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 12px;
        border: 2px solid #f3f5f9;
        transition: display 200ms;
        @media (min-width: ${({ theme }) => theme.md}) {
          padding: 3rem;
        }

        figure {
          width: fit-content;
          margin: 0 auto;

          img {
            min-height: 50px;
            height: 50px;
            @media (min-width: ${({ theme }) => theme.md}) {
              min-height: 75px;
              height: 75px;
            }
          }
        }

        h5 {
          color: #0f1a3d;
          text-align: center;
          margin-top: 0.1rem;
          margin-bottom: 0;
          font-size: 0.8em;
          line-height: 1.2;
          @media (min-width: ${({ theme }) => theme.md}) {
            margin-top: 1rem;
            font-size: 1em;
          }
        }
      }

      .tileBack {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(243, 245, 249, 0.05);
        border-radius: 12px;
        padding: 0.5rem;
        background-image: linear-gradient(
          90deg,
          #0786a3 0%,
          #0786a3 14.29%,
          #0786a3 28.57%,
          #0786a3 42.86%,
          #0786a3 57.14%,
          #0786a3 71.43%,
          #0786a3 85.71%,
          #0786a3 100%
        );
        color: #fff;
        flex-direction: column;
        justify-content: center;
        display: none;
        @media (min-width: ${({ theme }) => theme.md}) {
          padding: 1.5rem;
        }

        h5 {
          color: #fff;
          text-align: left;
          margin: 0;
          line-height: 1.2;
          font-size: 0.8em;
          @media (min-width: ${({ theme }) => theme.md}) {
            font-size: 1em;
          }
        }

        p {
          color: rgba(255, 255, 255, 0.8);
          font-size: 0.6em;
          line-height: 1.4;
          margin-bottom: 1rem;
          @media (min-width: ${({ theme }) => theme.md}) {
            font-size: 0.8em;
            margin-bottom: 2rem;
          }
        }

        a {
          color: #fff;
          font-size: 0.8em;
          @media (min-width: ${({ theme }) => theme.md}) {
            font-size: 1em;
          }
        }
      }
    }
  }

  .tileWrap:hover {
    .tileFront {
      display: none;
    }

    .tileBack {
      display: flex;
    }
  }

  /* .MuiGrid-item {
    padding: 0 4rem 0 1rem;
    ${({ reverse }) => reverse && ` padding: 0 3rem;`}
    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 4rem 0 0rem;
      ${({ reverse }) => reverse && ` padding: 0 3rem;`}
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0 1rem;
      margin-bottom: 3rem;
      //   ${({ reverse }) => reverse && `  padding: 0 1rem;`}
    }
  } */

  /* .slider-btn {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    > * {
      margin-bottom: 16px;
      margin-right: 0;
      cursor: pointer;
    }

    @media (max-width: ${({ theme }) => theme.md}) {
      display: flex;
      flex-direction: column;
    }
  } */

  /* .slider-image {
    border-radius: 10px;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    ${({ reverse }) => reverse && `   direction: rtl;`}

    img {
      border-radius: 10px;
      width: 100%;
      filter: drop-shadow(5px 10px 10px rgba(47, 53, 66, 0.3))
        drop-shadow(3px 5px 7px rgba(47, 53, 66, 0.1));
      @media (min-width: ${({ theme }) => theme.md}) {
        width: 600px;
      }
      @media (min-width: ${({ theme }) => theme.lg}) {
        width: 800px;
      }
    }
  } */
`;
