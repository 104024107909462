import React from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

import { InspirationWrap, StyledContainer } from "../../styles/GlobalStyles";
import VideoList from "../../sections/VideoList";
import { VideoDetailsSection } from "../../sections/VideoList.styled";
import { Grid } from "@material-ui/core";
import VideoEmbed from "../../components/videoEmbed";
import usePageTracking from "../../hooks/usePageTracking";

export default function InspirationDetailsPage() {
  let { slug } = useParams();
  usePageTracking();

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/videos/${slug}`
  );
  if (loading) return <p> Loading... </p>;
  if (error) return <Navigate to="/inspiratie" />;

  console.log("video data", data);

  return (
    <InspirationWrap>
      <StyledContainer maxWidth="xl">
        <VideoDetailsSection>
          <Grid container>
            <Grid item xs={12} md={4} className="VideoDetailsText">
              <span>{data.brand}</span>
              <h2>{data.title}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              ></div>

              <div className="categories">
                {data.categories.map((cat) => (
                  <span key={cat.id}>{cat.title}</span>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} md={8} className="VideoDetailsVideo">
              <VideoEmbed url={data.video_url} light={false}></VideoEmbed>
            </Grid>
          </Grid>

          <div className="contactus">
            Iți place ceea ce vezi? <Link to="/contact">Contactează-ne</Link>{" "}
            pentru o ofertă personalizată.
          </div>
        </VideoDetailsSection>

        <VideoList></VideoList>
      </StyledContainer>
    </InspirationWrap>
  );
}
