import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: relative;
  background-color: white;
  padding: 0 20px !important;
  width: auto;

  @media (min-width: ${({ theme }) => theme.md}) {
    background-color: #272b38;
    padding: 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.lg}) {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  #scrollToTop {
    position: absolute;
    top: 0;
    right: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: #35c2d8;
    color: #272b38;
    margin-top: -25px;
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;

    span {
      font-size: 35px;
      line-height: 40px;
    }
  }

  .footer-menu-cols {
    max-width: 1600px;
    margin: auto;
  }

  .footer-menu-cols .menu-col .menu {
    margin: 0;
    padding: 0 10px;
    list-style: none;
    max-width: 100%;
  }

  .footer-menu-cols .menu-col .menu > li {
    border-bottom: 1px solid #d7dde5;
    padding: 20px 0;
  }

  .footer-menu-cols .menu-col .menu > li.show .submenu {
    display: block;
  }

  .footer-menu-cols .menu-col .menu > li a,
  .footer-menu-cols .menu-col .menu > li span {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.32px;
    color: #35c2d8;
    text-decoration: none;
    margin: 0;
  }

  .footer-menu-cols .menu-col .menu > li .submenu {
    display: none;
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
  }

  .footer-menu-cols .menu-col .menu > li .submenu li {
    margin-bottom: 20px;
  }

  footer.footerNew
    .footer-menu-cols
    .menu-col
    .menu
    > li
    .submenu
    li:last-child {
    margin-bottom: 0;
  }

  .footer-menu-cols .menu-col .menu > li .submenu li a {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.32px;
    color: #a0a3aa;
  }

  .bottom {
    margin: 50px auto;
    max-width: 1600px;
    @media (min-width: ${({ theme }) => theme.md}) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #a0a3aa;
      padding: 25px 0;
      margin-top: 0;
      margin: 50px auto 0;
    }
  }

  .bottom .logo {
    display: flex;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.md}) {
      display: block;
      justify-content: none;
      order: 1;
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .bottom .logo svg {
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    margin: 0;
    vertical-align: middle;
    .st1 {
      fill: #a0a3aa;
    }
    @media (min-width: ${({ theme }) => theme.md}) {
      min-width: 120px;
      max-width: 120px;
      height: auto;
      max-height: 100px;
    }
  }

  .bottom .copyright {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.28px;
    color: #a0a3aa;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 100%;
    text-align: center;
    min-height: 40px;
    @media (min-width: ${({ theme }) => theme.md}) {
      text-align: left;
      order: 3;
      margin: 0;
      width: 50%;
    }
  }

  .bottom .bottom-menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: center;
    min-height: 40px;

    @media (min-width: ${({ theme }) => theme.md}) {
      order: 2;
      justify-content: flex-end;
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .bottom .bottom-menu li {
    line-height: 12px;
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid #a0a3aa;
    padding-right: 14px;
    margin-right: 14px;
  }

  .bottom .bottom-menu li:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .bottom .bottom-menu li a {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.28px;
    color: #a0a3aa;
    text-decoration: none;

    @media (min-width: ${({ theme }) => theme.md}) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .bottom .logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    a svg {
      fill: #a0a3aa;
      width: 100%;
      height: 35px;
      margin-top: 0;
    }
    @media (min-width: ${({ theme }) => theme.md}) {
      order: 4;
      margin: 0;
      width: 50%;
      justify-content: flex-end;

      a {
        margin-right: 30px;
        margin-bottom: 0;
      }

      a:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 992px) {
    #scrollToTop {
      top: 60px;
      right: 30px;
    }

    .footer-menu-cols {
      display: flex;
      justify-content: space-between;
      padding: 170px 0;
    }

    .footer-menu-cols .menu-col .menu > li {
      border: none;
      padding: 0;
    }

    .footer-menu-cols .menu-col .menu > li > a {
      display: block;
      margin-bottom: 30px;
    }

    .footer-menu-cols .menu-col .menu > li a,
    .footer-menu-cols .menu-col .menu > li span {
      font-size: 16px;
      line-height: 26px;
    }

    .footer-menu-cols .menu-col .menu > li .submenu {
      display: block;
      margin: 30px 0 0;
    }

    footer.footerNew
      .footer-menu-cols
      .menu-col
      .menu
      > li
      .submenu
      li:last-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1440px) {
    .footer-menu-cols .menu-col {
      max-width: 20%;
    }

    .bottom {
      align-items: flex-end;
    }

    .bottom .logo {
      width: auto;
      margin-bottom: 0;
    }

    .bottom .copyright {
      width: auto;
      font-size: 14px;
      line-height: 24px;
    }

    .bottom .bottom-menu {
      width: auto;
      margin-bottom: 5px;
    }

    .bottom .bottom-menu li a {
      font-size: 14px;
      line-height: 14px;
    }

    .bottom .bottom-menu li {
      padding-right: 20px;
      margin-right: 20px;
    }

    .bottom .logos {
      width: auto;
    }
  }
  @media only screen and (max-width: 992px) {
    .footer-menu-cols .menu-col {
      padding: 0;
    }
  }
`;
