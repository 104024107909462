import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const FeaturesSection = styled(Section)`
  padding: 5rem 0;
  margin: 5rem 0;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .MuiGrid-item {
    padding: 0 4rem 0 1rem;

    @media (max-width: ${({ theme }) => theme.lg}) {
      padding: 0 4rem 0 0rem;
    }
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 0 1rem;
      margin-bottom: 3rem;
    }
  }

  .bigDescription {
    font-size: 33px;
    font-weight: 700;
    line-height: 1.4;
    margin: 2rem 10%;
    text-align: center;
    color: #0786a3;

    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 23px;
      margin: 1rem 0;
    }
  }

  .images {
    display: flex;
    margin-top: 2rem;
  }

  .images span {
    margin-left: 4px;
    border-radius: 12px;
    width: 100%;
    overflow: hidden;

    :first-child {
      margin-left: 0;
    }
  }

  .featItem {
    display: flex;
    flex-direction: row;
    margin: 3rem 0;
    @media (max-width: ${({ theme }) => theme.sm}) {
      flex-direction: column;
      align-items: center;
    }

    span {
      margin: 0 2rem 0 0;
      height: 70px;
      width: 70px;
      color: #35c2d8;
      font-size: 70px;
      text-align: center;
      @media (max-width: ${({ theme }) => theme.sm}) {
        margin: 0;
        // height: 100px;
        // width: 100px;
      }
    }

    .featContent {
      h4 {
        font-size: 22px;
        margin: 0;
        font-weight: 700;
        font-family: "Lato", sans-serif;
        @media (max-width: ${({ theme }) => theme.sm}) {
          text-align: center;
        }
      }

      .featDescription {
        margin-top: 0.5em;
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.4;
          text-align: left;
          color: #6b7c93;

          margin: 0.2rem 0 0 0;

          @media (max-width: ${({ theme }) => theme.sm}) {
            text-align: center;
          }
        }
      }
    }
  }
`;
