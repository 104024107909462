import React from "react";
import usePageTracking from "../hooks/usePageTracking";

import styled from "styled-components";
import { PageWrap, StyledContainer, Section } from "../styles/GlobalStyles";
import contatieImg from "../images/llivestreaming_camera_ovn.png";
import CallIcon from "@material-ui/icons/Call";

import Hero from "../sections/Hero";
import CotatieForm from "../components/CotatieForm";

export default function CotatiePage() {
  usePageTracking();

  return (
    <>
      {/* <PageWrap className="static">
        <Hero
          light="1"
          title="Solicită o cotație de preț"
          subtitle="Vă oferim cea mai bună ofertă raportată la calitate/preț."
        ></Hero>
      </PageWrap> */}

      <CotatieWrap>
        <div
          className="background"
          style={{
            backgroundImage: `url(${contatieImg})`,
          }}
        ></div>

        <div className="title">
          Ai nevoie de inovație și creativitate pentru producția ta video?
        </div>
        <div className="description">
          <h4>
            Tehnologie de ultimă generație, ani de experiență specializată.
            Soluții personalizate.
          </h4>
        </div>
      </CotatieWrap>
      <TagWrap>
        <StyledContainer maxWidth="xl">
          <h3>
            {/* Dacă sunteți în căutarea unui serviciu, produs sau o soluție
            completa, vom fi bucuroși să facem brainstorming cu dvs. */}
            FORMULAR COTAȚIE
          </h3>
          <div className="call">
            Te Grăbești?{" "}
            <a href="tel:+40725875478">
              <CallIcon /> 0725.87.54.78
            </a>
          </div>
          {/* <div className="line"></div> */}
          {/* <div className="lead">
            Dacă sunteți în căutarea unui serviciu, produs sau o soluție
            completa, vom fi bucuroși să facem brainstorming cu dvs.
          </div> */}
        </StyledContainer>
      </TagWrap>

      <CotatieForm></CotatieForm>
    </>
  );
}

const TagWrap = styled(Section)`
  background: #36c2d9;
  position: relative;
  /* margin-bottom: 4rem; */

  :before {
    border-radius: 0 0 0 8px;
    content: "";
    display: block;
    position: absolute;
    background: #0787a4;
    width: 50%;
    height: 100px;
    opacity: 0.4;
    right: 0;
    @media (max-width: ${({ theme }) => theme.md}) {
      width: 85%;
      height: 50px;
    }
  }

  .line {
    height: 2px;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    margin-bottom: 4rem;
    position: relative;
  }

  .call {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(50% - 16px);
    height: 100px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    /* opacity: 0.7; */
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.md}) {
      height: 50px;
      font-size: 0.8rem;
      width: 85%;
      padding: 0 16px;
      justify-content: space-between;
    }

    a {
      color: #0787a4;
      margin-left: 16px;
      display: flex;
      align-items: center;
      font-size: 3rem;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.5rem;
        margin-left: 0;
      }

      svg {
        width: 3rem;
        height: 3rem;
        @media (max-width: ${({ theme }) => theme.md}) {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  h3 {
    color: #fff;
    width: 80%;
    font-size: 4rem;
    margin: 2rem 0 0 0;
    position: relative;

    @media (max-width: ${({ theme }) => theme.md}) {
      width: 100%;
      font-size: 2rem;
      text-align: center;
      margin: 70px 0 0 0;
    }
  }
`;

const CotatieWrap = styled(Section)`
  background: #36c2d9;
  padding-top: 76px;
  padding-bottom: 80px;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: visible;
  min-height: 550px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.md}) {
    /* margin-bottom: 110px; */
    min-height: 350px;
  }

  .background {
    position: absolute;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 100%;
    max-height: 600px;
    background-position: center;

    ::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      background: linear-gradient(
        180deg,
        rgba(7, 134, 163, 0.3) 50%,
        #36c2d929 100%
      );

      /* background: #36c2d929; */
      backdrop-filter: blur(4px);

      max-height: 550px;
    }
  }

  .title {
    position: relative;
    margin: 3rem 1rem;
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    width: 60%;
    color: #fff;
    @media (max-width: ${({ theme }) => theme.md}) {
      font-size: 2rem;
      width: 100%;
    }
  }
  .description {
    position: absolute;
    padding: 40px 80px 40px 60px;
    right: 0;
    bottom: 0;
    width: 50%;
    border: 8px;
    backdrop-filter: blur(20px);
    /* background: #36c2d980; */
    background: #0786a3;
    border-top-left-radius: 12px;
    @media (max-width: ${({ theme }) => theme.md}) {
      padding: 20px;
      width: 85%;
    }

    h4 {
      color: #fff;
      margin: 0;
      opacity: 0.7;
      position: relative;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 11px;
      }
      :after {
        background-color: #fff;
        opacity: 0.7;
        content: "";
        height: 100%;
        left: -20px;
        position: absolute;
        top: 0;
        width: 4px;
        @media (max-width: ${({ theme }) => theme.md}) {
          left: -10px;
          width: 2px;
        }
      }
    }
  }
`;
