import React, { useState, useEffect } from "react";
import { Button, StyledContainer } from "../styles/GlobalStyles";

import qs from "qs";
import axios from "axios";

import { BlogSection } from "./Blog.styled";
import BlogCard from "../components/cards/BlogCard";
import CategorySlider from "../components/CategorySlider";

import { TextField, InputAdornment, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export default function BlogAll() {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);

  function handleCategory(cat) {
    setActive(cat);
  }

  async function getArticles(pageno = 1, filter = null) {
    const query = qs.stringify({
      populate: ["cover", "categories"],

      pagination: {
        page: pageno,
        pageSize: 9,
      },
      fields: ["title", "slug"],
      filters: { ...filter },
      sort: "date:desc",
    });

    setLoading(true);
    setSearchQuery(filter);
    let url = `https://api.ovn.ro/api/articles?${query}`;
    // console.log(url);

    try {
      const res = await axios.get(url);

      if (pageno > 1) {
        setArticles([...articles, ...res.data.data]);
      } else {
        setArticles(res.data.data);
      }

      setPage(pageno + 1);
      setTotal(res.data.meta.pagination.total);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   getArticles();
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log("search", searchTerm);
      // Send Axios request here
      let filter = null;
      if (searchTerm !== "") {
        console.log("fetch blog post");
        filter = {
          $or: [
            {
              title: {
                $contains: searchTerm.split(" "),
              },
            },
            {
              content: {
                $contains: searchTerm.split(" "),
              },
            },
          ],
        };
      }

      if (active && active !== "all") {
        let add = {
          categories: {
            slug: {
              $in: active,
            },
          },
        };

        filter = { ...filter, ...add };
      }

      // console.log(filter);

      getArticles(1, filter);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, active]);
  return (
    <BlogSection>
      <StyledContainer maxWidth="xl">
        <h2>All Blog Posts</h2>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <TextField
              placeholder="Search"
              type="search"
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
              // onChange={handleSearchFieldOnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={10} xs={12}>
            <CategorySlider
              activeCat={active}
              onSelectCat={handleCategory}
            ></CategorySlider>
          </Grid>
        </Grid>

        <div className="blogWrap">
          {articles.map((article, index) => (
            <BlogCard article={article} key={index}></BlogCard>
          ))}
        </div>
        {articles.length < total && (
          <Button onClick={() => getArticles(page, searchQuery)}>
            Show more
          </Button>
        )}
      </StyledContainer>
    </BlogSection>
  );
}
