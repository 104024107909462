import React, { Component } from "react";

import { TilesSection } from "./Tiles.styled";

import { Title, StyledContainer } from "../styles/GlobalStyles";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

class Tiles extends Component {
  state = {};
  render() {
    //tiles array [{title, text, link_title, link_url, icon}]
    const { title, tiles } = this.props;
    return (
      <TilesSection {...this.props}>
        <StyledContainer maxWidth="lg">
          <div className="intro">
            <Title>{title}</Title>
          </div>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {tiles.map((tile, index) => (
              <Grid
                item
                md={4}
                xs={6}
                component={Box}
                className="tileWrap"
                key={index}
              >
                <div className="tileInner">
                  <div className="tileFront">
                    <figure>
                      <img src={tile.icon} alt="" />
                    </figure>
                    <h5 className="title">{tile.title}</h5>
                  </div>
                  <div className="tileBack">
                    <h5 className="title">{tile.title}</h5>
                    <p className="text">{tile.text}</p>
                    <a href={tile.link_url} target="_self">
                      {tile.link_title}
                    </a>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </StyledContainer>
      </TilesSection>
    );
  }
}

export default Tiles;
