import React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";
import { Button } from "../../styles/GlobalStyles";

import { CategoryWrap } from "./CategoryCard.styled";

export default function CategoryCard(props) {
  const { category } = props;

  const [open, setOpen] = useState(false);

  const serviceCount = category.services.length;
  // console.log("cat", category.services[0]?.background?.url);
  let image_style = {
    backgroundImage: `url(https://api.ovn.ro/uploads/fotbal_bg_474d41198b.png)`,
  };

  if (category.services[0]?.background?.url) {
    image_style = {
      backgroundImage: `url(https://api.ovn.ro${category.services[0].background.url})`,
    };
  }

  return (
    <CategoryWrap>
      <Link to={`/${category.section.slug}/${category.slug}`}>
        <div className="item" style={image_style}>
          <span className="section">{category.section.title}</span>
          <h3>{category.title}</h3>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: category.description }}
          ></div>
          {/* <Button>Detalii</Button> */}
        </div>
      </Link>
      <div
        className="serviceCount"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <span>
          {serviceCount} {serviceCount > 1 ? " Servicii" : "Serviciu"} Video
        </span>
        <div
          className="serviceList"
          style={{ display: open ? "block" : "none" }}
        >
          {category.services
            .sort(function (a, b) {
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
              return 0;
            })
            .map((item, index) => (
              <Link
                key={index}
                to={`/${item.section.slug}/${item.categories[0].slug}/${item.slug}`}
              >
                <img
                  src={`https://api.ovn.ro${item.background?.formats.thumbnail.url}`}
                />
                <b>{item.title}</b>
              </Link>
            ))}
        </div>
      </div>
    </CategoryWrap>
  );
}
