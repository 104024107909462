import styled from "styled-components";

export const VideoWrap = styled.div`
  background: #202020;
  border-radius: 24px;
  margin: 4rem 0;
  aspect-ratio: 16 / 7;
  height: auto;
  overflow: hidden;
  position: relative;

  .videoBackground {
    :after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      background: #070707;
      opacity: 0.6;
      /* z-index: 20; */
    }
  }

  .videoInfo {
    position: absolute;
    top: 48px;
    left: 48px;
    max-width: 520px;
    display: flex;
    flex-direction: column;

    .brand {
      color: #fff;
      opacity: 0.7;
    }

    .title {
      font-size: 3.5rem;
      line-height: 1.2;
      color: #fff;
      margin-bottom: 0;
      font-weight: 900;
    }

    .description {
      margin-bottom: 1rem;
      p {
        font-size: 18px;
        line-height: 28px;
        color: #fff;
      }
    }

    .play {
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      /* background: #36c2d9; */
      background: rgba(32, 32, 32, 0.25);
      border: 1.5px solid rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(24px);
      border-radius: 100px;
      padding: 12px 20px;
      display: flex;
      max-width: 110px;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease 0s;

      :hover {
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      svg {
        margin-right: 8px;
      }
    }
  }
`;
