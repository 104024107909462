import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

import showcase from "../images/front/showcase_icon.png";
import blog from "../images/front/blog_icon.png";
import services from "../images/front/services_icon.png";
import cariere from "../images/front/cariere_icon.png";

export const HeroTilesSection = styled(Section)`
  padding: 0;
  margin: 0;
  margin-top: -12rem;
  margin-bottom: 8rem;
  /* background: #0786a3; */
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin-bottom: 0;
  }

  .cardWrap {
    display: flex;
    margin-left: -18px;
    margin-right: -18px;
    overflow-x: visible;
    @media (max-width: ${({ theme }) => theme.md}) {
      overflow-x: auto;
      padding: 0 18px;
    }

    .item {
      @media (max-width: ${({ theme }) => theme.md}) {
        flex: 0 0 295px;
        margin-right: 12px;
      }

      &.marketplace {
        @media (min-width: ${({ theme }) => theme.md}) {
          flex: 0 0 50%;
          padding: 0 18px;
        }
        a {
          background: url(${services}) no-repeat center,
            linear-gradient(308deg, #de3a33 5.34%, #0688a5 100%);
          background-size: cover;
          @media (min-width: ${({ theme }) => theme.md}) {
            h3 {
              font-size: 42px;
              line-height: 1.2;
              max-width: 440px;
            }
          }
        }
      }

      &.d-flex {
        margin: 0 18px;
        flex-direction: column;
        display: flex;

        @media (max-width: ${({ theme }) => theme.md}) {
          flex: 0 0 602px;
          flex-direction: row;
          margin: 0;
          margin-right: 12px;
        }
      }

      &.locations {
        @media (min-width: ${({ theme }) => theme.md}) {
          flex: 0 0 25%;
          padding: 0;
        }

        a {
          background: url(${cariere}) no-repeat center, #de3a32;
          background-size: cover;
          @media (min-width: ${({ theme }) => theme.md}) {
            height: 268px;
            margin-bottom: 36px;
          }
        }
      }

      &.blog {
        @media (min-width: ${({ theme }) => theme.md}) {
          flex: 0 0 25%;
          padding: 0;
        }
        a {
          background: url(${blog}) no-repeat center, #0787a4;
          background-size: cover;
          @media (min-width: ${({ theme }) => theme.md}) {
            height: 268px;
          }
        }
      }

      &.inspiration {
        @media (min-width: ${({ theme }) => theme.md}) {
          flex: 0 0 25%;
          padding: 0 18px;
        }
        a {
          background: url(${showcase}) no-repeat bottom center, #272b38;
          background-size: cover;
        }
      }

      a {
        color: #fff;
        position: relative;
        display: block;
        border-radius: 20px;
        overflow: hidden;
        height: 100%;
        padding: 48px;
        background-size: cover;
        @media (max-width: ${({ theme }) => theme.md}) {
          height: 300px;
          padding: 24px;
        }

        h3 {
          color: #fff;
          margin: 16px 0 0;
          font-size: 21px;
          line-height: 26px;
          max-width: 410px;
        }

        .link {
          position: absolute;
          left: 48px;
          bottom: 40px;
          font-size: 20px;
          line-height: 28px;
          @media (max-width: ${({ theme }) => theme.md}) {
            left: 24px;
            font-size: 16px;
          }

          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }
`;
