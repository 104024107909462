import React from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import { BlogWrap, StyledContainer } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";

import useFetch from "../hooks/useFetch";
import reactStringReplace from "react-string-replace";
import slugify from "../hooks/slugify";

import { BlogDetailsTitle, BlogDetailsContent } from "../sections/Blog.styled";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import TimerIcon from "@material-ui/icons/Timer";
import usePageTracking from "../hooks/usePageTracking";

/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */
const Headings = ({ headings, activeId }) => (
  <ol>
    {/* <li className={"Introducere" === activeId ? "active" : ""}>
      <a
        href="#intro"
        onClick={(e) => {
          e.preventDefault();
          document.querySelector(`#Introducere`).scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1. Introducere
      </a>
    </li> */}
    {headings.map((heading, index) => (
      <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <a
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();

            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {index === 0 ? "Introducere" : heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? "active" : ""}
              >
                <a
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ol>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  let count = 1;

  React.useEffect(() => {
    document.querySelectorAll(".blogContent h2").forEach(
      // using an anonymous Arrow function on each node of the NodeList,
      // wherein we assign the text-content of the current <h2> element -
      // after first replacing all strings of white-space with the '-'
      // character to the 'id' property:
      (title) => {
        title.id = slugify(title.textContent);
      }
      // (title.id = title.textContent
      //   .toLowerCase()
      //   .replace(/^[A-Za-z]{1}[A-Za-z\s]+$/, "")
      //   .replace(/\s+/g, "-")
      //   )
    );

    // document
    //   .getElementsByClassName("blogContent")
    //   .innerHTML("<p id='intro'>intro</p>");
    const headingElements = Array.from(
      document.querySelectorAll(".blogContent h2, .blogContent h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      //   root: document.querySelector("iframe"),
      //   rootMargin: "500px",
      rootMargin: "-110px 0px -40% 0px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav aria-label="Table of contents" className="blogTable">
      <h4>Sumar Articol</h4>
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

const EmbedComponent = ({ htmlString }) => {
  // Use a regular expression to find the oembed element in the HTML string
  const oembedRegex = /<oembed[^>]*>/g;
  const oembedMatch = htmlString.match(oembedRegex);

  // If an oembed element was found, convert it to an iframe element
  if (oembedMatch) {
    const oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
    const iframeElement = `<iframe src="${oembedUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    htmlString = htmlString.replace(oembedRegex, iframeElement);
  }

  return (
    <div
      className="blogContent"
      dangerouslySetInnerHTML={{ __html: "<H2>Introducere</H2>" + htmlString }}
    />
  );
};

const EmbedComponent2 = ({ htmlString }) => {
  // Use a regular expression to find the oembed element in the HTML string

  const oembed = htmlString.split("</oembed>");
  let body = "";

  oembed.forEach((item, index) => {
    body += oembed[index] + "</oembed>";
    const oembed1 = item.split('url="')[1];
    if (oembed1) {
      const oembed2 = oembed1.split('">')[0];
      if (oembed2) {
        console.log("embed2", oembed2);
        const youtube = oembed2.split("https://www.youtube.com/watch?v=")[1];
        if (youtube) {
          body +=
            '<iframe src="https://youtube.com/embed/' +
            youtube +
            '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        } else {
          body +=
            '<iframe src="' +
            oembed2 +
            '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
      }
    }
  });
  return (
    <div
      className="blogContent"
      dangerouslySetInnerHTML={{ __html: "<H2>Introducere</H2>" + body }}
    />
  );
};

export default function BlogDetailsPage() {
  usePageTracking();
  let { slug } = useParams();

  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/articles/${slug}`
  );
  if (loading) return null;
  if (error) return <Navigate to="/blog" />;

  return (
    <>
      <BlogWrap
        className="static"
        // background={`https://api.ovn.ro${data.cover.url}`}
      >
        <div className="background">
          <img src={`https://api.ovn.ro${data.cover.url}`} />
        </div>
        {/* <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img> */}
        <StyledContainer maxWidth="xl">
          <BlogDetailsTitle>
            <span>Blog</span>
            <h1>{data.title}</h1>
            <p>
              {data.categories.map((cat) => (
                <span key={cat.slug}>{cat.title}</span>
              ))}
            </p>

            {/* <div dangerouslySetInnerHTML={{ __html: data.intro }}></div> */}
          </BlogDetailsTitle>
        </StyledContainer>
      </BlogWrap>
      <BlogDetailsContent>
        <StyledContainer maxWidth="xl">
          <div className="blogMeta">
            <span>
              {data.views} <RemoveRedEyeIcon />
            </span>
            <span>
              {data.reading_time} min <TimerIcon />
            </span>
          </div>
          <div className="blogContainer">
            <TableOfContents>Table of content</TableOfContents>
            <EmbedComponent2 htmlString={data.content} />
          </div>
        </StyledContainer>
      </BlogDetailsContent>
    </>
  );
}
