import React, { Component } from "react";

import { SliderSection } from "./Slider.styled";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Slider from "react-slick";

class CustomSlide extends Component {
  render() {
    const { slide } = this.props;

    return (
      <div className="slide">
        <img src={slide} />
      </div>
    );
  }
}

class LogoSlider extends Component {
  state = {};
  render() {
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 4000,
      arrows: false,
      variableWidth: true,
      cssEase: "linear",
      dots: false,
      pauseOnHover: false,
    };
    return (
      <SliderSection>
        <Container maxWidth="lg">
          <div className="wrapper">
            <h2
              className="title"
              dangerouslySetInnerHTML={{ __html: this.props.title }}
            ></h2>
            {this.props.subtitle && (
              <h4 className="subtitle">{this.props.subtitle}</h4>
            )}
          </div>

          <Slider className="logoSlider" {...settings}>
            {this.props.slides.map((slide, index) => (
              <CustomSlide key={index} slide={slide} />
            ))}
          </Slider>
        </Container>
      </SliderSection>
    );
  }
}

export default LogoSlider;

// <div class="item slick-slide" data-slick-index="6" aria-hidden="true" style="width: 300px;" tabindex="-1">

//         <figure class="logo-image">
//           <img src="https://jwplayer.com/wp-content/uploads/2022/12/logo-eurosport-white.svg" alt="" loading="lazy" class="logo">
//         </figure>

//             </div>
