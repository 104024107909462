import React from "react";
import VideoInspiration from "../../components/cards/VideoInspiration";
import VideoList from "../../sections/VideoList";
import { InspirationWrap, StyledContainer } from "../../styles/GlobalStyles";
import usePageTracking from "../../hooks/usePageTracking";

let videoData = {
  id: 1,
  title: "Maratonul nisipului",
  slug: "retail-revolution-2023",
  brand: "Online Video Network",
  video_provider: "youfacebooktube",
  video_url: "https://www.facebook.com/ovn.ro/videos/1053554015259300",
  description: "",
  locale: "ro",
};

export default function InspirationPage() {
  usePageTracking();
  return (
    <>
      <InspirationWrap>
        <StyledContainer maxWidth="xl">
          <VideoInspiration video={videoData}></VideoInspiration>
          <VideoList title></VideoList>
        </StyledContainer>
      </InspirationWrap>
    </>
  );
}
