import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FooterWrapper } from "../styles/footerStyles";

import { ReactComponent as LogoSimple } from "../ovn_logo_full.svg";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

// import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import AssignmentIcon from "@material-ui/icons/Assignment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

const socialIconItems = [
  {
    title: "facebook",
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/ovn.ro",
  },
  {
    title: "youtube",
    icon: <YouTubeIcon />,
    link: "https://www.youtube.com/c/OnlineVideoNetwork",
  },
  {
    title: "instagram",
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/ovn.ro/",
  },
  {
    title: "twitter",
    icon: <TwitterIcon />,
    link: "https://twitter.com/ovn_ro",
  },

  {
    title: "linkedin",
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/company/online-video-network/",
  },
];

const bottomMenuItems = [
  {
    title: "Privacy",
    link: "/privacy",
  },
  // {
  //   title: "DCMA",
  //   link: "/dcma",
  // },
  {
    title: "Terms",
    link: "/terms",
  },
  {
    title: "GDPR",
    link: "/gdpr",
  },
  {
    title: "Cookies",
    link: "/cookies",
  },
];

const menu = [
  {
    name: "About",
    path: "/despre",
    items: [
      {
        name: "Services",
        path: "/services",
      },
      {
        name: "Blog",
        path: "/blog",
      },
      {
        name: "Cariere",
        path: "/cariere",
      },
      {
        name: "Showcase",
        path: "/showcase",
      },
      {
        name: "Contact",
        path: "/contact",
      },
    ],
  },
  {
    name: "Live Streaming",
    path: "/livestreaming/competitii-sportive",
    items: [
      {
        name: "Competitii Sportive",
        path: "/livestreaming/competitii-sportive",
      },
      {
        name: "Evenimente Culturale",
        path: "/livestreaming/evenimente-culturale",
      },
      {
        name: "Brand & Corporate",
        path: "/livestreaming/brands-corporate-events",
      },
      { name: "Video Marketing", path: "/livestreaming/video-marketing" },
      // {
      //   name: "Provideri TV / Internet",
      //   path: "/solutii/provideri-tv-internet",
      // },
      // {
      //   name: "Organizatori Evenimente",
      //   path: "/solutii/organizatori-evenimente",
      // },
      // { name: "Televiziuni", path: "/solutii/televiziuni" },
    ],
  },

  {
    name: "Studio Space",
    path: "/servicii",
    items: [
      { name: "Chroma Key", path: "/studio/chromakey" },
      { name: "Studio Virtual", path: "/studio/virtual-events" },
      {
        name: "AR / VR Studio ",
        path: "/studio/virtual-events/lansare-produs-studio-virtual-3d",
      },
      { name: "Emisiuni / Podcast", path: "/studio/emisiuni-podcast" },
    ],
  },
  {
    name: "Video Platform",
    path: "/despre",
    items: [
      {
        name: "IOS & Androis Apps",
        path: "/video-development/ios-android-apps",
      },
      { name: "Video Player", path: "/video-development/video-player" },

      {
        name: "TV & Video Graphics",
        path: "/video-development/tv-graphics",
      },
      { name: "Consultanta Video", path: "/remote/consultanta-video" },
    ],
  },

  {
    name: "Others",
    fulllink: true,
    path: "/others",
    items: [
      { name: "myovn.com", path: "https://myovn.com", external: true },
      {
        name: "transmite.live",
        path: "https://transmite.live",
        external: true,
      },

      // { name: "sptv.net", path: "/cariere", external:true },
      { name: "cloudtv.ro", path: "https://cloudtv.ro", external: true },
    ],
  },
];

function SocialIcon({ itemData }) {
  return (
    <a href={itemData.link} target="_blank">
      {itemData.icon}
    </a>
  );
}

class Footer extends Component {
  state = { activeIndex: null };

  toggleClass(index) {
    if (this.state.activeIndex === index) {
      index = null;
    }
    console.log(index);
    this.setState({ activeIndex: index });
  }
  render() {
    function Copyright() {
      return (
        <p className="copyright">
          {"Copyright © "} 2013-{new Date().getFullYear()} {` `} SC Online Video
          Network SRL <br />
          J12/1860/2013 | RO31758529
        </p>
      );
    }
    return (
      // <footer>
      //   <div className="footerWrap">
      //     <Container maxWidth="lg">
      //       <Grid container justify="center">
      //         <Grid item xs={12} lg={12}>
      //           <h3 className="textCenter">Care sunt pașii următori?</h3>
      //         </Grid>
      //         <Grid item md={4}>
      //           <div className="textCenter footerStep">
      //             <span className="footerIcon">
      //               <AssignmentIcon></AssignmentIcon>
      //             </span>
      //             <h4>Oferta personalizata</h4>
      //             <p>
      //               Descrie proiectul tau video si iti vom face o oferta
      //               personalizata, exact cu ce ai nevoie.
      //             </p>
      //             <a href="#" className="btn red">
      //               Solicita acum!
      //             </a>
      //           </div>
      //         </Grid>
      //         <Grid item md={4}>
      //           <div className="textCenter footerStep">
      //             <span className="footerIcon">
      //               <ListAltIcon></ListAltIcon>
      //             </span>
      //             <h4>Lista preturi standard</h4>
      //             <p>
      //               Gaseste toate serviciile oferite de compania noastra si
      //               preturile aferente.
      //             </p>
      //             <a href="#" className="btn red">
      //               Descarca lista!
      //             </a>
      //           </div>
      //         </Grid>
      //         <Grid item md={4}>
      //           <div className="textCenter footerStep">
      //             <span className="footerIcon">
      //               <PhoneInTalkIcon></PhoneInTalkIcon>
      //             </span>
      //             <h4>Contacteaza-ne!</h4>
      //             <p>
      //               Pentru orice intrebare sau proiect video, iti stam la
      //               dispozitie!
      //             </p>
      //             <a href="#" className="btn red">
      //               Hai sa vorbim!
      //             </a>
      //           </div>
      //         </Grid>
      //       </Grid>
      //     </Container>
      //   </div>
      //   <Copyright />
      // </footer>

      <FooterWrapper className="footerNew">
        <button id="scrollToTop">
          <span className="material-icons">expand_less</span>
        </button>

        <div className="footer-menu-cols">
          {menu.map((item, index) => (
            <nav key={index} className="menu-col">
              <ul className="menu">
                {item.fulllink &&
                  item.items.map((submenu, index) => (
                    <li key={index} className="menu-item">
                      <Link
                        to={submenu.path + "?rel=ovn_ro_footer"}
                        target={submenu.external ? "_blank" : "_self"}
                      >
                        {submenu.name}
                      </Link>
                    </li>
                  ))}
                {!item.fulllink && (
                  <li
                    className={
                      this.state.activeIndex == index
                        ? "show menu-item"
                        : "menu-item"
                    }
                  >
                    <a
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => this.toggleClass(index)}
                    >
                      {item.name}
                    </a>
                    <ul className="submenu">
                      {item.items.map((submenu2, index) => (
                        <li key={index} className="menu-item">
                          <Link
                            to={submenu2.path}
                            target={submenu2.external ? "_blank" : "_self"}
                          >
                            {submenu2.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </nav>
          ))}
        </div>

        <div className="bottom">
          <a href="//ovn.ro" className="logo" aria-label="Home">
            <LogoSimple></LogoSimple>
          </a>

          <Copyright />

          <ul id="menu-footer-bottom" className="bottom-menu">
            {bottomMenuItems.map((item) => (
              <li key={item.link}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
          <div className="logos">
            {socialIconItems.map((item) => (
              <SocialIcon key={item.title} itemData={item} />
            ))}
          </div>
        </div>
      </FooterWrapper>
    );
  }
}

export default Footer;
