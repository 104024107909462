import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const SliderSection = styled(Section)`
  /* make changes as needed*/

  margin: 4rem 0 4rem 0;

  .title {
    /* color: #0f1a3d; */
    color: #6b7c93;
    font-size: 2rem;
    font-weight: 900;

    line-height: 1.2;
    text-align: center;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 1.2rem;
    line-height: 1.2;
    max-width: 65%;
    margin: 20px auto;
    text-align: center;
    margin-bottom: 20px;
    color: #6b7c93;
  }

  .logoSlider {
    /* background-color: #111; */
  }

  .slide {
    max-width: 350px;
    /* display: inline-block; */
    display: flex !important;
    height: 120px;
    padding: 0 40px;
    /* justify-content: flex-start;
    align-items: center; */

    justify-content: center;
    align-items: center;

    img {
      filter: grayscale(1);
      opacity: 0.7;
      width: 100%;
      max-height: 100%;
    }

    :hover img {
      filter: grayscale(0);
      opacity: 1;
      transition: 0.5s;
    }
  }

  /* Slider */
  .slick-slide {
    margin: 0px 40px;
  }

  .slick-slide img {
    /* max-height: 100%; */
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;

    -webkit-mask-image: linear-gradient(
      90deg,
      transparent,
      black 15% 85%,
      transparent
    );
    mask-image: linear-gradient(90deg, transparent, black 15% 85%, transparent);
    width: 100%;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;
