import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const FAQSection = styled(Section)`
  /* make changes as needed*/

  margin: 8rem calc(100px - 24px);

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 2rem 1rem;
  }

  h4 {
    color: #0786a3;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.md}) {
      text-align: center;
    }
  }

  .moreFAQ {
    text-align: right;
  }

  .accordion {
    /* max-width: 768px; */
  }
  .accordion-item {
    list-style: none;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ededed;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      line-height: 1.6;
      font-weight: normal;
      margin: 0;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1rem;
      }
    }
    span {
      font-size: 30px;
    }
  }

  .accordion-content {
    margin-top: 1rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #6b7c93;
  }
  /* .accordion-toggle:hover {
    background-color: #ddd;
  } */
`;
