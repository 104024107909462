import React, { Component } from "react";

import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";

function SectionSelect({ title, subtitle, formik }) {
  //   const formikProps = useFormikContext();
  //   console.log(formikProps.getFieldMeta("event_section").value);

  return (
    <div
      className={
        formik.getFieldMeta("event_section").value === title
          ? "sectionSelect selected"
          : "sectionSelect"
      }
      onClick={() => {
        formik.setFieldValue("event_section", title);
      }}
    >
      <h4> {title}</h4>
      <div className="extra">{subtitle}</div>
    </div>
  );
}

const SelectProduct = ({ formik }) => {
  //   console.log(formik);

  return (
    <Grid container className="formBox">
      <Grid item xs={12} sm={6}>
        <div className="stepInfo">
          De ce fel de producție video aveți nevoie?
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SectionSelect
          formik={formik}
          title="Live Streaming"
          subtitle="Competitii Sportive | Evenimente Culturale | Brands & Corporate |
        Video Marketing"
        />
        <SectionSelect
          formik={formik}
          title="Studio Space"
          subtitle="Chroma Key | Studio Virtual | Emisiuni / Podcast"
        />
        <SectionSelect
          formik={formik}
          title="Remote"
          subtitle="Video Games | Conferinte si Webinarii | Recording | Consultanta
          Video"
        />
        <SectionSelect
          formik={formik}
          title="Video Development"
          subtitle="Video Player | IOS & Android Apps | TV & Video Graphics | Proiecte
          Speciale"
        />
      </Grid>
    </Grid>
  );
};

SelectProduct.label = "Select Product";
SelectProduct.icon = <SubscriptionsIcon />;
SelectProduct.initialValues = {
  event_section: "",
};
SelectProduct.validationSchema = Yup.object().shape({
  event_section: Yup.string().required(),
});

export default SelectProduct;
