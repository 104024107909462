import React from "react";
import { Link } from "react-router-dom";
import { CategoryWrap } from "./CategoryCardList.styled";
import ServiceCard from "./ServiceCard";

export default function CategoryCardList(props) {
  const { category, order } = props;

  return (
    <CategoryWrap>
      <div className="item">
        <h3>{category.title}</h3>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: category.menu_description }}
        ></div>
      </div>

      <div className="serviceCount">
        <div className="serviceList">
          {category.services
            .sort(function (a, b) {
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
              return 0;
            })

            .map((item, index) => (
              //   <Link
              //     key={index}
              //     to={`/${item.section.slug}/${item.categories[0].slug}/${item.slug}`}
              //   >
              //     <img src={`https://api.ovn.ro${item.background?.url}`} />
              //     <b>{item.title}</b>
              //     <div
              //       className=""
              //       dangerouslySetInnerHTML={{ __html: item.description }}
              //     ></div>
              //   </Link>
              <ServiceCard service={item} key={index}></ServiceCard>
            ))}
        </div>
      </div>
    </CategoryWrap>
  );
}
