import React from "react";
import CategoryCard from "../components/cards/CategoryCard";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";

import { StyledContainer } from "../styles/GlobalStyles";
import { CategoryListSection } from "./CategoryList.styled";
import CategoryCardList from "../components/cards/CategoryCardList";

export default function CategoryList({ title, subtitle, showLink, isList }) {
  let { loading, error, data } = useFetch(
    `https://api.ovn.ro/api/categories?populate[services][populate]=*&populate=section`
  );
  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  // console.log(title);

  return (
    <CategoryListSection>
      <StyledContainer maxWidth="xl">
        <h2>{title}</h2>
        <div className="text">
          <p>{subtitle}</p>
          {showLink && (
            <div className="more">
              <Link to="/services">Lista completa</Link>
            </div>
          )}
        </div>

        <div className="categoryContainer">
          {!isList &&
            data
              .sort(function (a, b) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                return 0;
              })
              // .slice(0, 6)
              .map((item, index) => (
                <CategoryCard category={item} key={index}></CategoryCard>
              ))}

          {isList && (
            <div className="sectionWrap">
              <div className="sectionName">
                <h1>live Streaming</h1>
              </div>
              <div className="serviceLists">
                {data
                  .filter((item) => item.section.slug === "livestreaming")
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })

                  // .slice(0, 6)
                  .map((item, index) => (
                    <CategoryCardList
                      category={item}
                      key={index}
                      order={index}
                    ></CategoryCardList>
                  ))}
              </div>
            </div>
          )}

          {isList && (
            <div className="sectionWrap">
              <div className="sectionName">
                <h1>Studio Space</h1>
              </div>
              <div className="serviceLists">
                {data
                  .filter((item) => item.section.slug === "studio")
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })

                  // .slice(0, 6)
                  .map((item, index) => (
                    <CategoryCardList
                      category={item}
                      key={index}
                      order={index}
                    ></CategoryCardList>
                  ))}
              </div>
            </div>
          )}

          {isList && (
            <div className="sectionWrap">
              <div className="sectionName">
                <h1>Remote</h1>
              </div>
              <div className="serviceLists">
                {data
                  .filter((item) => item.section.slug === "remote")
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })

                  // .slice(0, 6)
                  .map((item, index) => (
                    <CategoryCardList
                      category={item}
                      key={index}
                      order={index}
                    ></CategoryCardList>
                  ))}
              </div>
            </div>
          )}
          {isList && (
            <div className="sectionWrap">
              <div className="sectionName">
                <h1>Video Development</h1>
              </div>
              <div className="serviceLists">
                {data
                  .filter(
                    (item) =>
                      item.section.slug === "video-development" &&
                      item.services !== ""
                  )
                  .sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase())
                      return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })

                  // .slice(0, 6)
                  .map((item, index) => (
                    <CategoryCardList
                      category={item}
                      key={index}
                      order={index}
                    ></CategoryCardList>
                  ))}
              </div>
            </div>
          )}
        </div>
      </StyledContainer>
    </CategoryListSection>
  );
}
