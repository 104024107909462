import React from "react";
import camera from "../images/icons/black/icon_camera.svg";
import led_screen from "../images/icons/black/icon_led_screen.svg";
import drona from "../images/icons/black/icon_drona.svg";
import light from "../images/icons/black/icon_light.svg";
import monitor from "../images/icons/black/icon_monitor.svg";
import talent from "../images/icons/black/icon_talent.svg";
import spidercam from "../images/icons/black/icon_spidercam.svg";
import spider from "../images/icons/black/icon_spider.svg";
import subtitrare from "../images/icons/black/icon_subtitrare.svg";
import sound from "../images/icons/black/icon_sound.svg";
import translate from "../images/icons/black/icon_translate.svg";
import proiector from "../images/icons/black/icon_proiector.svg";
import tv_stand from "../images/icons/black/icon_tv_stand.svg";

const iconList = {
  camera: camera,
  led_screen: led_screen,
  drona: drona,
  light: light,
  monitor: monitor,
  talent: talent,
  spidercam: spidercam,
  spider: spider,
  subtitrare: subtitrare,
  sound: sound,
  translate: translate,
  proiector: proiector,
  tv_stand: tv_stand,
};

export default function SvgExtraIcon(props) {
  let Icon = iconList[props.name];
  if (!Icon) {
    Icon = iconList.camera;
  }
  return <img src={Icon} width="30px" />;
}
