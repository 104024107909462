import styled from "styled-components";

export const VideoCardWrap = styled.article`
  padding: 12px;
  flex: 0 0 25%;
  max-width: 25%;
  overflow: hidden;
  position: relative;
  a {
    color: #202020;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    position: relative;

    :hover {
      color: #202020;
    }

    .videoPlaceholder {
      flex: auto;
      height: auto;
      padding-top: 56.25%;
      margin-right: 0;
      /* margin-bottom: 12px; */
      border-radius: 12px;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      :after {
        content: "";
        display: block;
        background: linear-gradient(180deg, rgba(7, 7, 7, 0) 0%, #070707 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 12px;
      bottom: 0;
      max-width: 65%;

      span {
        color: #fff;
        opacity: 0.5;
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #fff;
      }
    }
    .play {
      position: absolute;
      right: 8px;
      bottom: 8px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      background: rgba(32, 32, 32, 0.25);
      border: 1.5px solid rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(24px);
      border-radius: 100px;
      padding: 12px;
      display: flex;
      max-width: 95px;
      min-width: 95px;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease 0s;

      :hover {
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      svg {
        margin-right: 8px;
      }
    }
  }
`;
