import styled from "styled-components";
import { Section } from "../styles/GlobalStyles";

export const ContactSection = styled(Section)`
  /* make changes as needed*/

  padding: 5rem 0;
  margin: 5rem 0;
  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2rem 0;
    margin: 0;
  }

  .ContactFormWrap {
    h1 {
      margin: 1rem auto;
      /* text-align: center; */
      font-weight: 700;
      font-size: 4vw;
      line-height: 4.5vw;
      text-transform: none;
      color: #0786a3;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
      }
    }

    p {
      font-size: 2vw;
      @media (max-width: ${({ theme }) => theme.md}) {
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: justify;
      }
    }
  }

  .contactName {
    font-size: 2.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #0786a3;
    border-bottom: 4px solid #e8e8e8;
    padding-bottom: 8px;
    @media (max-width: ${({ theme }) => theme.sm}) {
      font-size: 2em;
      margin-top: 2em;
    }
  }
  .withIcon {
    display: inline-flex;
    margin: 4px 0;
    font-size: 1rem;
  }

  a {
    display: inline-flex;
    font-size: 1.2em;
    font-weight: 700;
    margin: 8px 16px 8px 0;

    &:first-of-type {
      margin-left: 0;
    }
  }

  p {
    line-height: 1.4em;

    b,
    strong {
      font-size: 1.1rem;
    }
  }
`;
