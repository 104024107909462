import styled from "styled-components";

export const BlogCardWrap = styled.article`
  flex: 0 0 auto;
  width: 33.33333333%;
  /* width: 25%; */
  padding: 12px;
  @media (max-width: ${({ theme }) => theme.md}) {
    width: 100%;
  }
  a {
    color: #202020;
    display: flex;
    flex-direction: column;

    transition: all 0.3s ease 0s;

    :hover {
      color: #202020;

      h3 {
        text-decoration: underline;
      }
    }

    .image {
      flex: auto;
      height: auto;
      padding-top: 56.25%;
      margin-right: 0;
      margin-bottom: 12px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
    }

    .text {
      display: flex;
      flex-direction: column;

      .extra {
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0.5;
        @media (max-width: ${({ theme }) => theme.md}) {
          /* flex-direction: column; */
          font-size: 6px;
          line-height: 12px;
        }

        .categories span {
          display: inline;
        }
        .categories span:after {
          content: "|";
          margin: 0 6px;
        }
        .categories span:last-child:after {
          content: "";
          margin: 0;
        }
        span {
          display: flex;
          align-items: center;
          font-size: 0.6rem;
          min-width: 110px;
          justify-content: end;
          svg {
            font-size: 1rem;
            margin: 0 2px;
          }
        }
      }

      h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
  }
`;
