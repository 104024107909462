import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Box from "@material-ui/core/Box";

import bg1 from "../images/bg3.png";
import intro from "../images/intro2.png";

import { IntroAreaSection } from "./IntroArea.styled";
import { Button, StyledContainer } from "../styles/GlobalStyles";
import { Link } from "react-router-dom";

class IntroArea extends React.Component {
  state = {};
  render() {
    return (
      <IntroAreaSection BackgroundImage={bg1} overflow="hide">
        <StyledContainer maxWidth="xl">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              md={6}
              component={Box}
              className="slider-content"
              //   display={{ xs: "block", md: "none" }}
            >
              <h2>Producem și distribuim video pentru WEB și TV.</h2>
              <p>
                Pentru companii și branduri creăm conținut video ce captivează
                publicul, determină acțiune și aduce rezultate.
              </p>
              <div className="slider-btn">
                <Link to="/cotatie" className="btn red">
                  Solicită o producție video
                </Link>
                <Link to="/showcase" className="btn transparent-btn">
                  Show Case
                </Link>
                {/* <Button type="red">Solicită o producție video</Button>
                <Button type="transparent">Show Case</Button> */}
              </div>
            </Grid>

            <Grid
              item
              md={6}
              component={Box}
              className="slider-image"
              display={{ xs: "none", md: "block" }}
            >
              <img src={intro} alt="ovn_intro"></img>
            </Grid>
          </Grid>
        </StyledContainer>
      </IntroAreaSection>
    );
  }
}

export default IntroArea;
