import React from "react";
import ReactDOM from "react-dom";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
// import theme from "./theme";

import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";

import { StylesProvider } from "@material-ui/core/styles";

// console.log(theme);
ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      {/* <CssBaseline /> */}
      <App />
    </ThemeProvider>
  </StylesProvider>,
  document.querySelector("#root")
);
