import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { SecondaryPageWrap } from "../../styles/GlobalStyles";
import TopSection from "../../sections/TopSection";
import LogoSlider from "../../sections/Slider";
import Services from "../../sections/Services";
import TwoSide from "../../sections/TwoSide";
import Next from "../../sections/Next";

import Image1 from "../../images/demo/1.svg";

import Digisport from "../../images/logotv/Digi_Sport_logo.svg";
import Eurosport from "../../images/logotv/Eurosport_Logo_2015.svg";
import ProArena from "../../images/logotv/PRO_Arena.svg";
import PrimaSport from "../../images/logotv/Prima_Sport_logo.svg";
import ZUTV from "../../images/logotv/ZUTVLogo.png";
import Antena1 from "../../images/logotv/antena1.svg";
import LookSport from "../../images/logotv/LookSport.png";
import FAQ from "../../sections/faq";
import Showcase from "../../sections/Showcase";

const tv_logos = [
  Eurosport,
  Digisport,
  ProArena,
  Antena1,
  PrimaSport,
  ZUTV,
  LookSport,
];

class EvenimenteCulturalePage extends Component {
  state = {};
  render() {
    return (
      <>
        <TopSection
          light="1"
          type="Evenimente Culturale"
          title="Valorificam mostenirea culturala a oamenilor si locurilor"
          description="Transmitem live orice eveniment din orice locație. De la  festivaluri de muzică la concerte sau sesiuni de poezie surprindem atmosfera locatiei si maximizam interesul publicului. "
          picture="https://jwplayer.com/wp-content/uploads/2022/12/86ee788b-642b-4b0a-bb58-524f8bb0f9a2_ott-sports-min.webp"
          //   link_title="Hello"
        ></TopSection>

        <TwoSide
          section="Event Production"
          title="Regie LIVE cu 12 Camere FullHD/4k"
          link_title="Detalii"
          link_url="/livestreaming/competitii-sportive"
          picture={Image1}
        >
          <p>
            Surprindem cele mai importante momente si emotii pentru spectatori
            si cream o expunere maxima pentru branduri si parteneri.
          </p>
        </TwoSide>

        <Showcase
          title="Producții anterioare pentru inspirație"
          category="2"
        ></Showcase>

        <Next backgroundColor="#0786a3"></Next>
        <FAQ />
      </>
    );
  }
}

export default EvenimenteCulturalePage;
