import React from "react";
import { Link } from "react-router-dom";

import { VideoCardWrap } from "./VideoCard.styled";

export default function VideoCard(props) {
  const { article } = props;

  return (
    <VideoCardWrap>
      <Link to={`/showcase/${article.slug}`}>
        <div
          className="videoPlaceholder"
          style={{
            backgroundImage: `url(https://api.ovn.ro${article.video_cover.formats.small.url}`,
          }}
        ></div>
        <div className="text">
          <span>{article.brand}</span>
          <h3>{article.title}</h3>
        </div>
        <button className="play">
          <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.612 8.36007C16.796 9.13281 16.796 10.8672 15.612 11.6399L3.52861 19.5262C2.22605 20.3764 0.499999 19.4418 0.499999 17.8863L0.5 2.11369C0.5 0.558245 2.22605 -0.376373 3.52861 0.473756L15.612 8.36007Z"
              fill="white"
            ></path>
          </svg>
          Play
        </button>
      </Link>
    </VideoCardWrap>
  );
}
