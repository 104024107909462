import React from "react";
import { PageWrap, StyledContainer } from "../styles/GlobalStyles";
import Hero from "../sections/Hero";
import usePageTracking from "../hooks/usePageTracking";
import Group from "../sections/Group";
import timeline from "../images/ovn_timeline.png";
import FullPromo from "../sections/FullPromo";

const group = {
  title: "La OVN ne mândrim cu:",
  items: [
    {
      title: "Experiență de Neegalat",
      description:
        "Suntem mândri să oferim peste un deceniu de experiență în producția video live, aducând evenimentele și conținutul dvs. la viață într-un mod inovator și captivant.",
      icon: "done_icon",
    },

    {
      title: "Calitate Impecabilă",
      description:
        "Ne străduim să livrăm calitatea supremă în fiecare proiect, folosind cele mai recente tehnologii și echipamente de vârf pentru a vă asigura că fiecare moment este capturat în detaliu excepțional.",
      icon: "done_icon",
    },
    {
      title: "Clienți Satisfăcuți",
      description:
        "Succesul nostru este măsurat în mulțumirea clienților noștri. Am colaborat cu o gamă diversificată de companii și instituții pentru a realiza evenimente video memorabile, de la conferințe la concerte și transmisiuni sportive.",
      icon: "done_icon",
    },

    {
      title: "Echipă Talentată",
      description:
        "Echipa noastră este formată din profesioniști pasionați, de la cameramani și regizori la editori și producători, care lucrează împreună pentru a aduce ideile dvs. la viață și pentru a crea conținut video care impresionează.",
      icon: "done_icon",
    },
  ],
};

export default function AboutPage() {
  usePageTracking();
  return (
    <>
      {" "}
      <PageWrap className="static">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI1MSIgdmlld0JveD0iMCAwIDE0NDAgNTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMzFDMzI1LjY4NiAyMS4wNyA2ODMuOTE2IDExLjAyNiAxMDc0LjY5Ljg2NyAxMTMwLjAwMyA2LjQ4NSAxMjUxLjc3MyAyMy4xOTYgMTQ0MCA1MUgwVjMxeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPgo="></img>
        <Hero
          light="1"
          title="Despre Noi"
          subtitle="Online Video Network este o companie media, specializată în transmisiuni video LIVE și producție video."
        ></Hero>
      </PageWrap>
      <Hero
        title="Un scurt istoric al inovațiilor"
        subtitle="De peste 10 ani, OVN furnizează soluții și produse inovatoare. Suntem un partener de încredere pentru zecile de companii ce vor să fie cu un pas în față."
      >
        <br />
        <br />
        <br />
        <img src={timeline} />
      </Hero>
      <FullPromo
        subtitle="Cariere"
        title="Ești interesat să faci parte din echipa noastră?"
        description="La Online Video Network ne propunem să creăm și să promovăm un loc de muncă incluziv, care celebreaază diversitatea în centrul culturii noastre, unde toate formele de diversitate sunt recunoscute ca valoare reală pentru companie."
        link_text="Hai în echipă!"
        link_url="/cariere"
        image="https://api.ovn.ro/uploads/competitii_sportive_in_sala_4e5888127f.jpeg"
      ></FullPromo>
      <Group jsonData={group}></Group>
    </>
  );
}
