import React, { Component } from "react";

import * as Yup from "yup";
import { useFormikContext, FormikProvider } from "formik";
import Grid from "@material-ui/core/Grid";
import InputField from "./InputField";
import SettingsIcon from "@material-ui/icons/Settings";

const ProjectInfo = ({ formik }) => {
  //   console.log("step", formik.getFieldProps("event_address"));

  return (
    <FormikProvider value={formik}>
      <Grid container spacing={4} className="formBox">
        <Grid item xs={12} sm={6}>
          <div className="stepInfo">
            Descrieți proiectul dvs. în cât mai multe detalii.
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <InputField
                name="event_notes"
                multiline
                maxRows={10}
                minRows={7}
                required
                label="Descrieți proiectul dvs. în cât mai multe detalii."
                //   placeholder="Exemplu: La evenimentul nostru vom avea prezentari PowerPoint si ecran LED. Dorim ca prezentarile sa se vada si pe ecran."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                name="event_date"
                label="Data Eveniment"
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputField
                name="event_duration"
                label="Durată Eveniment"
                select
                basicoptions={[
                  "1 Ora",
                  "2-3 Ore",
                  "4-5 Ore",
                  "6-7 Ore",
                  "8-10 Ore",
                  "10+ ore",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                name="event_days"
                label="Zile Eveniment"
                select
                basicoptions={[
                  "1 Zi",
                  "2 Zile",
                  "3 Zile",
                  "4 Zile",
                  "5 Zile",
                  "peste 5 Zile",
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputField
                name="event_address"
                label="Locație Eveniment"
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

ProjectInfo.label = "Project Information";
ProjectInfo.icon = <SettingsIcon />;
ProjectInfo.initialValues = {
  event_notes: "",
  event_date: "",
  event_address: "",
  event_duration: "",
  event_days: "",
};
ProjectInfo.validationSchema = Yup.object().shape({
  event_notes: Yup.string().required("Descrierea proiectului este obligatorie"),
  event_date: Yup.string().required("Va rugam completati acest camp"),
  event_address: Yup.string().min(1).required("Va rugam completati acest camp"),
});

export default ProjectInfo;
