import React, { useState } from "react";
import useFetch from "../hooks/useFetch";

import { StyledContainer } from "../styles/GlobalStyles";
import { CariereSection } from "./Cariere.styled";
import CariereCard from "../components/cards/CariereCard";

export default function Cariere({ title, pageid }) {
  const [active, setActive] = useState("all");

  let filter = pageid ? "&filters[id][$ne]=" + pageid : "";

  let { loading, data: deparment_data } = useFetch(
    `https://api.ovn.ro/api/departaments`
  );

  //   if (error) return <p> Error :( </p>;

  let { loading: jobs_loading, data: jobs } = useFetch(
    `https://api.ovn.ro/api/careers?populate=departments${filter}`
  );

  if (jobs_loading) return <p> Loading jobs... </p>;
  if (loading) return <p> Loading... </p>;

  const no_position = {
    title: "Nu ai găsit poziția pe care o căutai?",
    slug: "general",
    intro:
      "<p>Nu e nici o problemă.  Aplică folosind formularul general.</p><p>Suntem mereu în căutare de oameni mișto cu care să revoluționăm industria video și care împartășesc viziunea și modul nostru de lucru.</p>",
    static: true,
  };

  const statement = {
    title: "OVN pentru toți!",
    intro:
      "<p>La Online Video Network ne propunem să creăm și să promovăm un loc de muncă incluziv, care celebreaază diversitatea în centrul culturii noastre, unde toate formele de diversitate sunt recunoscute ca valoare reală pentru companie.</p>",
    static: true,
    icon: true,
  };

  const filtered = jobs
    .filter((job) => {
      //omit general form from list
      if (job.slug === "general") return false;
      if (active === "all") return true;

      return job.departments[0].slug === active;
    })
    .sort(function (a, b) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    });

  return (
    <CariereSection>
      <StyledContainer maxWidth="xl">
        <h2 style={{ textAlign: pageid ? "left" : "" }}>{title}</h2>

        {!pageid && (
          <div className="DepartmentWrap">
            <span
              className={active === "all" ? "active" : ""}
              onClick={() => setActive("all")}
            >
              All
            </span>
            {deparment_data
              .sort(function (a, b) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                return 0;
              })
              .map((dep, index) => (
                <span
                  key={index}
                  className={active === dep.slug ? "active" : ""}
                  onClick={() => setActive(dep.slug)}
                >
                  {dep.title}
                </span>
              ))}
          </div>
        )}

        <div className="jobWrap">
          {!pageid && (
            <div className="item statement" style={{ order: 2 }}>
              <CariereCard job={statement}></CariereCard>
            </div>
          )}
          <div className="item noposition" style={{ order: 99 }}>
            <CariereCard job={no_position}></CariereCard>
          </div>
          {filtered.map((job, index) => (
            <div className="item" key={index} style={{ order: index }}>
              <CariereCard job={job}></CariereCard>
            </div>
          ))}
        </div>
      </StyledContainer>
    </CariereSection>
  );
}
