import styled from "styled-components";

export const CariereWrap = styled.div`
  background: #fff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px 20px 20px;
  transition: all 200ms ease-out;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  min-height: 300px;

  :hover {
    box-shadow: 0 12px 36px rgb(0 0 0 / 15%);
  }

  .link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
  span {
    color: gray;
    display: flex;

    img {
      margin-right: 4px;
    }
  }

  .description {
    margin: 8px 0;
    margin-bottom: 16px;
    p {
      color: black;
      margin: 0;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .apply {
      border: 1.5px solid #202020;
      border-radius: 100px;
      background: transparent;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: #202020;
      height: 36px px;
      padding: 9px 14px;
      position: relative;
      :hover {
        background: #202020;
        color: #fff;
      }
    }

    .more {
      background: transparent;
      font-weight: 600;
      font-size: 14px;
      line-height: 36px;
      color: #202020;
      height: 36px;
      padding: 0;
      margin-left: 16px;
      position: relative;
    }
  }
`;
