import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { LaptopWindows } from "@material-ui/icons";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 44.435205,
  lng: 26.0794,
};

const position = {
  lat: 44.4321702,
  lng: 26.0493941,
};
const MapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    stylers: [
      {
        color: "#afaea7",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        color: "#afaea7",
      },
      {
        visibility: "simplified",
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

function MapOVN() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAHTGiEadNG9L0iJvjjETaT7mNUH1nB_q8",
  });

  const [map, setMap] = useState(null);

  return isLoaded ? (
    // <LoadScript googleMapsApiKey="AIzaSyAHTGiEadNG9L0iJvjjETaT7mNUH1nB_q8">
    <GoogleMap
      onLoad={(map) => {
        console.log(map);
        setMap(map);
      }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={14}
      // streetView={false}
      options={{
        styles: MapStyles,
        // disableDefaultUI: true, // a way to quickly hide all controls
        mapTypeControl: false,
        scaleControl: true,
        // streetView: false,
        streetViewControl: false,
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}

      <>
        <Marker
          position={position}
          marker
          icon={{
            //   path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",

            scale: 4,

            strokeWeight: 2,
            scaledSize: new window.google.maps.Size(100, 100),
            url: "http://s1.myovn.com/ovn_studio_marker.svg",
          }}
        />
      </>
    </GoogleMap>
  ) : (
    // </LoadScript>
    <></>
  );
}

export default React.memo(MapOVN);
