import React, { Component } from "react";
import { CardWrap } from "./BaseCard.styled";

export default class BaseCard extends Component {
  render() {
    const { title, text, type, link_title, link_url, icon } = this.props;
    const HtmlTag = link_url ? "a" : "div";
    return (
      <CardWrap>
        <HtmlTag className="inner" href="#" target="_self">
          {icon && (
            <div className="icon">
              <img src={icon} width="70" alt="" />
            </div>
          )}

          <div className="content">
            <span className="type">{type}</span>
            <span className="title">{title}</span>
            <span className="teaser">{text}</span>

            {link_title && link_url && <button>{link_title}</button>}
          </div>
        </HtmlTag>
      </CardWrap>
    );
  }
}
