import React, { useState, useEffect } from "react";
import { Button, StyledContainer } from "../styles/GlobalStyles";

import qs from "qs";
import axios from "axios";

import { VideoSection } from "./VideoList.styled";
import VideoCard from "../components/cards/VideoCard";
import CategorySlider from "../components/CategorySlider";

import { TextField, InputAdornment, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export default function VideoList(props) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);

  function handleCategory(cat) {
    setActive(cat);
  }

  async function getArticles(pageno = 1, filter = null) {
    const query = qs.stringify({
      populate: ["video_cover", "categories"],
      pagination: {
        page: pageno,
        pageSize: 16,
      },
      filters: { ...filter },
      sort: ["id:desc"],
    });

    setLoading(true);
    setSearchQuery(filter);
    let url = `https://api.ovn.ro/api/videos?${query}`;
    // console.log(url);

    try {
      const res = await axios.get(url);

      if (pageno > 1) {
        setArticles([...articles, ...res.data.data]);
      } else {
        setArticles(res.data.data);
      }

      setPage(pageno + 1);
      setTotal(res.data.meta.pagination.total);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   getArticles();
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log("search", searchTerm);
      // Send Axios request here
      let filter = null;
      if (searchTerm !== "") {
        console.log("fetch blog post");
        filter = {
          $or: [
            {
              title: {
                $contains: searchTerm.split(" "),
              },
            },
            {
              content: {
                $contains: searchTerm.split(" "),
              },
            },
          ],
        };
      }

      if (active && active !== "all") {
        let add = {
          categories: {
            slug: {
              $in: active,
            },
          },
        };

        filter = { ...filter, ...add };
      }

      console.log(filter);

      getArticles(1, filter);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, active]);
  return (
    <VideoSection>
      {props.title && <h2>Be inspired by our video content</h2>}
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CategorySlider
            activeCat={active}
            onSelectCat={handleCategory}
            dark
          ></CategorySlider>
        </Grid>
      </Grid>

      <div className="blogWrap">
        {articles.map((article, index) => (
          <VideoCard article={article} key={index} />
        ))}
      </div>
      {articles.length < total && (
        <Button onClick={() => getArticles(page, searchQuery)}>
          Show more
        </Button>
      )}
    </VideoSection>
  );
}
