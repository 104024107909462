import styled from "styled-components";
import ArrowDot from "../../images/arrow-dot.svg";

export const CardWrap = styled.div`
  /* make changes as needed*/
  margin: 3rem 2rem;
  background: #8797b2;
  border-radius: 12px;
  flex: 0 0 300px;
  flex-shrink: 0;
  padding: 2px 2px 12px 2px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 300px;

  :hover {
    background-image: linear-gradient(to right, #0786a3, #35c2d8);

    /* .inner .icon {
      border: 2px solid #0786a3;
    } */
  }

  .inner {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    color: #0786a3;
    transition: 0.1s ease-in;
    display: inline-block;

    .icon {
      height: 74px;
      width: 74px;
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #8797b2;
      border-radius: 100px;
      margin-left: 36px;
      margin-top: -37px;
      background: #fff;
    }

    .content {
      /* height: 263.273px; //to be deleted */
      padding: 2.5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: #0f1a3d;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
        font-size: 1.6em;
        line-height: 1.2;
        letter-spacing: normal;
        font-weight: 900;
      }

      .teaser {
        opacity: 0.8;
        margin-bottom: 1rem;
        color: #0f1a3d;
        display: block;
        font-size: 0.9em;
      }

      button {
        letter-spacing: 0.1px;
        text-decoration: none;
        cursor: pointer;
        text-align: center;
        box-shadow: 0rem 1rem 12rem 0 rgb(15 26 61 / 10%);
        margin: 1rem 0;
        transition: 0.1s ease-in;
        border: 0;
        border-radius: 24rem;
        display: block;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 900;
        position: relative;
        padding: 0 2rem 0 0;
        color: #0f1a3d;
        background: none;

        ::after {
          content: "";
          background-image: url(${ArrowDot});
          height: 24px;
          width: 24px;
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        :hover {
          color: #0786a3;
        }
      }
    }
  }
`;
