import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../styles/GlobalStyles";

import { ServiceWrap } from "./ServiceCard.styles";

export default function ServiceCard(props) {
  const { service, className } = props;

  // console.log(props);

  return (
    <ServiceWrap className={className}>
      <Link
        to={`/${service.section.slug}/${service.categories[0].slug}/${service.slug}`}
        className="serviceListItem"
      >
        <div
          className="image"
          style={{
            backgroundImage: `url(https://api.ovn.ro${service.background?.formats.small.url})`,
          }}
        ></div>

        <div className="text">
          <h4>{service.title}</h4>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: service.description }}
          ></div>

          <div className="link">Read More</div>
        </div>
      </Link>
    </ServiceWrap>
  );
}
