import React, { Component } from "react";

import * as Yup from "yup";
import { useFormikContext, FormikProvider } from "formik";
import Grid from "@material-ui/core/Grid";
import InputField from "./InputField";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

const ContactDetails = ({ formik }) => {
  return (
    <FormikProvider value={formik}>
      <Grid container spacing={4} className="formBox">
        <Grid item xs={12} sm={6}>
          <div className="stepInfo">
            Cum va putem contacta pentru mai multe detalii?
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4} className="">
            <Grid item xs={12} sm={6}>
              <InputField name="contact_firstname" label="Prenume" required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name="contact_name" label="Nume" required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name="contact_function" label="Funcție" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name="contact_company" label="Companie" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name="contact_email"
                label="Adresa de email"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField name="contact_phone" label="telefon" required />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name="contact_source"
                label="De unde ați auzit de noi?"
                select
                basicoptions={[
                  "Alt eveniment la care am participat",
                  "Colegi / Prieteni",
                  "Publicitate Online",
                  "Social Media",
                  "Email / Newsletter",
                  " Motor de cautare",
                  "Altă sursă",
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

ContactDetails.label = "Contact Details";
ContactDetails.icon = <AssignmentIndIcon />;
ContactDetails.initialValues = {
  contact_email: "",
  contact_phone: "",
  contact_firstname: "",
  contact_name: "",
  contact_function: "",
  contact_company: "",
  contact_source: "",
};
ContactDetails.validationSchema = Yup.object().shape({
  contact_email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  contact_phone: Yup.number("Enter your phone")
    .typeError("Only numbers (Ex: 0722111333)")
    .test("len", "Incorrect phone number", (val) => val.toString().length >= 9)
    .required("Phone is required"),
  contact_name: Yup.string("Enter your name").required("Name is required"),
  contact_firstname: Yup.string("Enter your firstname").required(
    "First Name is required"
  ),
});

export default ContactDetails;
